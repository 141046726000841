const modeMixin = {
  data() {
    return {
      darkMode: false,
    };
  },
  mounted() {
    this.processTheme();
  },
  watch: {
    darkMode() {
      const bodyElement = document.body;
      this.resetTheme();

      if (this.darkMode) {
        localStorage.setItem('theme', 'dark');
        bodyElement.classList.add('theme-dark');
      } else {
        localStorage.setItem('theme', 'light');
        bodyElement.classList.add('theme-light');
      }

      this.$store.dispatch('theme/setTheme', this.darkMode ? 'dark' : 'light');
    },
  },
  methods: {
    processTheme() {
      const bodyElement = document.body;
      const theme = localStorage.getItem('theme');
      this.resetTheme();

      if (theme === 'dark') {
        bodyElement.classList.add('theme-dark');
        this.darkMode = true;
      } else {
        bodyElement.classList.add('theme-light');
        this.darkMode = false;
      }

      this.$store.dispatch('theme/setTheme', this.darkMode ? 'dark' : 'light');
    },
    resetTheme() {
      const bodyElement = document.body;
      bodyElement.classList.remove('theme-light');
      bodyElement.classList.remove('theme-dark');
    },
  },
};

export default modeMixin;
