//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loadingLabel: {
      type: String,
      default: null,
    },
    link: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: '/',
    },
  },
  computed: {
    isDisabled() {
      return this.isLoading || this.disabled;
    },
  },
  methods: {
    onClick() {
      this.$emit('input');
      if (this.link && !this.isDisabled) {
        this.$router.push(this.to);
      }
    },
  },
};
