//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modeMixin from '~/mixins/mode';

export default {
  name: 'LayoutDefault',
  mixins: [modeMixin],
  scrollToTop: true,
  data() {
    return {
      screen: 'extra-large',
      mode: 'full',
      sidebarClasses: {
        full: 'full',
        compact: 'toggled',
        closed: 'closed',
      },

      showScroll: false,
    };
  },
  watch: {
    $route() {
      if (window.innerWidth < 768 && this.mode !== 'closed') this.toggle();
    },
  },
  async created() {
    this.init();

    await this.$store.dispatch('time/fetchTimezone');
  },
  mounted() {
    const bodyElement = document.body;
    bodyElement.classList.remove('scrollable');
    bodyElement.classList.add('scrollable');

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.init();
      });

      window.addEventListener('scroll', () => {
        this.showScroll = window.scrollY > 100;
      });
    });
  },
  methods: {
    init() {
      this.mode = 'closed';
      this.screen = 'xlarge';

      if (window.innerWidth < 768) {
        this.screen = 'small';
        this.mode = 'compact';
      } else if (window.innerWidth < 1200) {
        this.screen = 'large';
        this.mode = 'full';
      }

      this.toggle();
    },
    toggle() {
      switch (this.mode) {
        case 'full':
          this.mode = 'compact';
          if (this.screen === 'small') this.mode = 'closed';
          break;

        case 'compact':
          this.mode = 'closed';
          break;

        case 'closed':
          this.mode = 'full';
          if (this.screen === 'large') this.mode = 'compact';
          break;

        default:
          this.mode = 'full';
          if (this.screen === 'large') this.mode = 'compact';
          break;
      }
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
