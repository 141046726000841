import Interfaces from '~/api/interfaces';
import Wrapper from '~/api/wrapper';

let client;

const api = {
  v1: {},
  async init() {
    const maps = [
      { entry: 'user', interface: Interfaces.User },
      { entry: 'employee', interface: Interfaces.Employee },
      { entry: 'currency', interface: Interfaces.Currency },
      { entry: 'leave', interface: Interfaces.Leave },
      { entry: 'expense', interface: Interfaces.Expense },
      { entry: 'document', interface: Interfaces.Document },
      { entry: 'schedule', interface: Interfaces.Schedule },
      { entry: 'attendance', interface: Interfaces.Attendance },
      { entry: 'birthday', interface: Interfaces.Birthday },
      { entry: 'department', interface: Interfaces.Department },
      { entry: 'setting', interface: Interfaces.Setting },
      { entry: 'payroll', interface: Interfaces.Payroll },
      { entry: 'permission', interface: Interfaces.Permission },
      { entry: 'role', interface: Interfaces.Role },
      { entry: 'company', interface: Interfaces.Company },
      { entry: 'recruitment', interface: Interfaces.Recruitment },
      { entry: 'trip', interface: Interfaces.Trip },
      { entry: 'appraisal', interface: Interfaces.Appraisal },
    ];
    maps.map(item => {
      this.v1[item.entry] = Wrapper(client, item.interface, `xhr_v4_${item.entry}`);
      return item;
    });
  },
};

export function setClient(value) {
  client = value;
  api.init();
}

export default api;
