import { render, staticRenderFns } from "./CSidebarGroup.vue?vue&type=template&id=41a6a2d9&"
import script from "./CSidebarGroup.vue?vue&type=script&lang=js&"
export * from "./CSidebarGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CSidebarLink: require('/home/web/alpha-fe/components/navbar/CSidebarLink.vue').default})
