/* eslint-disable no-unused-vars */
import Vue from 'vue';

const initialState = {};

const getters = {};

const actions = {
  showMessage(_, { error, type = 'error', opts = {} }) {
    const allowEscapeKey = type !== 'error';
    const allowOutsideClick = type !== 'error';
    const showCancelButton = type !== 'error' || opts.showCancelButton;
    const confirmButtonText = opts.confirmButtonText || 'OK';
    const confirmButtonClass = opts.confirmButtonClass || 'swal2-confirm--sm swal2-confirm--block u-bg--red';

    Vue.swal({
      title: error.caption,
      text: error.message,
      icon: type,
      allowEscapeKey,
      allowOutsideClick,
      showCancelButton,
      confirmButtonText,
      customClass: {
        popup: 'swal2-popup--sm',
        confirmButton: confirmButtonClass,
      },
    });
  },
};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
