import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  // LEAVE TYPE
  getLeaveType(queries = {}, customCompanyId = null) {
    const finalQueries = {
      sort_by: 'name',
      sort_direction: 'asc',
      ...queries,
    };
    return this.get(`/api/v1/leaves/types${objectToQueryString(finalQueries)}`, 'user', new ApiOptions({ customCompanyId }));
  },
  getLeaveTypeById(id) {
    return this.get(`/api/v1/leaves/types/${id}`, 'user');
  },
  postLeaveType(formData) {
    return this.post(`/api/v1/leaves/types`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  putLeaveType(id, formData) {
    return this.put(`/api/v1/leaves/types/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deleteLeaveType(id) {
    return this.delete(`/api/v1/leaves/types/${id}`, 'user');
  },
  updateLeaveTypeStatus(id, formData) {
    return this.patch(`/api/v1/leaves/types/${id}/status`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },

  getAvailableLeaveType() {
    return this.get(`/api/v1/my/leaves/types`, 'user');
  },
  getLeaveTypeStatus() {
    return this.get(`/api/v1/leaves/requests/statuses`, 'user');
  },

  // Approval Level
  getLeaveApprovalLevels(formData) {
    const stacks = ['limit', 'page', 'search', 'sort_by', 'sort_direction', 'noLimit'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/leaves/approval-level${filteredQs}`, 'user');
  },

  getApprovalLevel(uuid) {
    return this.get(`/api/v1/leaves/approval-level/${uuid}`, 'user');
  },

  createApprovalLevel(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/leaves/approval-level`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  updateApprovalLevel(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/leaves/approval-level/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteApprovalLevel(id) {
    return this.delete(`/api/v1/leaves/approval-level/${id}`, 'user');
  },

  // LEAVE BALANCE
  getMyLeaveBalances(formData) {
    const stacks = ['limit', 'page', 'leave_type_id', 'status_id', 'start_date', 'end_date', 'sort_by', 'sort_direction', 'noLimit'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/my/leaves/balances${filteredQs}`, 'user');
  },
  getLeaveBalanceStatus() {
    return this.get(`/api/v1/leaves/balances/statuses`, 'user');
  },
  getLeaveBalances(formData) {
    const stacks = ['limit', 'page', 'leave_type_id', 'status_id', 'start_date', 'end_date', 'sort_by', 'sort_direction', 'employee_id', 'noLimit', 'search', 'status', 'leave_type', 'department'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/leaves/balances${filteredQs}`, 'user');
  },
  getLeaveBalance(id) {
    return this.get(`/api/v1/leaves/balances/${id}`, 'user');
  },
  createLeaveBalance(formData) {
    const allowed = ['employee_id', 'leave_type_id', 'period_start', 'period_end', 'quota', 'transferable', 'transferable_duration', 'notes', 'deduct_next', 'departments', 'is_active'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/leaves/balances`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateLeaveBalance(id, formData) {
    const allowed = ['employee_id', 'leave_type_id', 'period_start', 'period_end', 'quota', 'transferable', 'transferable_duration', 'notes', 'deduct_next', 'departments', 'is_active'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/leaves/balances/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteLeaveBalance(id) {
    return this.delete(`/api/v1/leaves/balances/${id}`, 'user');
  },
  updateLeaveBalanceStatus(id, formData) {
    const allowed = ['status_id'];
    const filtered = validateObject(formData, allowed);
    return this.patch(`/api/v1/leaves/balances/${id}/status`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  // LEAVE REQUEST
  getMyLeaveRequest(formData) {
    const stacks = ['limit', 'page', 'leave_type_id', 'request_status_id', 'start_date', 'end_date', 'search', 'sort_by', 'sort_direction'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/my/leaves/requests${filteredQs}`, 'user');
  },
  getMyLeaveRequestById(id) {
    return this.get(`/api/v1/my/leaves/requests/${id}`, 'user');
  },
  getLeaveRequest(formData) {
    const stacks = [
      'limit',
      'page',
      'employee_id',
      'leave_type_id',
      'request_status_id',
      'start_date',
      'end_date',
      'search',
      'sort_by',
      'sort_direction',
      'myturn',
    ];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/leaves/requests${filteredQs}`, 'user');
  },
  getLeaveRequestCount(formData) {
    const stacks = [
      'employee_id',
      'leave_type_id',
      'request_status_id',
      'request_status_id_by_setting',
      'start_date',
      'end_date',
      'search',
      'sort_by',
      'sort_direction',
    ];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/leaves/requests/count${filteredQs}`, 'user');
  },
  getLeaveRequestById(id) {
    return this.get(`/api/v1/leaves/requests/${id}`, 'user');
  },
  getLeaveRequestHistoryById(id) {
    return this.get(`/api/v1/leaves/requests/${id}/histories`, 'user');
  },
  createLeaveRequest(formData) {
    return this.post(`/api/v1/leaves/requests`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateLeaveRequest(id, formData) {
    return this.put(`/api/v1/leaves/requests/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getOffDate(id, formData) {
    return this.get(`/api/v1/get-off-schedules?employee_id=${id}&period_start=${formData.start_date}&period_end=${formData.end_date}&is_submit=${formData.is_submit}`, 'user');
  },

  // LEAVE REQUEST - STATUS
  setLeaveRequestStatus(id, formData) {
    const stacks = ['request_status_id', 'approval_notes'];
    const filtered = validateObject(formData, stacks);
    return this.patch(`/api/v1/leaves/requests/${id}/status`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  cancelApproved(id, formData) {
    const stacks = ['request_status_id', 'approval_notes'];
    const filtered = validateObject(formData, stacks);
    return this.patch(`/api/v1/leaves/requests/${id}/cancel`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  // LEAVE REQUEST - CALCULATOR
  fetchAvailableDates(formData) {
    const form = {};
    formData.dates.forEach((x, i) => {
      Object.keys(x).forEach(y => {
        form[`${y}[${i}]`] = x[y];
      });
    });
    const param = `is_ignore_schedule=${formData.is_ignore_schedule}`;

    return this.get(`/api/v1/leaves/requests/calculate${objectToQueryString(form)}&${param}`, 'user');
  },

  // LEAVE - WHO's OUT
  fetchWhosOut(formData) {
    const stacks = ['start_date', 'end_date', 'department'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/leaves/stats/whosout${filteredQs}`, 'user');
  },
  getAllSettings() {
    return this.get('/api/v1/leaves/settings?sort_by=name&sort_direction=asc', 'user');
  },
  getSettingValueByKey(key) {
    return this.get(`/api/v1/leaves/settings/keys/${key}`, 'user');
  },
  getMappingTransferLeave(id, period) {
    return this.get(`/api/v1/leave-mapping?employee_id=${id}&period=${period}`, 'user');
  },
};
