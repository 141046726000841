export const getErrorMessage = (error = null, customMessage = null, customCaption = null) => {
  let currentCode = 500;
  // let currentCaption = customCaption === null ? 'Error' : customCaption;
  // let currentMessage = customMessage === null ? 'Unkown Error' : customMessage;

  let currentCaption = '';
  let currentMessage = '';

  const captions = {
    400: {
      caption: 'Bad Request',
      message: 'Please double check the fields, fill all of the required fields, and make sure to answer it correctly.',
    },
    401: { caption: 'Access Denied', message: 'You are not authorized to access this resource.' },
    403: { caption: 'Access Denied', message: 'You are not authorized to access this resource.' },
    404: { caption: 'Not Exist', message: 'Data that you are trying to find is not exist or no longer exist.' },
    405: { caption: 'Method Not Allowed', message: 'Unexpected error. Please contact Administrator. (#405)' },
    500: { caption: 'Server Error', message: "We're experiencing a technical difficulties right now. Please try again later. (#500)" },
    502: { caption: 'Server Error', message: "We're experiencing a technical difficulties right now. Please try again later. (#502)" },
    503: { caption: 'Server Error', message: "We're experiencing a technical difficulties right now. Please try again later. (#503)" },
    504: { caption: 'Server Error', message: "We're experiencing a technical difficulties right now. Please try again later. (#504)" },
  };

  const messageCode = {
    'REQUEST LIMITED': 'You requested this data too often. Please try again in a few minutes.',
    'The given data was invalid.': 'Please double check the fields, fill all of the required fields, and make sure to answer it correctly.',
  };

  if (error) {
    if (error.response) {
      if (error.response.status) {
        currentCode = error.response.status;

        if (captions[error.response.status] && captions[error.response.status].caption && captions[error.response.status].message) {
          currentCaption = captions[error.response.status].caption;
          currentMessage = captions[error.response.status].message;
        }

        if (error.response.data && error.response.data.message) {
          if (process.env.NODE_ENV === 'development') currentMessage = error.response.data.message;

          if (messageCode[error.response.data.message]) {
            currentMessage = messageCode[error.response.data.message];
          }
        }
      }
    }
  }

  currentCaption = customCaption !== null ? customCaption : currentCaption;
  currentMessage = customMessage !== null ? customMessage : currentMessage;

  return { code: currentCode, caption: currentCaption, message: currentMessage };
};

export const mergeErrors = (errors, depth = 4) => {
  return errors.flat(depth);
};

export default { getErrorMessage, mergeErrors };
