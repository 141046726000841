import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_leave_index' */ '~/pages/admin/leave/Index');
const History = () => import(/* webpackChunkName: 'admin_leave_history' */ '~/pages/admin/leave/History');
const Detail = () => import(/* webpackChunkName: 'admin_leave_history' */ '~/pages/admin/leave/Detail');

const TypeIndex = () => import(/* webpackChunkName: 'admin_leave_type_index' */ '~/pages/admin/leave/TypeIndex');
const TypeHistory = () => import(/* webpackChunkName: 'admin_leave_type_history' */ '~/pages/admin/leave/TypeHistory');
const TypeCreate = () => import(/* webpackChunkName: 'admin_leave_type_create' */ '~/pages/admin/leave/TypeCreate');
const TypeUpdate = () => import(/* webpackChunkName: 'admin_leave_type_update' */ '~/pages/admin/leave/TypeUpdate');

const ApprovalLevelIndex = () => import(/* webpackChunkName: 'admin_leave_approval_level' */ '~/pages/admin/leave/ApprovalLevelIndex');
const ApprovalLevelList = () => import(/* webpackChunkName: 'admin_leave_approval_level_list' */ '~/pages/admin/leave/ApprovalLevelList');
const ApprovalLevelCreate = () => import(/* webpackChunkName: 'admin_leave_approval_level_create' */ '~/pages/admin/leave/ApprovalLevelCreate');
const ApprovalLevelDetail = () => import(/* webpackChunkName: 'admin_leave_approval_level_detail' */ '~/pages/admin/leave/ApprovalLevelDetail');
const ApprovalLevelEdit = () => import(/* webpackChunkName: 'admin_leave_approval_level_edit' */ '~/pages/admin/leave/ApprovalLevelEdit');

const BalanceIndex = () => import(/* webpackChunkName: 'admin_leave_balance_index' */ '~/pages/admin/leave/BalanceIndex');
const BalanceList = () => import(/* webpackChunkName: 'admin_leave_balance_list' */ '~/pages/admin/leave/BalanceList');
const BalanceCreate = () => import(/* webpackChunkName: 'admin_leave_balance_create' */ '~/pages/admin/leave/BalanceCreate');
const BalanceUpdate = () => import(/* webpackChunkName: 'admin_leave_balance_update' */ '~/pages/admin/leave/BalanceUpdate');

export default {
  path: 'leave',
  component: r(Index),
  children: [
    { path: '', redirect: '/admin/leave/manage', name: 'admin_leave_index' },
    {
      path: 'manage',
      component: r(Index),
      children: [
        { path: '', component: r(History), name: 'admin_leave_history' },
        { path: 'detail/:id(\\d+)', component: r(Detail), name: 'admin_leave_detail' },
      ],
    },
    {
      path: 'type',
      component: r(TypeIndex),
      children: [
        { path: '', component: r(TypeHistory), name: 'admin_leave_type' },
        { path: 'new', component: r(TypeCreate), name: 'admin_leave_type_create' },
        { path: ':id(\\d+)', component: r(TypeUpdate), name: 'admin_leave_type_detail' },
      ],
    },
    {
      path: 'approval-level',
      component: r(ApprovalLevelIndex),
      children: [
        { path: '', component: r(ApprovalLevelList), name: 'admin_leave_approval_level_list' },
        { path: 'new', component: r(ApprovalLevelCreate), name: 'admin_leave_approval_level_create' },
        { path: ':id/edit', component: r(ApprovalLevelEdit), name: 'admin_leave_approval_level_edit' },
        { path: ':id', component: r(ApprovalLevelDetail), name: 'admin_leave_approval_level_detail' },
      ],
    },
    {
      path: 'balance',
      component: r(BalanceIndex),
      children: [
        { path: '', component: r(BalanceList), name: 'admin_leave_balance_list' },
        { path: 'new', component: r(BalanceCreate), name: 'admin_leave_balance_create' },
        { path: ':id(\\d+)', component: r(BalanceUpdate), name: 'admin_leave_balance_detail' },
      ],
    },
  ],
};
