export const settings = {
  // Prefix, Postfix
  prefix: '%',
  postfix: '%',

  // Codes
  codes: {
    // TIME
    hour: 'H', // 24-hour format of an hour without leading zeros	                      0 through 23
    minute: 'i', // Minutes with leading zeros	                                          0 to 59
    second: 's', // Seconds with leading zeros	                                          0 through 59
  },
};

/**
 * Wrap string with date prefix and postfix
 *
 * @param   {!string=}      str         Name
 *
 * @return  {string}        The wrapped string
 */
export const wrap = str => {
  return `${settings.prefix}${str}${settings.prefix}`;
};

export const parseObjectToTime = time => {
  return `${time.HH}:${time.mm}`;
};

export const parseTimeToObject = obj => {
  return {
    HH: obj.slice(0,2),
    mm: obj.slice(3,5), 
  };
};

export const defaults = {
  timeToDisplay: `${wrap(settings.codes.hour)}:${wrap(settings.codes.minute)}`, // %H%:%i%
};

/**
 * Format given timestamp into specified format
 *
 * @param   {?string=}      time            The time you wish to convert
 * @param   {!string=}      format        The format, H:i:s/H:i/H
 *
 * @return  {string}        The converted result, based on the given format
 */
export const formatTime = (time = null, format = 'default') => {
  const defaultTimeFormat = defaults.timeToDisplay;
  let timeFormat = format !== null ? format : defaultTimeFormat;
  timeFormat = timeFormat === 'default' ? defaultTimeFormat : timeFormat;

  const result = [];

  // TIME
  result.hour = time.slice(0,2);
  result.minute = time.slice(3,5);
  result.second = time.slice(6,8);

  const maps = [];
  Object.entries(settings.codes).forEach(x => {
    maps[`${wrap(x[1])}`] = result[x[0]];
  });

  return timeFormat.replace(/%(.*?)%/gi, n => maps[n]);
};

export default {
  wrap,
  parseObjectToTime,
  parseTimeToObject,
  formatTime,
};
