//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import assetsMixin from 'mixins/assets';
import companyMixin from '~/mixins/company';

export default {
  name: 'CProfileCompany',
  mixins: [assetsMixin, companyMixin],
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  fetch() {
    this.fetchAvatarUrl();
  },
  data() {
    return {
      avatarUrl: null,
    };
  },
  watch: {
    src() {
      this.fetchAvatarUrl();
    },
  },
  methods: {
    async fetchAvatarUrl() {
      this.avatarUrl = await this.getAvatarUrl(this.src, this.isAdmin);
    },
  },
};
