import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  // Employees
  getEmployees(queries, cts = null) {
    return this.get(`/api/v1/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getEmployee(uuid) {
    return this.get(`/api/v1/employees/${uuid}`, 'user');
  },
  getEmployeeGeneral(uuid) {
    return this.get(`/api/v1/employees/${uuid}/general`, 'user');
  },
  createEmployee(formData) {
    return this.post(`/api/v1/employees/create`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateEmployee(uuid, formData) {
    return this.post(`/api/v1/employees/${uuid}/update`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },
  updateSelfEmployee(uuid, formData) {
    return this.post(`/api/v1/_self/employees/update`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },
  deleteEmployee(uuid) {
    return this.delete(`/api/v1/employees/${uuid}`, 'user');
  },
  changePhoto(uuid, formData) {
    return this.post(`/api/v1/employees/${uuid}/photo`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  removePhoto(uuid) {
    return this.delete(`/api/v1/employees/${uuid}/photo`, 'user');
  },

  // Emergency Contacts
  getEmergencyContacts(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/contacts${objectToQueryString(queries)}`, 'user');
  },
  getEmergencyContact(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/contacts/${id}`, 'user');
  },
  createEmergencyContact(uuid, formData) {
    const allowed = ['name', 'relationship', 'phone', 'address', 'notes', 'country', 'region', 'city', 'subdistric', 'postal_code'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/contacts`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmergencyContact(uuid, id, formData) {
    const allowed = ['name', 'relationship', 'phone', 'address', 'notes', 'country', 'region', 'city', 'subdistric', 'postal_code'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/contacts/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEmergencyContact(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/contacts/${id}`, 'user');
  },

  // Next of Kins
  getNextOfKins(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/noks${objectToQueryString(queries)}`, 'user');
  },
  getNextOfKin(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/noks/${id}`, 'user');
  },
  createNextOfKin(uuid, formData) {
    const allowed = ['fullname', 'relationship', 'gender', 'occupation', 'birthdate', 'phone', 'address', 'id_type', 'id_number', 'id_expiration'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/noks`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateNextOfKin(uuid, id, formData) {
    const allowed = ['fullname', 'relationship', 'gender', 'occupation', 'birthdate', 'phone', 'address', 'id_type', 'id_number', 'id_expiration'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/noks/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteNextOfKin(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/noks/${id}`, 'user');
  },

  // Educations
  getEducations(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/educations${objectToQueryString(queries)}`, 'user');
  },
  getEducation(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/educations/${id}`, 'user');
  },
  createEducation(uuid, formData) {
    const allowed = ['edu_level', 'institute_name', 'city', 'country', 'major', 'start_year', 'end_year', 'grade', 'notes'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/educations`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEducation(uuid, id, formData) {
    const allowed = ['edu_level', 'institute_name', 'city', 'country', 'major', 'start_year', 'end_year', 'grade', 'notes'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/educations/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEducation(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/educations/${id}`, 'user');
  },

  // Jobs
  getJobs(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/jobs${objectToQueryString(queries)}`, 'user');
  },
  getJob(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/jobs/${id}`, 'user');
  },
  createJob(uuid, formData) {
    const allowed = [
      'company',
      'city',
      'country',
      'position',
      'description',
      'date_start_year',
      'date_start_month',
      'date_start_date',
      'date_end_year',
      'date_end_month',
      'date_end_date',
      'reason',
    ];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/jobs`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateJob(uuid, id, formData) {
    const allowed = [
      'company',
      'city',
      'country',
      'position',
      'description',
      'date_start_year',
      'date_start_month',
      'date_start_date',
      'date_end_year',
      'date_end_month',
      'date_end_date',
      'reason',
    ];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/jobs/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteJob(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/jobs/${id}`, 'user');
  },

  // Bank Accounts
  getBankAccounts(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/accounts${objectToQueryString(queries)}`, 'user');
  },
  getBankAccount(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/accounts/${id}`, 'user');
  },
  createBankAccount(uuid, formData) {
    const allowed = ['bank_name', 'branch_name', 'branch_address', 'account_no', 'account_name', 'city', 'country', 'swiftcode', 'currency'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/accounts`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateBankAccount(uuid, id, formData) {
    const allowed = ['bank_name', 'branch_name', 'branch_address', 'account_no', 'account_name', 'city', 'country', 'swiftcode', 'currency'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/accounts/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteBankAccount(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/accounts/${id}`, 'user');
  },

  // Others
  getOtherData(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/data${objectToQueryString(queries)}`, 'user');
  },
  updateOtherDataBulk(uuid, formData) {
    const allowed = ['data'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/data`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  // Employee's Positions
  getEmployeeLastPosition(uuid) {
    return this.get(`/api/v1/employees/${uuid}/position`, 'user');
  },
  getEmployeePositions(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/positions${objectToQueryString(queries)}`, 'user');
  },
  getEmployeePosition(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/positions/${id}`, 'user');
  },
  createEmployeePosition(uuid, formData) {
    const allowed = ['position_id', 'start_date', 'end_date', 'start', 'end', 'employment_period_id', 'employment_type_id', 'team_notification', 'request_approver_notification'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/positions`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeePosition(uuid, id, formData) {
    const allowed = ['position_id', 'start_date', 'end_date', 'start', 'end', 'employment_period_id', 'employment_type_id', 'team_notification', 'request_approver_notification'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/positions/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEmployeePosition(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/positions/${id}`, 'user');
  },

  // Employee's Schedule
  getEmployeeLastSchedule(uuid) {
    return this.get(`/api/v1/employees/${uuid}/schedule`, 'user');
  },
  getEmployeeSchedules(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/schedules${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeSchedule(uuid, id, queries) {
    return this.get(`/api/v1/employees/${uuid}/schedules/${id}${objectToQueryString(queries)}`, 'user');
  },
  createEmployeeSchedule(uuid, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/schedules`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeeSchedule(uuid, id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/schedules/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEmployeeSchedule(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/schedules/${id}`, 'user');
  },

  // Employee's Salaries
  getEmployeeSalaries(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/salaries${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeSalary(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/salaries/${id}`, 'user');
  },
  createEmployeeSalary(uuid, formData) {
    const allowed = ['base_nominal', 'allowance', 'deduction', 'tax', 'reasons', 'custom_reason', 'effective_date', 'pay_type_id', 'pay_period_id', 'override'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/salaries`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeeSalary(uuid, id, formData) {
    const allowed = ['base_nominal', 'allowance', 'deduction', 'tax', 'reasons', 'custom_reason', 'effective_date', 'pay_type_id', 'pay_period_id', 'override'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/salaries/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeeSalaryStatus(uuid, id, formData) {
    const allowed = ['status', 'reasons_reject', 'custom_reason_reject'];
    const filtered = validateObject(formData, allowed);
    return this.patch(
      `/api/v1/employees/${uuid}/salaries/${id}/status`,
      'user',
      new ApiOptions({ requestPayload: filtered, responseErrorJson: false }),
    );
  },
  deleteEmployeeSalary(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/salaries/${id}`, 'user');
  },
  getEmployeeSalaryCurrent(uuid, queries = {}) {
    return this.get(`/api/v1/employees/${uuid}/salaries/active${objectToQueryString(queries)}`, 'user', new ApiOptions({ responseErrorJson: false }));
  },

  // Other Data Setting
  getDataCategories(queries = {}) {
    return this.get(`/api/v1/employees/data/categories${objectToQueryString(queries)}`, 'user');
  },
  getDataCategory(id) {
    return this.get(`/api/v1/employees/data/categories/${id}`, 'user');
  },
  createDataCategory(formData) {
    const allowed = ['name', 'type', 'parent_id', 'value', 'is_editable'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/data/categories`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateDataCategory(id, formData) {
    const allowed = ['name', 'type', 'parent_id', 'value', 'is_editable'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/data/categories/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteDataCategory(id) {
    return this.delete(`/api/v1/employees/data/categories/${id}`, 'user');
  },
  updateDataCategoryBulkParent(formData) {
    return this.patch(
      `/api/v1/employees/data/categories`,
      'user',
      new ApiOptions({ requestPayload: { groups: formData }, responseErrorJson: false }),
    );
  },

  // Roles & Permissions
  getRoles(uuid) {
    return this.get(`/api/v1/employees/${uuid}/roles`, 'user');
  },
  assignRole(uuid, roleName) {
    return this.post(`/api/v1/employees/${uuid}/roles/${roleName}`, 'user', new ApiOptions({ responseErrorJson: false }));
  },
  assignRoles(uuid, formData) {
    const allowed = ['name'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/roles`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  unassignRole(uuid, roleName) {
    return this.delete(`/api/v1/employees/${uuid}/roles/${roleName}`, 'user');
  },
  getPermissionGroups(uuid) {
    return this.get(`/api/v1/employees/${uuid}/permission-groups`, 'user');
  },
  assignPermissionGroup(uuid, formData) {
    const allowed = ['ids'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/permission-groups`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  unassignPermissionGroup(uuid, formData) {
    const allowed = ['ids'];
    const filtered = validateObject(formData, allowed);
    return this.delete(`/api/v1/employees/${uuid}/permission-groups`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  // getPermissions(uuid) {
  //   return this.get(`/api/v1/employees/${uuid}/permissions`, 'user');
  // },
  // assignPermission(uuid, formData) {
  //   const allowed = ['name'];
  //   const filtered = validateObject(formData, allowed);
  //   return this.post(`/api/v1/employees/${uuid}/permissions`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  // },
  // unassignPermission(uuid, formData) {
  //   const allowed = ['name'];
  //   const filtered = validateObject(formData, allowed);
  //   return this.delete(`/api/v1/employees/${uuid}/permissions`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  // },

  // Employee's Custom Schedule
  getEmployeeLastCustomSchedule(id) {
    return this.get(`/api/v1/employees/schedule/${id}/custom-schedule`, 'user');
  },
  getEmployeeCustomSchedules(id, queries) {
    return this.get(`/api/v1/employees/schedules/${id}/custom-schedule${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeCustomSchedule(id, cid) {
    return this.get(`/api/v1/employees/schedules/${id}/custom-schedule/${cid}`, 'user');
  },
  createEmployeeCustomSchedule(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/schedules/${id}/custom-schedule`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeeCustomSchedule(id, cid, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/schedules/${id}/custom-schedule/${cid}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEmployeeCustomSchedule(id, cid) {
    return this.delete(`/api/v1/employees/schedules/${id}/custom-schedule/${cid}`, 'user');
  },

  // Employee's Shift Schedule
  getEmployeeLastShiftSchedule(id) {
    return this.get(`/api/v1/employees/schedule/${id}/shift/period`, 'user');
  },
  getEmployeeShiftSchedules(id, queries) {
    return this.get(`/api/v1/employees/schedules/${id}/shift/period${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeShiftSchedule(id, cid) {
    return this.get(`/api/v1/employees/schedules/${id}/shift/period/${cid}`, 'user');
  },
  getEmployeeShiftScheduleByYearMonth(id, year, month) {
    return this.get(`/api/v1/employees/schedules/${id}/shift/period/${year}/${month}`, 'user');
  },
  createEmployeeShiftSchedule(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/schedules/${id}/shift/period`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeeShiftSchedule(id, cid, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/schedules/${id}/shift/period/${cid}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEmployeeShiftSchedule(id, cid) {
    return this.delete(`/api/v1/employees/schedules/${id}/shift/period/${cid}`, 'user');
  },

  // Employee's payslip
  getEmployeePayslips(uuid, queries) {
    const allowed = ['year'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/employees/${uuid}/payslip/${filteredQs}`, 'user');
  },
  changePasswordPayslips(uuid, formData) {
    return this.post(`/api/v1/employees/${uuid}/payslip-password`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getOTP(uuid) {
    return this.get(`/api/v1/employees/${uuid}/get-otp`, 'user');
  },

  // Employee's Finance Doc
  getEmployeeFinanceDocs(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/finance-docs/${objectToQueryString(queries)}`, 'user');
  },
  
  // Employee Activate
  getHistories(uuid) {
    return this.get(`/api/v1/employees/${uuid}/history`, 'user');
  },
  activateEmployee(uuid, formData) {
    return this.post(`/api/v1/employees/${uuid}/history`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },

  // Export Employee
  getExports(queries, cts = null) {
    return this.get(`/api/v1/employee-exports${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportStatus() {
    return this.get(`/api/v1/attendances/status`, 'user');
  },
  getExportById(id) {
    return this.get(`/api/v1/employee-exports/${id}`, 'user');
  },
  getExportEmployeesById(id, queries = '', cts = null) {
    return this.get(`/api/v1/employee-exports/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportHistoryById(id) {
    return this.get(`/api/v1/employee-exports/${id}/history`, 'user');
  },
  exportEmployee(formData) {
    return this.post(`/api/v1/employee-exports`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },

  // Employee Training & Certification
  getEmployeeTrainings(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/training-certifications${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeTrainingById(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/training-certifications/${id}`, 'user');
  },
  createEmployeeTraining(uuid, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/training-certifications`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeeTraining(uuid, id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/training-certifications/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEmployeeTraining(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/training-certifications/${id}`, 'user');
  },

  // Employee Bonding Contract
  getEmployeeBondingContract(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/bonding-contracts${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeBondingContractById(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/bonding-contracts/${id}`, 'user');
  },
  createEmployeeBondingContract(uuid, formData) {
    return this.post(`/api/v1/employees/${uuid}/bonding-contracts`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateEmployeeBondingContract(uuid, id, formData) {
    return this.put(`/api/v1/employees/${uuid}/bonding-contracts/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deleteEmployeeBondingContract(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/bonding-contracts/${id}`, 'user');
  },

  // Employee Warnings
  fetchWarningType(queries = '', customCompanyId = null) {
    return this.get(`/api/v1/warning-types${objectToQueryString(queries)}`, 'user', new ApiOptions({ customCompanyId }));
  },
  getWarningTypeById(id) {
    return this.get(`/api/v1/warning-types/${id}`, 'user');
  },
  createWarningType(formData) {
    return this.post(`/api/v1/warning-types`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateWarningType(id, formData) {
    return this.put(`/api/v1/warning-types/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deleteWarningType(id) {
    return this.delete(`/api/v1/warning-types/${id}`, 'user');
  },
  getEmployeeWarnings(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/warning${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeWarningById(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/warning/${id}`, 'user');
  },
  createEmployeeWarning(uuid, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/employees/${uuid}/warning`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEmployeeWarning(uuid, id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/employees/${uuid}/warning/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEmployeeWarning(uuid, id) {
    return this.delete(`/api/v1/employees/${uuid}/warning/${id}`, 'user');
  },

  // Employee Transfers
  fetchEmployeeTransfers(queries = '') {
    return this.get(`/api/v1/transfers${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeTransferStatus() {
    return this.get(`/api/v1/transfer-status`, 'user');
  },
  getEmployeeTransferById(id) {
    return this.get(`/api/v1/transfers/${id}`, 'user');
  },
  getEmployeeTransferByMasterData(id, query) {
    return this.get(`/api/v1/transfers/${id}/detail${objectToQueryString(query)}`, 'user');
  },
  getEmployeeTransferHistoryById(id) {
    return this.get(`/api/v1/transfers/${id}/history`, 'user');
  },
  createEmployeeTransfer(formData) {
    return this.post(`/api/v1/transfers`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateEmployeeTransfer(id, formData) {
    return this.put(`/api/v1/transfers/${id}/update`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  retryEmployeeTransfer(id) {
    return this.post(`/api/v1/transfers/${id}/retry`, 'user', new ApiOptions({ responseErrorJson: false }));
  },
  getEmployeeFiles(uuid, queries) {
    const finalQueries = { ...queries, employee_id: uuid };
    return this.get(`/api/v1/employees-mapping${objectToQueryString(finalQueries)}`, 'user');
  },
  getDraftEmployeeTransfer() {
    return this.get(`/api/v1/draft-count`, 'user');
  },
};
