/* eslint-disable no-restricted-globals, no-mixed-operators, no-shadow, prefer-template */
import Vue from 'vue';
/**
 * @module helpers/number
 */
/**
 * Return random integer in between min and max
 *
 * @example
 * getRandomInt(0, 5)
 * => 0, 1, 2, 3, 4 or 5
 *
 * @param  {Number} min minimum possible value
 * @param  {Number} max maximum possible value
 *
 * @return {Number}
 */
export function getRandomInt(min, max) {
  if (max < min) {
    throw new Error('Max must be greater or equal than min');
  }

  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const defaults = {
  numberOfDecimal: '0',
  decimalSeparator: ',',
  thousandSeparator: '.',
  currencyCode: 'idr',
};

/**
 * Delimit number
 *
 * @example
 * delimiter(1000000)
 * => '1.000.000'
 * delimiter(1000.05,',','.')
 * => '1,000.05'
 *
 * @param  {Number|String} val Number to delimit
 * @return {String} Delimited number
 */
export function delimiter(value, decimals = 0, decPoint = ',', thousandsSep = '.') {
  let number = value;
  number = `${number}`.replace(/[^0-9+\-Ee.]/g, '');
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  let s = '';

  const toFixedFix = function(n, prec) {
    if (`${n}`.indexOf('e') === -1) {
      return +`${Math.round(`${n}e+${prec}`)}e-${prec}`;
    }
    const arr = `${n}`.split('e');
    let sig = '';
    if (+arr[1] + prec > 0) {
      sig = '+';
    }
    return (+`${Math.round(`${+arr[0]}e${sig}${+arr[1] + prec}`)}e-${prec}`).toFixed(prec);
  };

  // TODO: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : `${Math.round(n)}`).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec);
}

export function delimiterByCurrency(val) {
  return delimiter(val, defaults.numberOfDecimal, defaults.decimalSeparator, defaults.thousandSeparator);
}

/**
 * Convert number to money
 *
 * @example
 * money(1000000)
 * => 'IDR 1.000.000'
 * money(1000.05, 'usd')
 * => 'USD 1,000.05'
 *
 * @param  {Number|String} val Number to convert
 * @return {String} Converted number in money format
 */
export function money(val, currency = 'default') {
  const defaultCurrency = defaults.currencyCode;
  let currencyCode = currency !== null ? currency : defaultCurrency;
  currencyCode = currencyCode === 'default' ? defaultCurrency : currencyCode;

  const delimited = delimiter(val);
  if (delimited.charAt(0) === '-') {
    return delimited.replace(/^-/g, `-${currencyCode.toUpperCase()}`);
  }
  return `${currencyCode.toUpperCase()} ${delimited}`;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i];
}

export const dayOrDays = (value, full = true, separator = ' ') => {
  const postfix = value >= 2 ? 'days' : 'day';
  if (!full) return postfix;
  return `${value}${separator}${postfix}`;
};

export default {
  getRandomInt,
  delimiter,
  money,
  formatBytes,
  dayOrDays,
};
