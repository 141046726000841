import ApiClient from '~/api';

const initialState = {
  types: [],
  balances: [],
  balanceStatuses: [],
  histories: [],
  balancesMeta: {},
  activeBalance: {},
  requestStatuses: [],
  requests: [],
  requestMeta: {},
  totalPending: 0,
  totalApproved: 0,
  settings: null,
  loadingApprovalLevel: false,
  approvalLevel: {},
  approvalLevels: [],
  approvalLevelsMeta: {},
};

const getters = {
  types: state => state.types,
  balances: state => state.balances,
  balanceStatuses: state => state.balanceStatuses,
  histories: state => state.histories,
  balancesMeta: state => state.balancesMeta,
  activeBalance: state => state.activeBalance,
  requestStatuses: state => state.requestStatuses,
  requests: state => state.requests,
  requestMeta: state => state.requestMeta,
  requestLinks: state => state.requestLinks,
  totalPending: state => state.totalPending,
  totalApproved: state => state.totalApproved,
  settings: state => state.settings,
  loadingApprovalLevel: state => state.loadingApprovalLevel,
  approvalLevel: state => state.approvalLevel,
  approvalLevels: state => state.approvalLevels,
  approvalLevelsMeta: state => state.approvalLevelsMeta,
};

const actionApprovalLevels = {
  async fetchApprovalLevels({ commit }, { query } = {}) {
    try {
      commit('setLoadingApprovalLevel', true);
      const res = await ApiClient.v1.leave.getLeaveApprovalLevels(query);

      if (res.data) {
        commit('setApprovalLevels', res.data);
        if (res.meta) commit('setApprovalLevelsMeta', res.meta);
        commit('setLoadingApprovalLevel', false);
        return res.data;
      }

      commit('setLoadingApprovalLevel', false);
      return [];
    } catch (e) {
      commit('setLoadingApprovalLevel', false);
      return [];
    }
  },

  async fetchApprovalLevel({ commit }, uuid) {
    try {
      const { data } = await ApiClient.v1.leave.getApprovalLevel(uuid);
      commit('setApprovalLevel', data);
      return data;
    } catch (e) {
      commit('setApprovalLevel', {});
      return {};
    }
  },
  async createApprovalLevel({ dispatch, commit }, formData) {
    try {
      const { data } = await ApiClient.v1.leave.createApprovalLevel(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create Approval Level';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateApprovalLevel({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.leave.updateApprovalLevel(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update Approval Level';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async deleteApprovalLevel({ dispatch }, { uuid }) {
    try {
      const { data } = await ApiClient.v1.leave.deleteApprovalLevel(uuid);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete Approval Level';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsLeaveBalances = {
  async fetchLeaveBalances({ commit }, { personal, query } = {}) {
    try {
      commit('setBalances', []);
      commit('setBalancesMeta', {});

      let res = null;

      if (!personal) res = await ApiClient.v1.leave.getLeaveBalances(query);
      else res = await ApiClient.v1.leave.getMyLeaveBalances(query);

      if (res.data) {
        commit('setBalances', res.data);
        if (res.meta) commit('setBalancesMeta', res.meta);

        return res.data;
      }

      commit('setBalances', []);
      commit('setBalancesMeta', {});

      return [];
    } catch (e) {
      commit('setBalances', []);
      commit('setBalancesMeta', {});

      return [];
    }
  },
  async fetchLeaveBalance({ commit }, { id }) {
    try {
      const { data } = await ApiClient.v1.leave.getLeaveBalance(id);
      commit('setActiveBalance', data);
    } catch (e) {
      commit('setActiveBalance', null);
    }
  },
  async fetchBalanceStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.leave.getLeaveBalanceStatus();
      commit('setBalanceStatuses', data);
      return [];
    } catch (e) {
      commit('setBalanceStatuses', []);
      return [];
    }
  },
  async createLeaveBalance({ dispatch, commit }, formData) {
    try {
      const { data } = await ApiClient.v1.leave.createLeaveBalance(formData);
      commit('setBalance', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create Balance';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateLeaveBalance({ dispatch, commit }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.leave.updateLeaveBalance(id, formData);
      commit('updateBalance', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteLeaveBalance({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.leave.deleteLeaveBalance(id);
      commit('deleteBalance', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateLeaveBalanceStatus({ commit, dispatch }, { id, idx = null, formData }) {
    try {
      const { data } = await ApiClient.v1.leave.updateLeaveBalanceStatus(id, formData);
      commit('updateBalance', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actions = {
  async fetchTypeList({ commit }, { query, customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.leave.getLeaveType(query, customCompanyId);
      commit('setTypes', data);
      return data;
    } catch (e) {
      commit('setTypes', []);
      return [];
    }
  },
  getLeaveTypeById(_, id) {
    return ApiClient.v1.leave.getLeaveTypeById(id);
  },
  addType(_, formData) {
    return ApiClient.v1.leave.postLeaveType(formData);
  },
  updateType(_, { id, formData }) {
    return ApiClient.v1.leave.putLeaveType(id, formData);
  },
  deleteType(_, id) {
    return ApiClient.v1.leave.deleteLeaveType(id);
  },
  updateTypeStatus(_, { id, formData }) {
    return ApiClient.v1.leave.updateLeaveTypeStatus(id, formData);
  },
  addTypeItem({ commit }, formData) {
    commit('addTypeItem', formData);
  },
  updateTypeItem({ commit }, { idx, formData }) {
    commit('updateTypeItem', { idx, formData });
  },
  deleteTypeItem({ commit }, { idx }) {
    commit('deleteTypeItem', idx);
  },

  async fetchAvailableTypeList({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.leave.getAvailableLeaveType(id);
      commit('setTypes', data);
      return [];
    } catch (e) {
      commit('setTypes', []);
      return [];
    }
  },

  ...actionApprovalLevels,
  ...actionsLeaveBalances,

  async fetchRequestStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.leave.getLeaveTypeStatus();
      commit('setRequestStatuses', data);
      return [];
    } catch (e) {
      commit('setRequestStatuses', []);
      return [];
    }
  },
  async fetchRequest({ commit }, { personal, query }) {
    try {
      commit('setRequests', []);
      commit('setRequestMeta', {});

      let res = null;

      if (personal) res = await ApiClient.v1.leave.getMyLeaveRequest(query);
      else res = await ApiClient.v1.leave.getLeaveRequest(query);

      if (res.data && res.meta) {
        commit('setRequests', res.data);
        commit('setRequestMeta', res.meta);
      }
      return [];
    } catch (e) {
      commit('setRequests', []);
      commit('setRequestMeta', {});
      return [];
    }
  },
  async fetchRequestById(_, { personal, id }) {
    try {
      let res = null;
      if (personal) res = await ApiClient.v1.leave.getMyLeaveRequestById(id);
      else res = await ApiClient.v1.leave.getLeaveRequestById(id);
      return res;
    } catch (e) {
      // return [];
      return { errors: e, data: [] };
    }
  },
  async fetchHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.leave.getLeaveRequestHistoryById(id);
      commit('setHistories', data);
      return data;
    } catch (e) {
      commit('setHistories', []);
      return [];
    }
  },
  createRequest(_, { formData }) {
    return ApiClient.v1.leave.createLeaveRequest({ ...formData, employee_id: this.$auth.user.uuid });
  },
  updateRequest(_, { id, formData }) {
    return ApiClient.v1.leave.updateLeaveRequest(id, { ...formData, employee_id: this.$auth.user.uuid });
  },
  updateStatus(_, { id, formData }) {
    return ApiClient.v1.leave.setLeaveRequestStatus(id, { ...formData });
  },
  fetchAvailableDates(_, formData) {
    return ApiClient.v1.leave.fetchAvailableDates(formData);
  },
  fetchWhosOut(_, formData) {
    return ApiClient.v1.leave.fetchWhosOut(formData);
  },
  async fetchSetting({ commit, state }, { key }) {
    try {
      let { settings } = state;

      const lsKey = 'leave_settings';
      if (sessionStorage.getItem(lsKey)) {
        try {
          settings = JSON.parse(sessionStorage.getItem(lsKey));
        } catch (e) {
          sessionStorage.removeItem(lsKey);
        }
      } else {
        const { data } = await ApiClient.v1.leave.getAllSettings();
        const initialValue = {};
        settings = data.reduce((obj, item) => {
          return {
            ...obj,
            [item.key]: item.value,
          };
        }, initialValue);

        sessionStorage.setItem(lsKey, JSON.stringify(settings));
      }

      commit('setSettings', settings);

      if (key) {
        if (key === 'all') return settings;

        let newKey = '';
        if (key === 'status_default') {
          newKey = 'leave_requests_default_status';
        } else {
          newKey = key;
        }

        return settings[newKey];
      }

      return null;
    } catch (e) {
      return null;
    }
  },
  async fetchRequestCount({ commit }, uuid = null) {
    try {
      const { data: data0 } = await ApiClient.v1.leave.getLeaveRequestCount({
        request_status_id_by_setting: 'leave_requests_submitted_status',
        employee_id: uuid,
      });
      commit('setTotalPending', data0);

      const { data: data1 } = await ApiClient.v1.leave.getLeaveRequestCount({
        request_status_id_by_setting: 'leave_requests_approved_status',
        employee_id: uuid,
      });
      commit('setTotalApproved', data1);

      return [];
    } catch (e) {
      commit('setTotalPending', 0);
      commit('setTotalApproved', 0);
      return [];
    }
  },

  cancelApproved(_, { id, formData }) {
    return ApiClient.v1.leave.cancelApproved(id, { ...formData });
  },
  getOffDate(_, { id, formData }) {
    return ApiClient.v1.leave.getOffDate(id, formData);
  },
  async getMappingTransferLeave({ commit }, { id, period }) {
    return ApiClient.v1.leave.getMappingTransferLeave(id, period);
  },
};

const mutations = {
  setHistories(state, data) {
    state.histories = data;
  },  // LeaveBalances
  setBalances(state, data) {
    state.balances = data;
  },
  setBalancesMeta(state, data) {
    state.balancesMeta = data;
  },
  setBalanceStatuses(state, value) {
    state.balanceStatuses = value;
  },
  setActiveBalance(state, value) {
    state.activeBalance = value;
  },
  setBalance(state, data) {
    state.balances.splice(0, 0, data);
  },
  updateBalance(state, { idx, data }) {
    state.balances.splice(idx, 1, data);
  },
  deleteBalance(state, idx) {
    state.balances.splice(idx, 1);
  },

  setTypes(state, value) {
    state.types = value;
  },
  addTypeItem(state, formData) {
    // state.types.push(formData); // Add to the end of the list
    state.types.splice(0, 0, formData); // Add to the beginning of the list
  },
  updateTypeItem(state, { idx, formData }) {
    state.types.splice(idx, 1, formData);
  },
  deleteTypeItem(state, idx) {
    state.types.splice(idx, 1);
  },
  setRequestStatuses(state, value) {
    state.requestStatuses = value;
  },
  setRequests(state, value) {
    state.requests = value;
  },
  setRequestMeta(state, value) {
    state.requestMeta = value;
  },
  setTotalPending(state, value) {
    state.totalPending = value;
  },
  setTotalApproved(state, value) {
    state.totalApproved = value;
  },
  setSettings(state, value) {
    state.settings = value;
  },
  setLoadingApprovalLevel(state, value) {
    state.loadingApprovalLevel = value;
  },
  setApprovalLevel(state, data) {
    state.approvalLevel = data;
  },
  updateApprovalLevel(state, data) {
    state.approvalLevel = data;
  },
  setApprovalLevels(state, data) {
    state.approvalLevels = data;
  },
  setApprovalLevelsMeta(state, data) {
    state.approvalLevelsMeta = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
