//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NuxtError',
  head() {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0',
        },
      ],
    };
  },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
    message() {
      return this.error.message;
    },
    appName() {
      return process.env.APP_NAME;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  created() {
    if (this.error && this.error.statusCode === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('auth._token.local');
      localStorage.removeItem('auth._refresh_token.local');
      window.location = '/login';
    }
  },
};
