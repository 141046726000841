//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CLoadingBar',
  props: {
    radius: {
      type: Number,
      default: null,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    expectedRadius() {
      return this.circle ? 0 : this.radius;
    },
  },
};
