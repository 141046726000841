import { render, staticRenderFns } from "./error.vue?vue&type=template&id=8a31f928&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=8a31f928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a31f928",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CPage404: require('/home/web/alpha-fe/components/page/error/CPage404.vue').default,CPage503: require('/home/web/alpha-fe/components/page/error/CPage503.vue').default,CLogo: require('/home/web/alpha-fe/components/CLogo.vue').default})
