import ApiOptions from '../api-options';
import { validateObject } from '~/helpers/url-helper';

export default {
  getAvailableMenus(formData) {
    return this.post(`/api/v1/permissions/menus`, 'user', new ApiOptions({ requestPayload: { links: formData }, responseErrorJson: false }));
  },
  getProfileTabs(formData) {
    return this.post(`/api/v1/permissions/profile-tabs`, 'user', new ApiOptions({ requestPayload: { links: formData }, responseErrorJson: false }));
  },

  validateRoute(name) {
    return this.post(`/api/v1/permissions/validate_route`, 'user', new ApiOptions({ requestPayload: { name }, responseErrorJson: false }));
  },

  getPermissions() {
    return this.get(`/api/v1/permissions`, 'user');
  },
  getPermissionGroups(asTree = false) {
    let qs = '';
    if (asTree) qs = `?as=tree`;
    return this.get(`/api/v1/permission-groups${qs}`, 'user');
  },
  getPermissionGroup(id) {
    return this.get(`/api/v1/permission-groups/${id}`, 'user');
  },
  createPermissionGroup(formData) {
    const allowed = ['parent_id', 'permission_id', 'name', 'description'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/permission-groups`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updatePermissionGroup(id, formData) {
    const allowed = ['parent_id', 'permission_id', 'name', 'description'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/permission-groups/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deletePermissionGroup(id) {
    return this.delete(`/api/v1/permission-groups/${id}`, 'user');
  },
  updatePermissionGroupBulkParent(updates) {
    return this.patch(`/api/v1/permission-groups`, 'user', new ApiOptions({ requestPayload: { groups: updates }, responseErrorJson: false }));
  },
  updatePermissionGroupParent(id, parentId) {
    return this.patch(
      `/api/v1/permission-groups/${id}/parent`,
      'user',
      new ApiOptions({ requestPayload: { parent_id: parentId }, responseErrorJson: false }),
    );
  },
};
