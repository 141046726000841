//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CValidationError',
  props: {
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
    keys: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    isError() {
      return this.errors && (this.errors.client && this.errors.client.length) || (this.errors.server && Object.keys(this.errors.server).length);
    },
  },
};
