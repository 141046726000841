import ApiClient from '~/api';

const initialState = {
  roles: [],
  rolesMeta: {},
  permissionGroups: [],
};

const getters = {
  roles: state => state.roles,
  rolesMeta: state => state.rolesMeta,
  permissionGroups: state => state.permissionGroups,
};

const actions = {
  // Roles
  async fetchRoles({ commit, dispatch }, query) {
    try {
      const { data, meta } = await ApiClient.v1.role.getRoles(query);
      commit('setRoles', data);
      commit('setRolesMeta', meta);

      return data;
    } catch (e) {
      commit('setRoles', []);
      commit('setRolesMeta', {});

      const caption = 'Unable to load roles list';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async fetchRole({ dispatch }, name) {
    try {
      const { data } = await ApiClient.v1.role.getRole(name);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load role information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async createRole({ commit, dispatch }, { formData = {} }) {
    try {
      const { data } = await ApiClient.v1.role.createRole(formData);
      commit('setRole', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to create role';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateRole({ commit, dispatch }, { name, idx = 0, formData = {} }) {
    try {
      const { data } = await ApiClient.v1.role.updateRole(name, formData);
      commit('updateRole', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update role information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteRole({ commit, dispatch }, { name, idx = 0 }) {
    try {
      const { data } = await ApiClient.v1.role.deleteRole(name);
      commit('deleteRole', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete role';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateRoleStatus({ commit, dispatch }, { name, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.role.updateRoleStatus(name, formData);
      commit('updateRole', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update role information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchRolePermissions({ commit, dispatch }, { name }) {
    try {
      const { data } = await ApiClient.v1.role.getPermissionsToRoles(name);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = "Unable to fetch role's permissions";
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateRolePermissions({ commit, dispatch }, { name, formData }) {
    try {
      const { data } = await ApiClient.v1.role.attachPermissionsToRoles(name, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update role permissions';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployees({ dispatch }, { name }) {
    try {
      const { data, meta } = await ApiClient.v1.role.getRoleEmployees(name);

      return { errors: null, data, meta };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [], meta: {} };
      }

      const caption = 'Unable to load employee list';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [], meta: {} };
    }
  },

  // PermissionGroups
  async fetchPermissionGroups({ commit, dispatch }, asTree) {
    try {
      const { data } = await ApiClient.v1.permission.getPermissionGroups(asTree);
      commit('setPermissionGroups', data);

      return data;
    } catch (e) {
      commit('setPermissionGroups', []);

      const caption = 'Unable to load permission list';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async fetchPermissionGroup({ dispatch }, name) {
    try {
      const { data } = await ApiClient.v1.permission.getPermissionGroup(name);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load permission information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async createPermissionGroup({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.permission.createPermissionGroup(formData);
      commit('setPermissionGroup', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to create permission';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updatePermissionGroup({ commit, dispatch }, { name, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.permission.updatePermissionGroup(name, formData);
      commit('updatePermissionGroup', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update permission information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deletePermissionGroup({ commit, dispatch }, { name, idx }) {
    try {
      const { data } = await ApiClient.v1.permission.deletePermissionGroup(name);
      commit('deletePermissionGroup', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete role';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updatePermissionGroupStatus({ commit, dispatch }, { name, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.permission.updatePermissionGroupStatus(name, formData);
      commit('updatePermissionGroup', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update role information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const mutations = {
  // Roles
  setRoles(state, data) {
    state.roles = data;
  },
  setRolesMeta(state, data) {
    state.rolesMeta = data;
  },
  setRole(state, data) {
    state.roles.splice(0, 0, data);
  },
  updateRole(state, { idx, data }) {
    state.roles.splice(idx, 1, data);
  },
  deleteRole(state, idx) {
    state.roles.splice(idx, 1);
  },

  // Permission Groups
  setPermissionGroups(state, data) {
    state.permissionGroups = data;
  },
  setPermissionGroup(state, data) {
    state.permissionGroups.splice(0, 0, data);
  },
  updatePermissionGroup(state, { idx, data }) {
    state.permissionGroups.splice(idx, 1, data);
  },
  deletePermissionGroup(state, idx) {
    state.permissionGroups.splice(idx, 1);
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
