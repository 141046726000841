import { render, staticRenderFns } from "./CTopBar.vue?vue&type=template&id=97129bba&scoped=true&"
import script from "./CTopBar.vue?vue&type=script&lang=js&"
export * from "./CTopBar.vue?vue&type=script&lang=js&"
import style0 from "./CTopBar.vue?vue&type=style&index=0&id=97129bba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97129bba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CTopBarLink: require('/home/web/alpha-fe/components/navbar/CTopBarLink.vue').default,CLoadingBar: require('/home/web/alpha-fe/components/CLoadingBar.vue').default,CProfileCompany: require('/home/web/alpha-fe/components/CProfileCompany.vue').default,CWidgetChangePassword: require('/home/web/alpha-fe/components/widget/CWidgetChangePassword.vue').default})
