import ApiClient from '~/api';

const initialState = {};

const getters = {};

const actions = {
  fetchBirthday(_, formData) {
    return ApiClient.v1.birthday.getBirthday(formData);
  },
};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
