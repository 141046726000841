import Vue from 'vue';
import VueCookies from 'vue-cookies';
import ApiClient from '~/api';
import { decryptText } from '~/helpers/crypt';

Vue.use(VueCookies);

const initialState = {
  userRoles: null,
  permissions: null,
  routes: null,
};

const getters = {
  userRoles: state => state.userRoles,
  permissions: state => state.permissions,
  routes: state => state.routes,
};

const actions = {
  // Get Routes
  async getRoutes({ state, commit }) {
    try {
      if (state.routes === null) {
        const url = '/data/all-routes.txt';
        const routes = await this.$axios.get(url);
        const decryptedData = decryptText(routes.data);
        commit('setRoutes', decryptedData);
        return decryptedData;
      }

      return state.routes;
    } catch (e) {
      commit('setRoutes', null);
      return [];
    }
  },

  // Get Permissions
  async getPermissions({ state, commit }) {
    try {
      if (state.permissions === null) {
        const url = '/data/role-permissions.txt';
        const permissions = await this.$axios.get(url);
        const decryptedData = decryptText(permissions.data);
        const parsed = JSON.parse(decryptedData);
        commit('setPermissions', parsed);
        return parsed;
      }
      return state.permissions;
    } catch (e) {
      commit('setPermissions', []);
      return [];
    }
  },

  // Get Roles
  async getUserRoles({ state, commit, dispatch }, _) {
    try {
      if (state.userRoles === null) {
        if (Vue.$cookies.get('roles') === null || Vue.$cookies.get('roles') === undefined) {
          const { data } = await ApiClient.v1.role.getUserRoles();
          commit('setUserRoles', data);
          return data;
        }

        commit('setUserRoles', JSON.parse(Vue.$cookies.get('roles')));
        return JSON.parse(Vue.$cookies.get('roles'));
      }
      return state.userRoles;
    } catch (e) {
      commit('setUserRoles', null);

      const caption = 'Unable to load roles';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
};

const mutations = {
  setRoutes(state, data) {
    state.routes = data;
  },
  setPermissions(state, data) {
    state.permissions = data;
  },
  setUserRoles(state, data) {
    state.userRoles = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
