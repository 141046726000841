import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_business_trip_index' */ '~/pages/admin/business-trip/Index');
const List = () => import(/* webpackChunkName: 'admin_business_trip_list' */ '~/pages/admin/business-trip/List');
const Detail = () => import(/* webpackChunkName: 'admin_business_trip_detail' */ '~/pages/admin/business-trip/Detail');

export default {
  path: 'business-travel',
  component: r(Index),
  children: [
    { path: 'list', component: r(List), name: 'admin_business_trip_list' },
    { path: 'detail/:id(\\d+)', component: r(Detail), name: 'admin_business_trip_detail' },
  ],
};
