import {  getYears } from '~/helpers/super-date';

const initialState = {
  days: [
    { id: 'Sunday', name: 'Sunday' },
    { id: 'Monday', name: 'Monday' },
    { id: 'Tuesday', name: 'Tuesday' },
    { id: 'Wednesday', name: 'Wednesday' },
    { id: 'Thursday', name: 'Thursday' },
    { id: 'Friday', name: 'Friday' },
    { id: 'Saturday', name: 'Saturday' },
  ],
  months: [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ],
  dateFormats: [
    { id: '%d%-%m%-%Y%', name: 'd-m-Y' },
    { id: '%Y%-%m%-%d%', name: 'Y-m-d' },
  ],
  timeFormats: [
    { id: '%H%:%I%', name: 'HH:mm' },
    { id: '%g%:%i% %A%', name: 'H:i AM/PM' },
  ],
};

const getters = {
  days: state => state.days,
  dateFormats: state => state.dateFormats,
  timeFormats: state => state.timeFormats,
  months: state => state.months,
  month: state => id => {
    return state.months.find(month => month.id === id);
  },
  years: () => {
    const ys = getYears();

    const ret = [];
    ys.forEach(element => {
      ret.push({ id: element, name: element });
    });

    return ret;
  },
};

const actions = {};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
