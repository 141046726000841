import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_attendance_index' */ '~/pages/admin/attendance/Index');
const List = () => import(/* webpackChunkName: 'admin_attendance_list' */ '~/pages/admin/attendance/List');
const Detail = () => import(/* webpackChunkName: 'admin_attendance_detail' */ '~/pages/admin/attendance/Detail');
const Report = () => import(/* webpackChunkName: 'admin_attendance_report' */ '~/pages/admin/attendance/Report');

const ResyncIndex = () => import(/* webpackChunkName: 'admin_attendance_resync_index' */ '~/pages/admin/attendance/ResyncIndex');
const ResyncList = () => import(/* webpackChunkName: 'admin_attendance_resync_list' */ '~/pages/admin/attendance/ResyncList');
const ResyncDetail = () => import(/* webpackChunkName: 'admin_attendance_resync_detail' */ '~/pages/admin/attendance/ResyncDetail');

const ExportIndex = () => import(/* webpackChunkName: 'admin_attendance_export_index' */ '~/pages/admin/attendance/ExportIndex');
const ExportList = () => import(/* webpackChunkName: 'admin_attendance_export_list' */ '~/pages/admin/attendance/ExportList');
const ExportDetail = () => import(/* webpackChunkName: 'admin_attendance_export_detail' */ '~/pages/admin/attendance/ExportDetail');

const ExportAttendanceIndex = () => import(/* webpackChunkName: 'admin_attendance_export_attendance_index' */ '~/pages/admin/attendance/ExportAttendanceIndex');
const ExportAttendanceList = () => import(/* webpackChunkName: 'admin_attendance_export_attendance_list' */ '~/pages/admin/attendance/ExportAttendanceList');
const ExportAttendanceDetail = () => import(/* webpackChunkName: 'admin_attendance_export_attendance_detail' */ '~/pages/admin/attendance/ExportAttendanceDetail');

export default {
  path: 'attendance',
  component: r(Index),
  children: [
    { path: '', redirect: '/admin/attendance/list', name: 'admin_attendance_index' },
    {
      path: 'list',
      component: r(List),
      name: 'admin_attendance_list',
    },
    {
      path: 'report',
      component: r(Report),
      name: 'admin_attendance_report',
    },
    {
      path: 'resync',
      component: r(ResyncIndex),
      children: [
        { path: '', name: 'admin_attendance_resync_list', component: r(ResyncList) },
        { path: ':id', name: 'admin_attendance_resync_detail', component: r(ResyncDetail) },
      ],
    },
    {
      path: 'export',
      component: r(ExportIndex),
      children: [
        { path: '', name: 'admin_attendance_export_list', component: r(ExportList) },
        { path: ':id', name: 'admin_attendance_export_detail', component: r(ExportDetail) },
      ],
    },
    {
      path: 'export-attendance',
      component: r(ExportAttendanceIndex),
      children: [
        { path: '', name: 'admin_attendance_export_attendance_list', component: r(ExportAttendanceList) },
        { path: ':id', name: 'admin_attendance_export_attendance_detail', component: r(ExportAttendanceDetail) },
      ],
    },
    {
      path: 'detail/:id',
      component: r(Detail),
      name: 'admin_attendance_detail',
    },
  ],
};
