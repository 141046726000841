// import Vue from 'vue';

export async function checkAccess(access, permissions, routes, roles) {
  const BreakException = {};
  let found = false;

  try {
    roles.forEach(role => {
      routes[access].forEach(permission => {
        const cek = permissions[role].find(x => x === permission);
        /* let inMainCompany = true;
        if (Vue.$cookies.get('selected_company')) {
          inMainCompany = parseInt(Vue.$cookies.get('main_company'), 10) === parseInt(Vue.$cookies.get('selected_company').id, 10);
        } */

        if (cek) {
          // hide employee menu when not in main company 
          /* if (!permission.includes('page.user.') || (permission.includes('page.user.') && inMainCompany)) {
            throw BreakException;
          } */
          throw BreakException;
        }
      });
    });
  } catch (e) {
    if (e === BreakException) found = true;
  }
  return found;
};

export default {
  checkAccess,
};
