const initialState = {
  languages: [],
};

const getters = {
  languages: state => state.languages,
};

const actions = {
  async getLanguages({ commit }) {
    try {
      const url = '/data/list-language.json';
      const { data } = await this.$axios.get(url);
      commit('setLanguages', data);
      return [];
    } catch (e) {
      commit('setLanguages', []);
      return [];
    }
  },
};

const mutations = {
  setLanguages(state, value) {
    state.languages = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
