import Attendance from '~/api/interfaces/attendance-api';
import Birthday from '~/api/interfaces/birthday-api';
import Document from '~/api/interfaces/document-api';
import Currency from '~/api/interfaces/currency-api';
import Employee from '~/api/interfaces/employee-api';
import Expense from '~/api/interfaces/expense-api';
import Leave from '~/api/interfaces/leave-api';
import Schedule from '~/api/interfaces/schedule-api';
import User from '~/api/interfaces/user-api';
import Department from '~/api/interfaces/department-api';
import Setting from '~/api/interfaces/setting-api';
import Payroll from '~/api/interfaces/payroll-api';
import Permission from '~/api/interfaces/permission-api';
import Role from '~/api/interfaces/role-api';
import Company from '~/api/interfaces/company-api';
import Recruitment from '~/api/interfaces/recruitment-api';
import Trip from '~/api/interfaces/trip-api';
import Appraisal from '~/api/interfaces/appraisal-api';

export default {
  Attendance,
  Birthday,
  Document,
  Currency,
  Employee,
  Expense,
  Leave,
  Schedule,
  User,
  Department,
  Setting,
  Payroll,
  Permission,
  Role,
  Company,
  Recruitment,
  Trip,
  Appraisal,
};
