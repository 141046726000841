import { render, staticRenderFns } from "./CWidgetChangePassword.vue?vue&type=template&id=98db797e&scoped=true&"
import script from "./CWidgetChangePassword.vue?vue&type=script&lang=js&"
export * from "./CWidgetChangePassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98db797e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CFormLabel: require('/home/web/alpha-fe/components/CFormLabel.vue').default,CInputPassword: require('/home/web/alpha-fe/components/CInputPassword.vue').default,CValidationError: require('/home/web/alpha-fe/components/CValidationError.vue').default,CButton: require('/home/web/alpha-fe/components/CButton.vue').default,CModal: require('/home/web/alpha-fe/components/CModal.vue').default})
