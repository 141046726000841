import Vue from 'vue';
import axios from 'axios';
import ApiClient from '~/api';

const initialState = {
  templates: [],
  templatesMeta: {},
  templateStatuses: [],
  scores: [],
  scoresMeta: {},
  scoreStatuses: [],
  cycles: [],
  cyclesMeta: {},
  cycleStatuses: [],
  cycleReviews: [],
  cycleReviewsMeta: {},
  employeeReviewers: [],
  employeeReviewersMeta: {},
  reviews: [],
  reviewsMeta: {},
  reviewStatuses: [],
  reviewHistories: [],
  reviewCount: {},
  indisciplines: [],
  indisciplinesMeta: {},
  indisciplineStatuses: [],
  recommendations: [],
  recommendationsMeta: {},
  recommendationStatuses: [],
  exports: [],
  exportsMeta: {},
  exportStatuses: [],
  exportEmployees: [],
  exportEmployeesMeta: {},
  exportHistories: [],

  activePeriods: [],

  alphaData: [],
  timeframes: [],
  reviewMethods: [],
};

const getters = {
  templates: state => state.templates,
  templatesMeta: state => state.templatesMeta,
  templateStatuses: state => state.templateStatuses,
  scores: state => state.scores,
  scoresMeta: state => state.scoresMeta,
  scoreStatuses: state => state.scoreStatuses,
  cycles: state => state.cycles,
  cyclesMeta: state => state.cyclesMeta,
  cycleStatuses: state => state.cycleStatuses,
  cycleReviews: state => state.cycleReviews,
  cycleReviewsMeta: state => state.cycleReviewsMeta,
  employeeReviewers: state => state.employeeReviewers,
  employeeReviewersMeta: state => state.employeeReviewersMeta,
  reviews: state => state.reviews,
  reviewsMeta: state => state.reviewsMeta,
  reviewStatuses: state => state.reviewStatuses,
  reviewHistories: state => state.reviewHistories,
  reviewCount: state => state.reviewCount,
  indisciplines: state => state.indisciplines,
  indisciplinesMeta: state => state.indisciplinesMeta,
  indisciplineStatuses: state => state.indisciplineStatuses,
  recommendations: state => state.recommendations,
  recommendationsMeta: state => state.recommendationsMeta,
  recommendationStatuses: state => state.recommendationStatuses,
  exports: state => state.exports,
  exportsMeta: state => state.exportsMeta,
  exportStatuses: state => state.exportStatuses,
  exportEmployees: state => state.exportEmployees,
  exportEmployeesMeta: state => state.exportEmployeesMeta,
  exportHistories: state => state.exportHistories,

  activePeriods: state => state.activePeriods,

  alphaData: state => state.alphaData,
  timeframes: state => state.timeframes,
  reviewMethods: state => state.reviewMethods,
};

const actions = {
  async fetchTemplates({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getTemplates(query, cts);

      commit('setTemplates', data);
      commit('setTemplatesMeta', meta);
      return data;
    } catch (e) {
      commit('setTemplates', []);
      commit('setTemplatesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchTemplateById(_, id) {
    return ApiClient.v1.appraisal.getTemplateById(id);
  },
  async fetchTemplateStatus({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getPAStatus({ type: 'template' });
      commit('setTemplateStatuses', data);
      return [];
    } catch (e) {
      commit('setTemplateStatuses', []);

      const caption = 'Failed to Load Template Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async createTemplate({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.createTemplate(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async duplicateTemplate({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.duplicateTemplate(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Duplicate';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateTemplate({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.updateTemplate(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  updateTemplateStatus(_, id) {
    return ApiClient.v1.appraisal.updateTemplateStatus(id);
  },
  async deleteTemplate({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.deleteTemplate(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete template';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchScores({ commit, dispatch }, { query = {}, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getScores(query, cts);

      commit('setScores', data);
      commit('setScoresMeta', meta);
      return data;
    } catch (e) {
      commit('setScores', []);
      commit('setScoresMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchScoreById(_, id) {
    return ApiClient.v1.appraisal.getScoreById(id);
  },
  async fetchScoreStatus({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getPAStatus({ type: 'score_type' });
      commit('setScoreStatuses', data);
      return [];
    } catch (e) {
      commit('setScoreStatuses', []);

      const caption = 'Failed to Load Score Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async createScore({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.createScore(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async duplicateScore({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.duplicateScore(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Duplicate';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateScore({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.updateScore(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  updateScoreStatus(_, id) {
    return ApiClient.v1.appraisal.updateScoreStatus(id);
  },
  async deleteScore({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.deleteScore(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete score';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchCycles({ commit, dispatch }, { query = {}, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getCycles(query, cts);

      commit('setCycles', data);
      commit('setCyclesMeta', meta);
      return data;
    } catch (e) {
      commit('setCycles', []);
      commit('setCyclesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchCycleStatus({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getPAStatus({ type: 'cycle' });
      commit('setCycleStatuses', data);
      return [];
    } catch (e) {
      commit('setCycleStatuses', []);

      const caption = 'Failed to Load PA Cycle Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async createCycle({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.createCycle(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async duplicateCycle({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.duplicateCycle(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Duplicate';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateCycle({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.updateCycle(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  updateCycleStatus(_, id) {
    return ApiClient.v1.appraisal.updateCycleStatus(id);
  },
  async deleteCycle({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.deleteCycle(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete PA cycle';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  fetchCycleById(_, id) {
    return ApiClient.v1.appraisal.getCycleById(id);
  },
  async fetchCycleReviews({ commit, dispatch }, { query = {}, id = null, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getCycleReviews(query, id, cts);

      commit('setCycleReviews', data);
      commit('setCycleReviewsMeta', meta);
      return data;
    } catch (e) {
      commit('setCycleReviews', []);
      commit('setCycleReviewsMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchReviews({ commit, dispatch }, { query, cts = null, personal = null }) {
    try {
      let res = null;

      if (!personal) res = await ApiClient.v1.appraisal.getReviews(query, cts);
      else res = await ApiClient.v1.appraisal.getMyReviews(query, cts);

      commit('setReviews', res.data);
      commit('setReviewsMeta', res.meta);
      return res.data;
    } catch (e) {
      commit('setReviews', []);
      commit('setReviewsMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchReviewStatus({ commit, dispatch }, { query }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getPAStatus(query);
      commit('setReviewStatuses', data);
      return [];
    } catch (e) {
      commit('setReviewStatuses', []);

      const caption = 'Failed to Load Review Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchReviewById(_, { id, query }) {
    return ApiClient.v1.appraisal.getReviewById(id, query);
  },
  async fetchEmployeeReviewers({ commit, dispatch }, { query = {}, id = null, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getEmployeeReviewers(query, id, cts);

      commit('setEmployeeReviewers', data);
      commit('setEmployeeReviewersMeta', meta);
      return data;
    } catch (e) {
      commit('setEmployeeReviewers', []);
      commit('setEmployeeReviewersMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchReviewHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.appraisal.getReviewHistoryById(id);
      commit('setReviewHistories', data);
      return data;
    } catch (e) {
      commit('setReviewHistories', []);
      return [];
    }
  },
  fetchReviewDetailById(_, id) {
    return ApiClient.v1.appraisal.getReviewDetailById(id);
  },
  fetchReviewDetailFormById(_, { id, query }) {
    return ApiClient.v1.appraisal.getReviewDetailFormById(id, query);
  },
  async addReviewer({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.addReviewer(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Add';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async assignReviewer({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.assignReviewer(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Assign';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async changeReviewer({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.changeReviewer(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Change';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async shareAccessReviewer({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.shareAccessReviewer(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Share Access';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async removeAccessReviewer({ dispatch }, { id, shareId }) {
    try {
      const { data } = await ApiClient.v1.appraisal.removeAccessReviewer(id, shareId);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Remove Access';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async cancelReviewer({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.cancelReviewer(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Cancel';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async startReview({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.startReview(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Start';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async cancelReview({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.cancelReview(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Cancel';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async finishReview({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.finishReview(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Finish';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async recalculateIndiscipline({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.recalculateIndiscipline(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Recalculate';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async saveIndiscipline({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.saveIndiscipline(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Save';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async saveReviewForm({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.saveReviewForm(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Save';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async setAppraisalStatus({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.setAppraisalStatus(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Change Status';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchReviewCount({ commit, dispatch }, { query = {} }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getReviewCount(query);
      commit('setReviewCount', data);
      return data;
    } catch (e) {
      commit('setReviewCount', {});

      const caption = 'Failed to Load Review Count';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return {};
    }
  },
  async fetchIndisciplines({ commit, dispatch }, { query = {}, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getIndisciplines(query, cts);

      commit('setIndisciplines', data);
      commit('setIndisciplinesMeta', meta);
      return data;
    } catch (e) {
      commit('setIndisciplines', []);
      commit('setIndisciplinesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Indisciplines List';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchIndisciplineById(_, id) {
    return ApiClient.v1.appraisal.getIndisciplineById(id);
  },
  async fetchIndisciplineStatus({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getPAStatus({ type: 'score_type' });
      commit('setIndisciplineStatuses', data);
      return [];
    } catch (e) {
      commit('setIndisciplineStatuses', []);

      const caption = 'Failed to Load Indiscipline Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async createIndiscipline({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.createIndiscipline(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async duplicateIndiscipline({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.duplicateIndiscipline(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Duplicate';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateIndiscipline({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.updateIndiscipline(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  updateIndisciplineStatus(_, id) {
    return ApiClient.v1.appraisal.updateIndisciplineStatus(id);
  },
  async deleteIndiscipline({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.deleteIndiscipline(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete indiscipline';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchRecommendations({ commit, dispatch }, { query = {}, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getRecommendations(query, cts);

      commit('setRecommendations', data);
      commit('setRecommendationsMeta', meta);
      return data;
    } catch (e) {
      commit('setRecommendations', []);
      commit('setRecommendationsMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Recommendations List';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchRecommendationById(_, id) {
    return ApiClient.v1.appraisal.getRecommendationById(id);
  },
  async fetchRecommendationStatus({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getPAStatus({ type: 'score_type' });
      commit('setRecommendationStatuses', data);
      return [];
    } catch (e) {
      commit('setRecommendationStatuses', []);

      const caption = 'Failed to Load Recommendation Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async createRecommendation({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.createRecommendation(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateRecommendation({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.updateRecommendation(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  updateRecommendationStatus(_, id) {
    return ApiClient.v1.appraisal.updateRecommendationStatus(id);
  },
  async deleteRecommendation({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.deleteRecommendation(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete recommendation';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchAlphaData({ commit, dispatch }, { query = {} }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getAlphaData(query);
      commit('setAlphaData', data);
      return [];
    } catch (e) {
      commit('setAlphaData', []);

      const caption = 'Failed to Load Alpha Data list';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExports({ commit, dispatch }, { query = {}, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getExports(query, cts);

      commit('setExports', data);
      commit('setExportsMeta', meta);
      return data;
    } catch (e) {
      commit('setExports', []);
      commit('setExportsMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Export List';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchExportById(_, id) {
    return ApiClient.v1.appraisal.getExportById(id);
  },
  async fetchExportStatus({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getPAStatus({ type: 'pa_export' });
      commit('setExportStatuses', data);
      return [];
    } catch (e) {
      commit('setExportStatuses', []);

      const caption = 'Failed to Load Export Status';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportEmployeesById({ commit, dispatch }, { id, query = '', cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.appraisal.getExportEmployeesById(id, query, cts);
      commit('setExportEmployees', data);
      commit('setExportEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setExportEmployees', []);
      commit('setExportEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.appraisal.getExportHistoryById(id);
      commit('setExportHistories', data);
      return data;
    } catch (e) {
      commit('setExportHistories', []);
      return [];
    }
  },
  async exportPA({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.exportPA(formData);
      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Export';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  getCycleActive(_, uuid) {
    return ApiClient.v1.appraisal.getCycleActive(uuid);
  },
  async getAvailablePeriod({ commit, dispatch }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getAvailablePeriod(uuid, id);
      commit('setAvailablePeriod', data);
      return data;
    } catch (e) {
      commit('setAvailablePeriod', []);

      const caption = 'Failed to Load Available Period';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async generateForm({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.appraisal.generateForm(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Generate';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async fetchTimeframes({ commit, dispatch }, { query = {} }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getTimeframes(query);
      commit('setTimeframes', data);
      return [];
    } catch (e) {
      commit('setTimeframes', []);

      const caption = 'Failed to Load Timeframe list';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchReviewMethods({ commit, dispatch }, { query = {} }) {
    try {
      const { data } = await ApiClient.v1.appraisal.getReviewMethods(query);

      commit('setReviewMethods', data);
      return data;
    } catch (e) {
      commit('setReviewMethods', []);

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async downloadPdf(_, id) {
    try {
      return await ApiClient.v1.appraisal.downloadPdf(id);
    } catch (e) {
      return null;
    }
  },
};

const mutations = {
  setTemplates(state, data) {
    state.templates = data;
  },
  setTemplatesMeta(state, data) {
    state.templatesMeta = data;
  },
  setTemplateStatuses(state, data) {
    state.templateStatuses = data;
  },
  setScores(state, data) {
    state.scores = data;
  },
  setScoresMeta(state, data) {
    state.scoresMeta = data;
  },
  setScoreStatuses(state, data) {
    state.scoreStatuses = data;
  },
  setCycles(state, data) {
    state.cycles = data;
  },
  setCyclesMeta(state, data) {
    state.cyclesMeta = data;
  },
  setCycleStatuses(state, data) {
    state.cycleStatuses = data;
  },
  setCycleReviews(state, data) {
    state.cycleReviews = data;
  },
  setCycleReviewsMeta(state, data) {
    state.cycleReviewsMeta = data;
  },
  setEmployeeReviewers(state, data) {
    state.employeeReviewers = data;
  },
  setEmployeeReviewersMeta(state, data) {
    state.employeeReviewersMeta = data;
  },
  setReviews(state, data) {
    state.reviews = data;
  },
  setReviewsMeta(state, data) {
    state.reviewsMeta = data;
  },
  setReviewStatuses(state, data) {
    state.reviewStatuses = data;
  },
  setReviewHistories(state, data) {
    state.reviewHistories = data;
  },
  setReviewCount(state, data) {
    state.reviewCount = data;
  },
  setIndisciplines(state, data) {
    state.indisciplines = data;
  },
  setIndisciplinesMeta(state, data) {
    state.indisciplinesMeta = data;
  },
  setIndisciplineStatuses(state, data) {
    state.indisciplineStatuses = data;
  },
  setRecommendations(state, data) {
    state.recommendations = data;
  },
  setRecommendationsMeta(state, data) {
    state.recommendationsMeta = data;
  },
  setRecommendationStatuses(state, data) {
    state.recommendationStatuses = data;
  },
  setExports(state, data) {
    state.exports = data;
  },
  setExportsMeta(state, data) {
    state.exportsMeta = data;
  },
  setExportStatuses(state, data) {
    state.exportStatuses = data;
  },
  setExportEmployees(state, data) {
    state.exportEmployees = data;
  },
  setExportEmployeesMeta(state, data) {
    state.exportEmployeesMeta = data;
  },
  setExportHistories(state, data) {
    state.exportHistories = data;
  },

  setAvailablePeriod(state, data) {
    state.activePeriods = data;
  },

  setAlphaData(state, data) {
    state.alphaData = data;
  },
  setTimeframes(state, data) {
    state.timeframes = data;
  },
  setReviewMethods(state, data) {
    state.reviewMethods = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
