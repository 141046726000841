import ApiClient from '~/api';

const initialState = {
  submissionTypes: [
    { id: 'reimbursement', name: 'Reimbursement' },
    { id: 'cash_advance', name: 'Cash Advance' },
  ],
  types: [],
  statuses: [],
  histories: [],
  requests: [],
  requestMeta: {},
  totalPending: 0,
  totalApproved: 0,
  totalCompleted: 0,
  settings: null,
  loadingApprovalLevel: false,
  approvalLevel: {},
  approvalLevels: [],
  approvalLevelsMeta: {},
};

const getters = {
  submissionTypes: state => state.submissionTypes,
  types: state => state.types,
  statuses: state => state.statuses,
  histories: state => state.histories,
  requests: state => state.requests,
  requestMeta: state => state.requestMeta,
  totalPending: state => state.totalPending,
  totalApproved: state => state.totalApproved,
  totalCompleted: state => state.totalCompleted,
  settings: state => state.settings,
  loadingApprovalLevel: state => state.loadingApprovalLevel,
  approvalLevel: state => state.approvalLevel,
  approvalLevels: state => state.approvalLevels,
  approvalLevelsMeta: state => state.approvalLevelsMeta,
};

const actionApprovalLevels = {
  async fetchApprovalLevels({ commit }, { query } = {}) {
    try {
      commit('setLoadingApprovalLevel', true);
      const res = await ApiClient.v1.expense.getExpenseApprovalLevels(query);

      if (res.data) {
        commit('setApprovalLevels', res.data);
        if (res.meta) commit('setApprovalLevelsMeta', res.meta);
        commit('setLoadingApprovalLevel', false);
        return res.data;
      }

      commit('setLoadingApprovalLevel', false);
      return [];
    } catch (e) {
      commit('setLoadingApprovalLevel', false);
      return [];
    }
  },

  async fetchApprovalLevel({ commit }, uuid) {
    try {
      const { data } = await ApiClient.v1.expense.getApprovalLevel(uuid);
      commit('setApprovalLevel', data);
      return data;
    } catch (e) {
      commit('setApprovalLevel', {});
      return {};
    }
  },
  async createApprovalLevel({ dispatch, commit }, formData) {
    try {
      const { data } = await ApiClient.v1.expense.createApprovalLevel(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create Approval Level';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateApprovalLevel({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.expense.updateApprovalLevel(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update Approval Level';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async deleteApprovalLevel({ dispatch }, { uuid }) {
    try {
      const { data } = await ApiClient.v1.expense.deleteApprovalLevel(uuid);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete Approval Level';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actions = {
  ...actionApprovalLevels,
  async fetchTypeList({ commit }, { query, customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.expense.getExpenseType(query, customCompanyId);
      commit('setTypes', data);
      return [];
    } catch (e) {
      commit('setTypes', []);
      return [];
    }
  },
  async fetchAvailableTypeList({ commit }) {
    try {
      const { data } = await ApiClient.v1.expense.getAvailableExpenseType();
      commit('setTypes', data);
      return [];
    } catch (e) {
      commit('setTypes', []);
      return [];
    }
  },
  addType(_, formData) {
    return ApiClient.v1.expense.postExpenseType(formData);
  },
  updateType(_, { id, formData }) {
    return ApiClient.v1.expense.putExpenseType(id, formData);
  },
  deleteType(_, id) {
    return ApiClient.v1.expense.deleteExpenseType(id);
  },
  addTypeItem({ commit }, formData) {
    commit('addTypeItem', formData);
  },
  updateTypeItem({ commit }, { idx, formData }) {
    commit('updateTypeItem', { idx, formData });
  },
  deleteTypeItem({ commit }, { idx }) {
    commit('deleteTypeItem', idx);
  },
  async fetchStatusList({ commit }, formData) {
    try {
      const { data } = await ApiClient.v1.expense.getExpenseStatus(formData);
      commit('setStatuses', data);
      return [];
    } catch (e) {
      commit('setStatuses', []);
      return [];
    }
  },
  async fetchRequest({ commit }, { personal, query }) {
    try {
      commit('setRequests', []);
      commit('setRequestMeta', {});

      let res = null;

      if (personal) res = await ApiClient.v1.expense.getMyExpenseRequest(query);
      else res = await ApiClient.v1.expense.getExpenseRequest(query);

      if (res.data && res.meta) {
        commit('setRequests', res.data);
        commit('setRequestMeta', res.meta);
      }
      return [];
    } catch (e) {
      commit('setRequests', []);
      commit('setRequestMeta', {});

      throw e;
    }
  },
  fetchRequestById(_, id) {
    return ApiClient.v1.expense.getExpenseRequestById(id);
  },
  async fetchRequestCount({ commit }, uuid = null) {
    try {
      const { data: data0 } = await ApiClient.v1.expense.getExpenseRequestCount({
        expense_status_id_by_setting: 'expense_requests_pending_status',
        employee_id: uuid,
      });
      commit('setTotalPending', data0);

      const { data: data1 } = await ApiClient.v1.expense.getExpenseRequestCount({
        expense_status_id_by_setting: 'expense_requests_approved_status',
        employee_id: uuid,
      });
      commit('setTotalApproved', data1);

      const { data: data2 } = await ApiClient.v1.expense.getExpenseRequestCount({
        expense_status_id_by_setting: 'expense_requests_completed_status',
        employee_id: uuid,
      });
      commit('setTotalCompleted', data2);

      return [];
    } catch (e) {
      commit('setTotalPending', 0);
      commit('setTotalApproved', 0);
      commit('setTotalCompleted', 0);
      return [];
    }
  },
  fetchChart(_, query) {
    return ApiClient.v1.expense.getExpenseChart(query);
  },
  async fetchHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.expense.getExpenseRequestHistoryById(id);
      commit('setHistories', data);
      return data;
    } catch (e) {
      commit('setHistories', []);
      return [];
    }
  },
  createRequest(_, { formData }) {
    return ApiClient.v1.expense.createExpenseRequest({ ...formData, employee_id: this.$auth.user.uuid });
  },
  updateRequest(_, { id, formData }) {
    return ApiClient.v1.expense.updateExpenseRequest(id, { ...formData, employee_id: this.$auth.user.uuid });
  },
  updateRequestToPaid(_, { id, formData }) {
    return ApiClient.v1.expense.updateRequestToPaid(id, { ...formData, employee_id: this.$auth.user.uuid });
  },
  updateStatus(_, { id, formData }) {
    return ApiClient.v1.expense.setExpenseRequestStatus(id, { ...formData });
  },
  updateReceiptForm(_, { id, formData }) {
    return ApiClient.v1.expense.updateReceiptForm(id, { ...formData, employee_id: this.$auth.user.uuid });
  },
  async fetchSetting({ commit, state }, { key }) {
    try {
      let { settings } = state;
      const lsKey = 'expense_settings';
      if (sessionStorage.getItem(lsKey)) {
        try {
          settings = JSON.parse(sessionStorage.getItem(lsKey));
        } catch (e) {
          sessionStorage.removeItem(lsKey);
        }
      } else {
        const { data } = await ApiClient.v1.expense.getAllSettings();
        const initialValue = {};
        settings = data.reduce((obj, item) => {
          return {
            ...obj,
            [item.key]: item.value,
          };
        }, initialValue);

        sessionStorage.setItem(lsKey, JSON.stringify(settings));
      }

      commit('setSettings', settings);

      if (key) {
        if (key === 'all') return settings;

        let newKey = '';
        if (key === 'status_default') {
          newKey = 'expense_requests_default_status';
        } else if (key === 'status_approved') {
          newKey = 'expense_requests_approved_status';
        } else if (key === 'status_canceled') {
          newKey = 'expense_requests_canceled_status';
        } else if (key === 'status_completed') {
          newKey = 'expense_requests_completed_status';
        } else if (key === 'status_paid') {
          newKey = 'expense_requests_paid_status';
        } else if (key === 'status_pending') {
          newKey = 'expense_requests_pending_status';
        } else if (key === 'status_drafted') {
          newKey = 'expense_requests_drafted_status';
        }

        return settings[newKey];
      }

      return null;
    } catch (e) {
      return null;
    }
  },
  async getMappingTransferExpense({ commit }, { id, period }) {
    return ApiClient.v1.expense.getMappingTransferExpense(id, period);
  },
};

const mutations = {
  setTypes(state, value) {
    state.types = value;
  },
  addTypeItem(state, formData) {
    // state.types.push(formData); // Add to the end of the list
    state.types.splice(0, 0, formData); // Add to the beginning of the list
  },
  updateTypeItem(state, { idx, formData }) {
    state.types.splice(idx, 1, formData);
  },
  deleteTypeItem(state, idx) {
    state.types.splice(idx, 1);
  },
  setHistories(state, value) {
    state.histories = value;
  },
  setStatuses(state, value) {
    state.statuses = value;
  },
  setRequests(state, value) {
    state.requests = value;
  },
  setRequestMeta(state, value) {
    state.requestMeta = value;
  },
  setTotalPending(state, value) {
    state.totalPending = value;
  },
  setTotalApproved(state, value) {
    state.totalApproved = value;
  },
  setTotalCompleted(state, value) {
    state.totalCompleted = value;
  },
  setSettings(state, value) {
    state.settings = value;
  },
  setLoadingApprovalLevel(state, value) {
    state.loadingApprovalLevel = value;
  },
  setApprovalLevel(state, data) {
    state.approvalLevel = data;
  },
  updateApprovalLevel(state, data) {
    state.approvalLevel = data;
  },
  setApprovalLevels(state, data) {
    state.approvalLevels = data;
  },
  setApprovalLevelsMeta(state, data) {
    state.approvalLevelsMeta = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
