const generateParams = obj => {
  if (!obj) return '';
  const str = [];
  Object.keys(obj).forEach(p => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  });
  const params = str.join('&');
  return params !== '' ? `?${params}` : '';
};

const getConfig = (path, customOptions, withParams = false) => {
  const config = {
    options: null,
    endpoint: null,
    headers: null,
  };
  config.options = {
    requestHeaders: {},
    requestPayload: {},
    requestParams: {},
    ...customOptions,
  };
  config.headers = new Headers();
  Object.keys(config.options.requestHeaders).forEach(key => {
    config.headers.append(key, config.options.requestHeaders[key]);
  });
  config.endpoint = path;
  if (withParams) {
    config.endpoint += generateParams(config.options.requestParams);
  }
  return config;
};

const createFetch = (method, path, customOptions) => {
  const isGetMethod = method === 'GET';
  const { endpoint, headers, options } = getConfig(path, customOptions, isGetMethod);
  const fetchOptions = {
    method,
    mode: 'cors',
    headers,
  };
  if (!isGetMethod) {
    fetchOptions.body = JSON.stringify(options.requestPayload);
    fetchOptions.headers.append('Content-Type', 'application/json');
  }
  return fetch(endpoint, fetchOptions).then(response => response.json());
};

export { generateParams, getConfig, createFetch };
