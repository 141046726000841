import CryptoJS from 'crypto-js';

const secretKey = process.env.SECRET_ENCRYPTION;

const CryptoJSAesJson = {
  stringify(cipherParams) {
    const j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  parse(jsonStr) {
    const j = JSON.parse(jsonStr);
    const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  },
};

export function encryptText(text) {
  return CryptoJS.AES.encrypt(JSON.stringify(text), secretKey, { format: CryptoJSAesJson }).toString();
}

export function decryptText(text) {
  return JSON.parse(CryptoJS.AES.decrypt(JSON.stringify(text), secretKey, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8));
}

export default {
  encryptText,
  decryptText,
};
