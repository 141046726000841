import { mapGetters } from 'vuex';
import { checkAccess } from '~/helpers/permissions';
import { whitelistedPages } from '~/helpers/role-helper';

const permissionMixin = {
  filters: { checkAccess },
  methods: {
    async check(access) {
      if (whitelistedPages.includes(access)) return true;
      const p = await this.$store.dispatch('permission/getPermissions');
      const r = await this.$store.dispatch('permission/getRoutes');
      const roles = await this.$store.dispatch('permission/getUserRoles');
      const ret = await checkAccess(access, p, r, roles);
      return ret;
    },
  },
};

export default permissionMixin;
