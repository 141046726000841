//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { randomId } from '@/helpers/string';

export default {
  name: 'CInputPassword',
  props: {
    toggle: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Enter your Password',
    },
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
  computed: {
    fieldId() {
      return this.id !== null ? this.id : randomId('txt');
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onChange(e) {
      this.$emit('input', e.target.value);
    },
  },
};
