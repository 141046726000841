import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_schedule_index' */ '~/pages/admin/schedule/Index');

const EmployeeIndex = () => import(/* webpackChunkName: 'admin_schedule_employee_index' */ '~/pages/admin/schedule/employee/Index');
const EmployeeList = () => import(/* webpackChunkName: 'admin_schedule_employee_list' */ '~/pages/admin/schedule/employee/List');
const EmployeeCreate = () => import(/* webpackChunkName: 'admin_schedule_employee_new' */ '~/pages/admin/schedule/employee/Create');
const EmployeeUpdate = () => import(/* webpackChunkName: 'admin_schedule_employee_update' */ '~/pages/admin/schedule/employee/Update');

const WorkHourIndex = () => import(/* webpackChunkName: 'admin_schedule_work_hour_index' */ '~/pages/admin/schedule/work-hour/Index');
const WorkHourList = () => import(/* webpackChunkName: 'admin_schedule_work_hour_list' */ '~/pages/admin/schedule/work-hour/List');
const WorkHourCreate = () => import(/* webpackChunkName: 'admin_schedule_work_hour_new' */ '~/pages/admin/schedule/work-hour/Create');
const WorkHourUpdate = () => import(/* webpackChunkName: 'admin_schedule_work_hour_update' */ '~/pages/admin/schedule/work-hour/Update');

const DepartmentShiftIndex = () => import(/* webpackChunkName: 'admin_schedule_department_shift_index' */ '~/pages/admin/schedule/department-shift/Index');
const DepartmentShiftList = () => import(/* webpackChunkName: 'admin_schedule_department_shift_list' */ '~/pages/admin/schedule/department-shift/List');
const DepartmentShiftCreate = () => import(/* webpackChunkName: 'admin_schedule_department_shift_new' */ '~/pages/admin/schedule/department-shift/Create');
const DepartmentShiftManage = () => import(/* webpackChunkName: 'admin_schedule_department_shift_manage' */ '~/pages/admin/schedule/department-shift/Manage');

const FixedScheduleTypeIndex = () => import(/* webpackChunkName: 'admin_schedule_fixed_schedule_type_index' */ '~/pages/admin/schedule/fixed-schedule-type/Index');
const FixedScheduleTypeList = () => import(/* webpackChunkName: 'admin_schedule_fixed_schedule_type_list' */ '~/pages/admin/schedule/fixed-schedule-type/List');
const FixedScheduleTypeCreate = () => import(/* webpackChunkName: 'admin_schedule_fixed_schedule_type_new' */ '~/pages/admin/schedule/fixed-schedule-type/Create');
const FixedScheduleTypeUpdate = () => import(/* webpackChunkName: 'admin_schedule_fixed_schedule_type_update' */ '~/pages/admin/schedule/fixed-schedule-type/Update');

export default {
  path: 'schedules',
  component: r(Index),
  children: [
    { path: '', redirect: '/admin/schedules/employees', name: 'admin_schedule_index' },
    {
      path: 'employees',
      component: r(EmployeeIndex),
      children: [
        { path: '', component: r(EmployeeList), name: 'admin_schedule_employee_list' },
        { path: 'new', component: r(EmployeeCreate), name: 'admin_schedule_employee_new' },
        { path: ':id(\\d+)', component: r(EmployeeUpdate), name: 'admin_schedule_employee_update' },
      ],
    },
    {
      path: 'work-hours',
      component: r(WorkHourIndex),
      children: [
        { path: '', component: r(WorkHourList), name: 'admin_schedule_work_hour_list' },
        { path: 'new', component: r(WorkHourCreate), name: 'admin_schedule_work_hour_new' },
        { path: ':id(\\d+)', component: r(WorkHourUpdate), name: 'admin_schedule_work_hour_update' },
      ],
    },
    {
      path: 'department-shifts',
      component: r(DepartmentShiftIndex),
      children: [
        { path: '', component: r(DepartmentShiftList), name: 'admin_schedule_department_shift_list' },
        { path: 'department/:department/year/:year/month/:month/date/:date/new', component: r(DepartmentShiftCreate), name: 'admin_schedule_department_shift_new' },
        { path: 'department/:department/year/:year/month/:month/date/:date/:id', component: r(DepartmentShiftManage), name: 'admin_schedule_department_shift_manage' },
      ],
    },
    {
      path: 'fixed-types',
      component: r(FixedScheduleTypeIndex),
      children: [
        { path: '', component: r(FixedScheduleTypeList), name: 'admin_schedule_fixed_schedule_type_list' },
        { path: 'new', component: r(FixedScheduleTypeCreate), name: 'admin_schedule_fixed_schedule_type_new' },
        { path: ':id(\\d+)', component: r(FixedScheduleTypeUpdate), name: 'admin_schedule_fixed_schedule_type_update' },
      ],
    },
  ],
};
