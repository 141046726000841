import r from '~/utils/render-utils';

const ApprovalLevelIndex = () => import(/* webpackChunkName: 'admin_expense_approval_level' */ '~/pages/admin/expense/ApprovalLevelIndex');
const ApprovalLevelList = () => import(/* webpackChunkName: 'admin_expense_approval_level_list' */ '~/pages/admin/expense/ApprovalLevelList');
const ApprovalLevelCreate = () => import(/* webpackChunkName: 'admin_expense_approval_level_create' */ '~/pages/admin/expense/ApprovalLevelCreate');
const ApprovalLevelDetail = () => import(/* webpackChunkName: 'admin_expense_approval_level_detail' */ '~/pages/admin/expense/ApprovalLevelDetail');
const ApprovalLevelEdit = () => import(/* webpackChunkName: 'admin_expense_approval_level_edit' */ '~/pages/admin/expense/ApprovalLevelEdit');

const PageAdminExpenseIndex = () => import(/* webpackChunkName: 'admin_expense_index' */ '~/pages/admin/expense/PageAdminExpenseIndex');
const PageAdminExpenseHistory = () => import(/* webpackChunkName: 'admin_expense_history' */ '~/pages/admin/expense/PageAdminExpenseHistory');
const PageAdminExpenseDetail = () => import(/* webpackChunkName: 'admin_expense_history' */ '~/pages/admin/expense/PageAdminExpenseDetail');
const PageAdminExpenseTypeIndex = () => import(/* webpackChunkName: 'admin_expense_type_index' */ '~/pages/admin/expense/PageAdminExpenseTypeIndex');
const PageAdminExpenseTypeHistory = () =>
  import(/* webpackChunkName: 'admin_expense_type_history' */ '~/pages/admin/expense/PageAdminExpenseTypeHistory');

export default {
  path: 'expense',
  component: r(PageAdminExpenseIndex),
  children: [
    { path: '', redirect: '/admin/expense/manage', name: 'admin_expense_index' },
    {
      path: 'manage',
      component: r(PageAdminExpenseIndex),
      children: [
        { path: '', component: r(PageAdminExpenseHistory), name: 'admin_expense_history' },
        { path: 'detail/:id(\\d+)', component: r(PageAdminExpenseDetail), name: 'admin_expense_detail' },
      ],
    },
    {
      path: 'type',
      component: r(PageAdminExpenseTypeIndex),
      children: [{ path: '', component: r(PageAdminExpenseTypeHistory), name: 'admin_expense_type' }],
    },
    {
      path: 'approval-level',
      component: r(ApprovalLevelIndex),
      children: [
        { path: '', component: r(ApprovalLevelList), name: 'admin_expense_approval_level_list' },
        { path: 'new', component: r(ApprovalLevelCreate), name: 'admin_expense_approval_level_create' },
        { path: ':id/edit', component: r(ApprovalLevelEdit), name: 'admin_expense_approval_level_edit' },
        { path: ':id', component: r(ApprovalLevelDetail), name: 'admin_expense_approval_level_detail' },
      ],
    },
  ],
};
