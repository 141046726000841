import axios from 'axios';
import ApiClient from '~/api';

const initialState = {
  requests: [],
  requestsMeta: {},
  requestStatuses: [],
  requestDetails: [],
  requestHistories: [],

  offices: [],
  devices: [],
  deviceUsages: [],
  workArrangements: [],
  employmentStatus: [],
};

const getters = {
  requests: state => state.requests,
  requestsMeta: state => state.requestsMeta,
  requestStatuses: state => state.requestStatuses,
  requestDetails: state => state.requestDetails,
  requestHistories: state => state.requestHistories,

  offices: state => state.offices,
  devices: state => state.devices,
  deviceUsages: state => state.deviceUsages,
  workArrangements: state => state.workArrangements,
  employmentStatus: state => state.employmentStatus,
};

const actions = {
  async fetchRequests({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.recruitment.getRequests(query, cts);

      commit('setRequests', data);
      commit('setRequestsMeta', meta);
      return data;
    } catch (e) {
      commit('setRequests', []);
      commit('setRequestsMeta', {});

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchRequestStatusList({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.recruitment.getRequestStatus(query);
      commit('setRequestStatuses', data);
      return data;
    } catch (e) {
      commit('setRequestStatuses', []);
      return [];
    }
  },
  async fetchWorkArrangement({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.recruitment.getWorkArrangement(query);
      commit('setWorkArrangement', data);
      return [];
    } catch (e) {
      commit('setWorkArrangement', []);
      return [];
    }
  },
  async fetchEmploymentStatus({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.recruitment.getEmploymentStatus(query);
      commit('setEmploymentStatus', data);
      return data;
    } catch (e) {
      commit('setEmploymentStatus', []);
      return [];
    }
  },
  async fetchOfficeLocation({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.recruitment.getOfficeLocation(query);
      commit('setOfficeLocation', data);
      return data;
    } catch (e) {
      commit('setOfficeLocation', []);
      return [];
    }
  },
  async fetchDevices({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.recruitment.getDevice(query);
      commit('setDevice', data);
      return data;
    } catch (e) {
      commit('setDevice', []);
      return [];
    }
  },
  async fetchDeviceUsage({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.recruitment.getDeviceUsage(query);
      commit('setDeviceUsage', data);
      return data;
    } catch (e) {
      commit('setDeviceUsage', []);
      return [];
    }
  },
  fetchRequestById(_, id) {
    return ApiClient.v1.recruitment.getRequestById(id);
  },
  async fetchRequestHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.recruitment.getRequestHistoryById(id);
      commit('setRequestHistories', data);
      return data;
    } catch (e) {
      commit('setRequestHistories', []);
      return [];
    }
  },
  updateStatus(_, { id, formData }) {
    return ApiClient.v1.recruitment.setRequestStatus(id, { ...formData });
  },
  async createEmployeeRequest({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.recruitment.createEmployeeRequest(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to create employee request';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeRequest({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.recruitment.updateEmployeeRequest(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to update employee request';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const mutations = {
  setRequests(state, data) {
    state.requests = data;
  },
  setRequestStatuses(state, value) {
    state.requestStatuses = value;
  },
  setRequestsMeta(state, data) {
    state.requestsMeta = data;
  },
  setWorkArrangement(state, value) {
    state.workArrangements = value;
  },
  setEmploymentStatus(state, data) {
    state.employmentStatus = data;
  },
  setOfficeLocation(state, value) {
    state.offices = value;
  },
  setDevice(state, value) {
    state.devices = value;
  },
  setDeviceUsage(state, value) {
    state.deviceUsages = value;
  },
  setRequestHistories(state, data) {
    state.requestHistories = data;
  },
  setRequestEmployees(state, data) {
    state.requestEmployees = data;
  },
  setRequestEmployeesMeta(state, data) {
    state.requestEmployeesMeta = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
