//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CSidebarLink',
  props: {
    link: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cssClass: {
      type: String,
      default: 'nav-link',
    },
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  computed: {
    isParentInFocus() {
      // console.info(this.$route);
      if (this.link.highlighted === true) return true;

      // If the active path has the same exact path as the current item
      if (this.$route.name === this.link.path) return true;

      // If current item has children
      if (this.link.children) return this.isMatch(this.link, true);

      // If the active path includes the item's path
      return this.$route.name.indexOf(this.link.path) === 0;
    },
    iconClassNames() {
      return [
        // 'o-icon',
        // 'o-icon--line-color',
        // 'o-icon--active--solid',
        // `o-icon--${this.link.icon.variant}`,
        // `o-icon--active--${this.link.icon.variant}`,
      ];
    },
    isToggleable() {
      return this.link.children && this.link.children.length >= 1;
    },
  },
  mounted() {
    this.isCollapsed = !this.isParentInFocus;
  },
  methods: {
    isMatch(record, isParent = false) {
      let paths = [record.path, ...record.alias];
      if (isParent) {
        paths = record.children.map(child => [child.path, ...child.alias]).flat(2);
      }

      const foundPaths = paths.filter(path => {
        if (this.$route.name.indexOf(path) === 0) return true;
        return false;
      });

      return foundPaths.length >= 1;
    },
    childClass(record) {
      return ['u-cursor--pointer', 'nav-link', { active: this.isMatch(record) }];
    },
    onClick() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
