import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_position_index' */ '~/pages/admin/position/Index');
const List = () => import(/* webpackChunkName: 'admin_position_list' */ '~/pages/admin/position/List');
const Detail = () => import(/* webpackChunkName: 'admin_position_detail' */ '~/pages/admin/position/Detail');
const DetailEmployee = () => import(/* webpackChunkName: 'admin_position_employee' */ '~/pages/admin/position/DetailEmployee');

const routes = {
  path: 'employee/position',
  component: r(Index),
  children: [
    {
      path: '',
      component: r(List),
      name: 'admin_position_list',
    },
    {
      path: ':id(\\d+)',
      component: r(Detail),
      children: [
        {
          path: '',
          redirect: '/admin/position/:id/employees',
          name: 'admin_position_view',
        },
        {
          path: 'employees',
          name: 'admin_position_view_employee',
          component: r(DetailEmployee),
        },
      ],
    },
  ],
};

// old routes
const redirect = {
  path: 'position',
  children: [
    { path: '', name: 'admin_position_list_old', redirect: { name: 'admin_position_list' } },
    {
      path: ':id(\\d+)',
      children: [
        { path: '', name: 'admin_position_view_old', redirect: { name: 'admin_position_view' } },
        { path: 'employees', name: 'admin_position_view_employee_old', redirect: { name: 'admin_position_view_employee' } },
      ],
    },
  ],
};

export default [
  routes,
  redirect,
];
