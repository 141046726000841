const initialState = {
  theme: 'light',
};

const getters = {
  theme: state => state.theme,
};

const actions = {
  async setTheme({ commit }, data) {
    commit('setTheme', data);
  },
};

const mutations = {
  setTheme(state, value) {
    state.theme = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
