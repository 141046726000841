import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  getBusinessTripStatus() {
    return this.get(`/api/v1/business/status`, 'user');
  },
  getBusinessTripRequest(queries) {
    return this.get(`/api/v1/business/trips${objectToQueryString(queries)}`, 'user');
  },
  getBusinessTripRequestById(id) {
    return this.get(`/api/v1/business/trips/${id}`, 'user');
  },
  getBusinessTripRequestHistoryById(id) {
    return this.get(`/api/v1/business/logs/${id}`, 'user');
  },
  createBusinessTripRequest(formData) {
    return this.post(`/api/v1/business/trips`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },
  updateBusinessTripRequest(id, formData) {
    return this.post(`/api/v1/business/trips/${id}/update`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },
  setBusinessTripRequestStatus(id, formData) {
    return this.patch(`/api/v1/business/trips/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getBusinessTripTransport(queries) {
    return this.get(`/api/v1/transportations${objectToQueryString(queries)}`, 'user');
  },
  getBusinessTripRequestCount() {
    return this.get(`/api/v1/business/count`, 'user');
  },
  getSettings() {
    return this.get(`/api/v1/business/settings`, 'user');
  },
  saveSettings(formData) {
    return this.patch(`/api/v1/business/settings`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
};
