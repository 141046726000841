import ApiClient from '~/api';

const initialState = {
  schedules: [],
  workHours: [],
  workHoursMeta: {},
  activeWorkHour: null,
  fixedTypes: [],
  fixedTypesMeta: {},
  activeFixedType: null,
  employees: [],
  employeesMeta: {},
  activeEmployee: null,
  departmentShift: {},
  activeDepartmentShift: {
    person: [],
  },
  upcomingEvents: [],
};

const getters = {
  schedules: state => state.schedules,
  workHours: state => state.workHours,
  workHoursMeta: state => state.workHoursMeta,
  activeWorkHour: state => state.activeWorkHour,
  fixedTypes: state => state.fixedTypes,
  fixedTypesMeta: state => state.fixedTypesMeta,
  activeFixedType: state => state.activeFixedType,
  employees: state => state.employees,
  employeesMeta: state => state.employeesMeta,
  activeEmployee: state => state.activeEmployee,
  departmentShift: state => state.departmentShift,
  activeDepartmentShift: state => state.activeDepartmentShift,
  upcomingEvents: state => state.upcomingEvents,
};

const actions = {
  async fetchCalendar({ commit, dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.schedule.getCalendar(formData);

      commit('setSchedule', data);

      return data;
    } catch (e) {
      commit('setSchedule', []);

      const caption = 'Failed to Load Employee Calendar';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  fetchTypes(_, formData) {
    return ApiClient.v1.schedule.getTypes(formData);
  },
  fetchSubTypes(_, formData) {
    return ApiClient.v1.schedule.getSubTypes(formData);
  },
  fetchTimeShift(_, formData) {
    return ApiClient.v1.schedule.getEmployeeTimeShift(formData);
  },
  fetchUpcomingHolidays(_, formData) {
    return ApiClient.v1.schedule.getUpcomingHolidays(formData);
  },
  fetchUpcomingEvents(_, formData) {
    return ApiClient.v1.schedule.getUpcomingEvents(formData);
  },

  // Work Hour
  async fetchLastWorkHour({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.schedule.getLastWorkHour();

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchWorkHours({ commit }, { query }) {
    try {
      const { data, meta } = await ApiClient.v1.schedule.getWorkHours(query);
      commit('setWorkHours', data);
      commit('setWorkHoursMeta', meta);

      return data;
    } catch (e) {
      commit('setWorkHours', []);
      commit('setWorkHoursMeta', {});

      return [];
    }
  },
  async fetchWorkHour({ commit }, { id }) {
    try {
      const { data } = await ApiClient.v1.schedule.getWorkHour(id);
      // const { data } = { data: { id: 1,days: [] } };
      commit('setActiveWorkHour', data);
    } catch (e) {
      commit('setActiveWorkHour', null);
    }
  },
  async createWorkHour({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.createWorkHour(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateWorkHour({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.updateWorkHour(id, formData);
      // commit('updateWorkHour', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteWorkHour({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.schedule.deleteWorkHour(id);
      commit('deleteWorkHour', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Fixed Types
  async fetchLastFixedType({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.schedule.getLastFixedType();

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchFixedTypes({ commit }, { query, customCompanyId } = {}) {
    try {
      const { data, meta } = await ApiClient.v1.schedule.getFixedTypes(query, customCompanyId);
      commit('setFixedTypes', data);
      commit('setFixedTypesMeta', meta);

      return data;
    } catch (e) {
      commit('setFixedTypes', []);
      commit('setFixedTypesMeta', {});

      return [];
    }
  },
  async fetchFixedType({ commit }, { id }) {
    try {
      const { data } = await ApiClient.v1.schedule.getFixedType(id);
      // const { data } = { data: { id: 1,days: [] } };
      commit('setActiveFixedType', data);
    } catch (e) {
      commit('setActiveFixedType', null);
    }
  },
  async createFixedType({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.createFixedType(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateFixedType({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.updateFixedType(id, formData);
      // commit('updateFixedType', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteFixedType({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.schedule.deleteFixedType(id);
      commit('deleteFixedType', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Employees
  async fetchLastEmployee({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.schedule.getLastEmployee();

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployees({ commit }, { query }) {
    try {
      const { data, meta } = await ApiClient.v1.schedule.getEmployees(query);
      commit('setEmployees', data);
      commit('setEmployeesMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployees', []);
      commit('setEmployeesMeta', {});

      return [];
    }
  },
  async fetchEmployee({ commit }, { id }) {
    try {
      const { data } = await ApiClient.v1.schedule.getEmployee(id);
      // const { data } = { data: { id: 1,days: [] } };
      commit('setActiveEmployee', data);
    } catch (e) {
      commit('setActiveEmployee', null);
    }
  },
  async createEmployee({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.createEmployee(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployee({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.updateEmployee(id, formData);
      // commit('updateEmployee', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployee({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.schedule.deleteEmployee(id);
      commit('deleteEmployee', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Department Shift
  async fetchDepartmentShifts({ commit }, { query, cts = null }) {
    try {
      const { data } = await ApiClient.v1.schedule.getDepartmentShifts(query, cts);
      commit('setDepartmentShift', data);

      return data;
    } catch (e) {
      commit('setDepartmentShift', {});

      return [];
    }
  },
  async fetchDepartmentShift({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.schedule.getDepartmentShift(query);
      commit('setActiveDepartmentShift', data);
    } catch (e) {
      commit('setActiveDepartmentShift', { person: [] });
    }
  },
  async createUpdateDepartmentShift({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.createUpdateDepartmentShift(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateDepartmentShift({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.schedule.updateDepartmentShift(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteDepartmentShift({ dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.schedule.deleteDepartmentShift(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteDepartmentShiftEmployee({ state, commit, dispatch }, { formData, idx }) {
    try {
      const { data } = await ApiClient.v1.schedule.deleteDepartmentShiftEmployee(formData);
      commit('deleteEmployeeDepartmentShift', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const mutations = {
  // WorkHours
  setWorkHours(state, data) {
    state.workHours = data;
  },
  setWorkHoursMeta(state, data) {
    state.workHoursMeta = data;
  },
  setActiveWorkHour(state, value) {
    state.activeWorkHour = value;
  },
  setWorkHour(state, data) {
    state.workHours.splice(0, 0, data); // Add to the beginning of the list
  },
  updateWorkHour(state, { idx, data }) {
    state.workHours.splice(idx, 1, data);
  },
  deleteWorkHour(state, idx) {
    state.workHours.splice(idx, 1);
  },

  // Fixed Types
  setFixedTypes(state, data) {
    state.fixedTypes = data;
  },
  setFixedTypesMeta(state, data) {
    state.fixedTypesMeta = data;
  },
  setActiveFixedType(state, value) {
    state.activeFixedType = value;
  },
  setFixedType(state, data) {
    state.fixedTypes.splice(0, 0, data); // Add to the beginning of the list
  },
  updateFixedType(state, { idx, data }) {
    state.fixedTypes.splice(idx, 1, data);
  },
  deleteFixedType(state, idx) {
    state.fixedTypes.splice(idx, 1);
  },

  // Employees
  setEmployees(state, data) {
    state.employees = data;
  },
  setEmployeesMeta(state, data) {
    state.employeesMeta = data;
  },
  setActiveEmployee(state, value) {
    state.activeEmployee = value;
  },
  setEmployee(state, data) {
    state.employees.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEmployee(state, { idx, data }) {
    state.employees.splice(idx, 1, data);
  },
  deleteEmployee(state, idx) {
    state.employees.splice(idx, 1);
  },

  // Department Shift
  setActiveDepartmentShift(state, value) {
    state.activeDepartmentShift = value;
  },
  setDepartmentShift(state, value) {
    state.departmentShift = value;
  },
  deleteEmployeeDepartmentShift(state, idx) {
    let count = 0;
    idx.forEach(element => {
      state.activeDepartmentShift.person.splice(element - count, 1);
      count += 1;
    });
  },

  // Schedule
  setSchedule(state, data) {
    state.schedules = data;
  },

  setUpcomingEvents(state, value) {
    state.upcomingEvents = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
