import ApiOptions from '~/api/api-options';
import { objectToQueryString } from '~/helpers/url-helper';

export default {
  getCompanySettings() {
    return this.get('/api/v1/company-setting', 'user');
  },
  getActiveLocale() {
    return this.get(`/api/v1/company-setting/locale`, 'user');
  },
  getLoginData() {
    return this.get(`/api/v1/company-setting/login-data`, 'user');
  },

  // Manage Company
  getCompanies(queries, cts = null) {
    return this.get(`/api/v1/company-settings${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getCompanyByEmployee(uuid, queries, cts = null) {
    return this.get(`/api/v1/company/${uuid}${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getCompanyById(id) {
    return this.get(`/api/v1/company-settings/${id}`, 'user');
  },
  createCompany(formData) {
    return this.post(`/api/v1/company-settings`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },
  updateCompany(id, formData) {
    return this.post(`/api/v1/company-settings/${id}/update`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },
  updateCompanyStatus(id, formData) {
    return this.patch(`/api/v1/company-settings/${id}/status`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
};
