import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  // Departments
  getDepartments(queries) {
    const allowed = ['search', 'sort_by', 'sort_direction', 'page', 'limit', 'noLimit'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/departments${filteredQs}`, 'user');
  },
  getDepartment(id) {
    return this.get(`/api/v1/departments/${id}`, 'user');
  },
  createDepartment(formData) {
    return this.post(`/api/v1/departments`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateDepartment(id, formData) {
    return this.put(`/api/v1/departments/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deleteDepartment(id) {
    return this.delete(`/api/v1/departments/${id}`, 'user');
  },

  // Categories
  getCategories(queries) {
    const allowed = ['search', 'sort_by', 'sort_direction', 'page', 'limit'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/categories${filteredQs}`, 'user');
  },
  getCategory(id) {
    return this.get(`/api/v1/categories/${id}`, 'user');
  },
  createCategory(formData) {
    return this.post(`/api/v1/categories`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateCategory(id, formData) {
    return this.put(`/api/v1/categories/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deleteCategory(id) {
    return this.delete(`/api/v1/categories/${id}`, 'user');
  },

  // Positions
  getPositions(queries, customCompanyId = null) {
    const allowed = ['search', 'sort_by', 'sort_direction', 'page', 'limit', 'noLimit', 'department', 'category'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/positions${filteredQs}`, 'user', new ApiOptions({ customCompanyId }));
  },
  getPosition(id) {
    return this.get(`/api/v1/positions/${id}`, 'user');
  },
  getPositionSalaryRange(id) {
    return this.get(`/api/v1/positions/${id}/salary-range`, 'user');
  },
  createPosition(formData) {
    return this.post(`/api/v1/positions`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updatePosition(id, formData) {
    return this.put(`/api/v1/positions/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deletePosition(id) {
    return this.delete(`/api/v1/positions/${id}`, 'user');
  },

  // Employment Types
  getEmploymentTypes(queries = {}, customCompanyId = null) {
    const allowed = ['search', 'sort_by', 'sort_direction', 'page', 'limit', 'noLimit'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/employments/types${filteredQs}`, 'user', new ApiOptions({ customCompanyId }));
  },
  getEmploymentType(id) {
    return this.get(`/api/v1/employments/typess/${id}`, 'user');
  },
  createEmploymentType(formData) {
    return this.post(`/api/v1/employments/types`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateEmploymentType(id, formData) {
    return this.put(`/api/v1/employments/types/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deleteEmploymentType(id) {
    return this.delete(`/api/v1/employments/types/${id}`, 'user');
  },

  // Employment Periods
  getEmploymentPeriods(queries = {}, customCompanyId = null) {
    const allowed = ['search', 'sort_by', 'sort_direction', 'page', 'limit', 'noLimit'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/employments/periods${filteredQs}`, 'user', new ApiOptions({ customCompanyId }));
  },
  getEmploymentPeriod(id) {
    return this.get(`/api/v1/employments/periods/${id}`, 'user');
  },
  createEmploymentPeriod(formData) {
    return this.post(`/api/v1/employments/periods`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateEmploymentPeriod(id, formData) {
    return this.put(`/api/v1/employments/periods/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  deleteEmploymentPeriod(id) {
    return this.delete(`/api/v1/employments/periods/${id}`, 'user');
  },

  // Department's Employees
  getDepartmentsEmployees(id, queries = {}, cts = null) {
    return this.get(`/api/v1/departments/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getMultiDepartmentsEmployees(queries = {}, cts = null) {
    return this.get(`/api/v1/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getDepartmentsPositions(id, queries = {}) {
    return this.get(`/api/v1/departments/${id}/positions${objectToQueryString(queries)}`, 'user');
  },
  getPositionsEmployees(id, queries = {}, cts = null) {
    return this.get(`/api/v1/positions/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getCategorysEmployees(id, queries = {}, cts = null) {
    return this.get(`/api/v1/categories/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getCategorysPositions(id, queries = {}) {
    return this.get(`/api/v1/categories/${id}/positions${objectToQueryString(queries)}`, 'user');
  },
};
