import RequestManager from '~/api/request-manager';

const wrap = (client, interfaces = {}, requestLaneKey = 'requestLane') => {
  const wrapped = {};
  const requestManager = new RequestManager(client, process.env.API_HOST, requestLaneKey);

  Object.keys(interfaces).forEach(key => {
    wrapped[key] = (...args) => {
      return interfaces[key].bind(requestManager)(...args);
    };
  });

  wrapped.abortRequest = requestManager.abortRequest.bind(requestManager);
  return wrapped;
};

export default wrap;
