//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CTopBarLink',
  props: {
    icon: {
      type: String,
      default: 'dashboard',
    },
    iconSize: {
      type: String,
      default: 'large',
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Boolean,
      default: false,
    },
    badgeStyle: {
      type: String,
      default: '',
    },
    badgeCssClass: {
      type: String,
      default: 'u-bg--red-light u-fg--white',
    },
    fontawesome: {
      type: Boolean,
      default: false,
    },
    iconPlus: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    iconClassNames() {
      return [
        'o-icon',
        'o-icon--line',
        'o-icon--active--line-color',
        `o-icon--${this.iconSize}`,
        `o-icon--${this.icon}`,
        // `o-icon--active--${this.icon}`,
      ];
    },
  },
};
