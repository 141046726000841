import Vue from 'vue';
import MSAL from 'vue-msal';

export default ({ app, error, $axios }, inject) => {
  inject('msal', new MSAL(
    {
      auth: { 
        clientId: process.env.AZURE_CLIENT_ID, 
        tenantId: process.env.AZURE_TENANT_ID,
        redirectUri: process.env.AZURE_CALLBACK,
        navigateToLoginRequestUrl: false,
      },
      request: { 
        scopes: ['openid', 'profile'],
        prompt: process.env.AZURE_PROMPT,
        response_type: 'code',
      },
      cache: { 
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    }, Vue));
};
