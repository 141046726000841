import axios from 'axios';
import ApiClient from '~/api';

const initialState = {
  earningTypes: [],
  deductionTypes: [],
  taxTypes: [],
  newSalaryReasonTypes: [],
  payrollPayslips: [],
  payrollPayslipsMeta: {},
  selectedPayrollPayslip: {},
  selectedPayrollPayslipHistory: [],
  adminPayrollPageType: {
    list: 'list',
    revise: 'revise',
    update: 'update',
    history: 'history',
  },

  exports: [],
  exportStatuses: [],
  exportMeta: {},
  exportDetails: [],
  exportHistories: [],
  exportEmployees: [],
  exportEmployeesMeta: {},

  imports: [],
  importStatuses: [],
  importMeta: {},
  importDetails: [],
  importHistories: [],
  importEmployees: [],
  importEmployeesMeta: {},

  financeDocs: [],
  financeDocMeta: {},
  financeDocDetails: [],
  financeDocEmployees: [],
  financeDocEmployeesMeta: {},
};

const getters = {
  earningTypes: state => state.earningTypes,
  deductionTypes: state => state.deductionTypes,
  taxTypes: state => state.taxTypes,
  newSalaryReasonTypes: state => state.newSalaryReasonTypes,
  payrollPayslips: state => state.payrollPayslips,
  payrollPayslipsMeta: state => state.payrollPayslipsMeta,
  selectedPayrollPayslip: state => state.selectedPayrollPayslip,
  selectedPayrollPayslipHistory: state => state.selectedPayrollPayslipHistory,
  adminPayrollPageType: state => state.adminPayrollPageType,

  exports: state => state.exports,
  exportStatuses: state => state.exportStatuses,
  exportMeta: state => state.exportMeta,
  exportHistories: state => state.exportHistories,
  exportEmployees: state => state.exportEmployees,
  exportEmployeesMeta: state => state.exportEmployeesMeta,

  imports: state => state.imports,
  importStatuses: state => state.importStatuses,
  importMeta: state => state.importMeta,
  importHistories: state => state.importHistories,
  importEmployees: state => state.importEmployees,
  importEmployeesMeta: state => state.importEmployeesMeta,

  financeDocs: state => state.financeDocs,
  financeDocMeta: state => state.financeDocMeta,
  financeDocEmployees: state => state.financeDocEmployees,
  financeDocEmployeesMeta: state => state.financeDocEmployeesMeta,
};

const actions = {
  async fetchEarningTypes({ commit, dispatch }, { customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.payroll.getEarningTypes(customCompanyId);

      commit('setEarningTypes', data);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available earning types';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchDeductionTypes({ commit, dispatch }, { customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.payroll.getDeductionTypes(customCompanyId);

      commit('setDeductionTypes', data);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available deduction types';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchTaxTypes({ commit, dispatch }, { customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.payroll.getTaxTypes(customCompanyId);

      commit('setTaxTypes', data);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available tax types';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchPayPeriods({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.payroll.getPayPeriods();

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available pay periods';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchPayTypes({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.payroll.getPayTypes();

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available pay types';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchSalaryChangeReasons({ commit, dispatch }, { customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.payroll.getSalaryChangeReasons(customCompanyId);

      commit('setNewSalaryReasonTypes', data);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available payroll change reasons list';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchSalaryChangeRejectionReasons({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.payroll.getSalaryChangeRejectionReasons();

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available payroll change reasons list';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async fetchPayrollPayslips({ commit, dispatch }, query) {
    try {
      const { data, meta } = await ApiClient.v1.payroll.getPayrollPayslips(query);

      commit('setPayrollPayslips', data);
      commit('setPayrollPayslipsMeta', meta);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available payslips';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async fetchPayrollPayslip({ commit, dispatch }, id) {
    try {
      const { data } = await ApiClient.v1.payroll.getPayrollPayslip(id);

      commit('setSelectedPayrollPayslip', {
        content: data,
        index: null,
      });

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available payslips';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async fetchPayrollPayslipHistory({ commit, dispatch }, payslipId) {
    try {
      const { data } = await ApiClient.v1.payroll.getPayrollPayslipHistory(payslipId);

      commit('setSelectedPayrollPayslipHistory', data);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available payslip history';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async createNewEarningTypes({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.createEarningTypes(formData);

      commit('addEarningTypes', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async createNewDeductionTypes({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.createDeductionTypes(formData);

      commit('addDeductionTypes', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async createNewTaxTypes({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.createTaxTypes(formData);

      commit('addTaxTypes', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async createNewSalaryChangeReasons({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.createSalaryChangeReasons(formData);

      commit('addSalaryChangeReasons', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async updateExistingEarningTypes({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.updateEarningTypes(id, formData);

      if (data) commit('updateEarningTypes', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async updateExistingDeductionTypes({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.updateDeductionTypes(id, formData);

      if (data) commit('updateDeductionTypes', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async updateExistingTaxTypes({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.updateTaxTypes(id, formData);

      if (data) commit('updateTaxTypes', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async updateExistingSalaryChangeReasons({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.updateSalaryChangeReasons(id, formData);

      if (data) commit('updateSalaryChangeReasons', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async deleteExistingEarningTypes({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.payroll.deleteEarningTypes(id);
      commit('deleteEarningTypes', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async deleteExistingDeductionTypes({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.payroll.deleteDeductionTypes(id);
      commit('deleteDeductionTypes', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async deleteExistingTaxTypes({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.payroll.deleteTaxTypes(id);
      commit('deleteTaxTypes', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async deleteExistingSalaryChangeReasons({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.payroll.deleteSalaryChangeReasons(id);
      commit('deleteSalaryChangeReasons', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async publishPayrollPayslips({ commit, dispatch }, formData) {
    try {
      const { message } = await ApiClient.v1.payroll.publishPayrollPayslips(formData);

      return { errors: null, message };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Publish';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async revisePayrollPayslips({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.revisePayrollPayslips(id, formData);

      commit('updatePayrollPayslip', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Revise';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async updatePayrollPayslips({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.updatePayrollPayslips(id, formData);

      commit('updatePayrollPayslip', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async createPayrollPayslips({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.createPayrollPayslips(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async updateSelectedPayrollPayslip({ commit }, { content, index }) {
    commit('setSelectedPayrollPayslip', { content, index });
  },

  async fetchExports({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.payroll.getExports(query, cts);

      commit('setExports', data);
      commit('setExportMeta', meta);
      return data;
    } catch (e) {
      commit('setExports', []);
      commit('setExportMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.payroll.getExportStatus();
      commit('setExportStatuses', data);
      return [];
    } catch (e) {
      commit('setExportStatuses', []);
      return [];
    }
  },
  fetchExportById(_, id) {
    return ApiClient.v1.payroll.getExportById(id);
  },
  async fetchExportEmployeesById({ commit, dispatch }, { id, query = '', cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.payroll.getExportEmployeesById(id, query, cts);
      commit('setExportEmployees', data);
      commit('setExportEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setExportEmployees', []);
      commit('setExportEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.payroll.getExportHistoryById(id);
      commit('setExportHistories', data);
      return data;
    } catch (e) {
      commit('setExportHistories', []);
      return [];
    }
  },
  async exportPayslip({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.exportPayslip(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to export payslip';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async fetchImports({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.payroll.getImports(query, cts);

      commit('setImports', data);
      commit('setImportMeta', meta);
      return data;
    } catch (e) {
      commit('setImports', []);
      commit('setImportMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchImportStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.payroll.getImportStatus();
      commit('setImportStatuses', data);
      return [];
    } catch (e) {
      commit('setImportStatuses', []);
      return [];
    }
  },
  fetchImportById(_, id) {
    return ApiClient.v1.payroll.getImportById(id);
  },
  async fetchImportEmployeesById({ commit, dispatch }, { id, query = '', cts = null }) {
    try {
      const { data, success, failed, meta } = await ApiClient.v1.payroll.getImportEmployeesById(id, query, cts);
      commit('setImportEmployees', data);
      commit('setImportEmployeesMeta', meta);
      return { data, success, failed };
    } catch (e) {
      commit('setImportEmployees', []);
      commit('setImportEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchImportHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.payroll.getImportHistoryById(id);
      commit('setImportHistories', data);
      return data;
    } catch (e) {
      commit('setImportHistories', []);
      return [];
    }
  },
  async importPayslip({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.importPayslip(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to import payslip';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async deleteDraftPayslips({ commit, dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.payroll.deleteDraftPayslips(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchFinanceDocs({ commit, dispatch }, { query, cts = null, customCompanyId } = {}) {
    try {
      const { data, meta } = await ApiClient.v1.payroll.getFinanceDocs(query, cts, customCompanyId);

      commit('setFinanceDocs', data);
      commit('setFinanceDocMeta', meta);
      return data;
    } catch (e) {
      commit('setFinanceDocs', []);
      commit('setFinanceDocMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchFinanceDocById(_, id) {
    return ApiClient.v1.payroll.getFinanceDocById(id);
  },
  async fetchFinanceDocEmployeesById({ commit, dispatch }, { id, query = '' }) {
    try {
      const { data, meta } = await ApiClient.v1.payroll.getFinanceDocEmployeesById(id, query);
      commit('setFinanceDocEmployees', data);
      commit('setFinanceDocEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setFinanceDocEmployees', []);
      commit('setFinanceDocEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async createFinanceDoc({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.createFinanceDoc(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to create Finance Document';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateFinanceDoc({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.payroll.updateFinanceDoc(id, formData);
      // commit('updateFinanceDoc', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to update Finance Document';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  uploadFinanceDoc(_, formData) {
    return ApiClient.v1.payroll.uploadFinanceDoc(formData);
  },
  async deleteFinanceDoc({ commit, dispatch }, { docId, uuid }) {
    try {
      const { data } = await ApiClient.v1.payroll.deleteFinanceDoc(docId, uuid);

      return { errors: null, data };
    } catch (e) {
      return { errors: e, data: [] };
    }
  },
  async sendNotifFinanceDoc({ commit, dispatch }, formData) {
    try {
      const { message } = await ApiClient.v1.payroll.sendNotifFinanceDoc(formData);

      return { errors: null, message };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Send Notification';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },      
};

const mutations = {
  setEarningTypes(state, data) {
    state.earningTypes = data;
  },

  setDeductionTypes(state, data) {
    state.deductionTypes = data;
  },

  setTaxTypes(state, data) {
    state.taxTypes = data;
  },

  setNewSalaryReasonTypes(state, data) {
    state.newSalaryReasonTypes = data;
  },

  setPayrollPayslips(state, data) {
    state.payrollPayslips = data;
  },

  setPayrollPayslipsMeta(state, meta) {
    state.payrollPayslipsMeta = meta;
  },

  setSelectedPayrollPayslipHistory(state, data) {
    state.selectedPayrollPayslipHistory = data;
  },

  addEarningTypes(state, data) {
    state.earningTypes.push(data); // Add to the end of the list
  },

  updateEarningTypes(state, { idx, data }) {
    state.earningTypes.splice(idx, 1, data);
  },

  deleteEarningTypes(state, idx) {
    state.earningTypes.splice(idx, 1);
  },

  addDeductionTypes(state, data) {
    state.deductionTypes.push(data);
  },

  updateDeductionTypes(state, { idx, data }) {
    state.deductionTypes.splice(idx, 1, data);
  },

  deleteDeductionTypes(state, idx) {
    state.deductionTypes.splice(idx, 1);
  },

  addTaxTypes(state, data) {
    state.taxTypes.push(data);
  },

  updateTaxTypes(state, { idx, data }) {
    state.taxTypes.splice(idx, 1, data);
  },

  deleteTaxTypes(state, idx) {
    state.taxTypes.splice(idx, 1);
  },

  addSalaryChangeReasons(state, data) {
    state.newSalaryReasonTypes.splice(0, 0, data); // Add to the beginning of the list
  },

  updateSalaryChangeReasons(state, { idx, data }) {
    state.newSalaryReasonTypes.splice(idx, 1, data);
  },

  deleteSalaryChangeReasons(state, idx) {
    state.newSalaryReasonTypes.splice(idx, 1);
  },

  updatePayrollPayslip(state, { idx, data }) {
    state.payrollPayslips.splice(idx, 1, data);
  },
  setSelectedPayrollPayslip(state, { content, index }) {
    state.selectedPayrollPayslip = { content, index };
  },

  setExports(state, data) {
    state.exports = data;
  },
  setExportStatuses(state, data) {
    state.exportStatuses = data;
  },
  setExportMeta(state, data) {
    state.exportMeta = data;
  },
  setExportHistories(state, data) {
    state.exportHistories = data;
  },
  setExportEmployees(state, data) {
    state.exportEmployees = data;
  },
  setExportEmployeesMeta(state, data) {
    state.exportEmployeesMeta = data;
  },

  setImports(state, data) {
    state.imports = data;
  },
  setImportStatuses(state, data) {
    state.importStatuses = data;
  },
  setImportMeta(state, data) {
    state.importMeta = data;
  },
  setImportHistories(state, data) {
    state.importHistories = data;
  },
  setImportEmployees(state, data) {
    state.importEmployees = data;
  },
  setImportEmployeesMeta(state, data) {
    state.importEmployeesMeta = data;
  },

  setFinanceDocs(state, data) {
    state.financeDocs = data;
  },
  setFinanceDocMeta(state, data) {
    state.financeDocMeta = data;
  },
  setFinanceDocEmployees(state, data) {
    state.financeDocEmployees = data;
  },
  setFinanceDocEmployeesMeta(state, data) {
    state.financeDocEmployeesMeta = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
