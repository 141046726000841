import Vue from 'vue';
import Router from 'vue-router';

/**
 * Code Prefetch
 * Ref: https://nuxtjs.org/api/components-nuxt-link/
 * To improve the responsiveness of your Nuxt.js applications, when the link will be displayed within the viewport,
 * Nuxt.js will automatically prefetch the code splitted page. This feature is inspired by quicklink.js by Google Chrome Labs.
 *
 * Summary: Based on the explanation above, if one or more page is in focus, the system will automatically load the required files on idle.
 */

import GeneralRoutes from '~/router/general';
import UserRoutes from '~/router/user';
import AdminRoutes from '~/router/admin';
import OtherRoutes from '~/router/other';

Vue.use(Router);

const routes = [{ path: '/', redirect: '/dashboard', name: 'user_home' }, ...GeneralRoutes, ...UserRoutes, ...AdminRoutes, ...OtherRoutes];

const appRouter = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export function createRouter() {
  return appRouter;
}

export default appRouter;
