//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { convertTZ, parseTimestamp } from '~/helpers/super-date';

export default {
  name: 'CEmployeeTransferToast',
  filters: { convertTZ, parseTimestamp },
  data() {
    return {
      draftTransfer: null,
      isShowToast: true,
      isCloseToast: false,
      canceledTime: null,

      roles: [],
    };
  },
  watch: {
    '$route.name': {
      handler(newValue, oldValue) {
        if (newValue === 'admin_employee_transfer_create') this.isShowToast = false;
        if (oldValue === 'admin_employee_transfer_create' && newValue !== 'admin_employee_transfer_create' && !this.isCloseToast) this.isShowToast = true;
      },
      immediate: true,
    },
    draft() {
      if (this.draft && this.draft.length <= 0) this.draftTransfer = null;
    },
  },
  computed: {
    ...mapGetters({
      timezone: 'time/timezone',
      draft: 'employee/draftEmployeeTransfer',
    }),
    checkRoleAuthorization() {
      if (['admin', 'admin-hr'].some(r => this.roles.includes(r))) return true;
      return false;
    },
  },
  async mounted() {
    await this.init();

    if (this.draftTransfer) {
      this.getCanceledTime();
      setInterval(this.getCanceledTime, 60000);
    }
  },
  methods: {
    async init() {
      try {
        this.roles = await this.$store.dispatch('permission/getUserRoles');

        if (this.checkRoleAuthorization) {
          await this.$store.dispatch('employee/getDraftEmployeeTransfer');
          if (this.draft && this.draft.length) this.draftTransfer = this.draft[0];
        }
      } catch (e) {
        this.$store.dispatch('error/catchError', { error: e, caption: 'Unable to get draft count' });
      }
    },
    getCanceledTime() {
      const date = convertTZ(parseTimestamp(this.draftTransfer.expired), this.timezone);
      const now = new Date();
      const diffTime = date.getTime() - now.getTime();
      const diff = new Date(diffTime);
      let cancel = 'Auto canceled in the next ';

      if (date > now) {
        if (diff.getUTCHours() >= 1) cancel += `${ diff.getUTCHours() } hours `;
        cancel += `${ diff.getUTCMinutes() } minutes`;
      } else {
        cancel = 'The transfer request for this employee has already expired';
      }
      
      this.canceledTime = cancel;
    },
    async deleteDraft(data) {
      const result = await this.$swal({
        titleText: 'Cancel Transfer Employee',
        html: `Are you sure you want to cancel the employee transfer process for <span class="font-weight-bold u-fg--red">${ data.name }</span> from <span class="font-weight-bold u-fg--red">${ data.company_name }</span> company? <br> You won't be able to revert this action.`,
        icon: 'question',
        iconHtml: '<i class="fas fa-fw fa-people-arrows u-fg--red"></i>',
        showCancelButton: true,
        confirmButtonText: 'Cancel',
        cancelButtonText: 'Back',
        customClass: {
          title: 'u-fg--red',
          popup: 'swal2-popup--sm',
          icon: 'border-0 icon-big',
          confirmButton: 'swal2-confirm--sm u-bg--red',
          cancelButton: 'swal2-cancel--sm swal2-cancel--outline',
        },
      });

      if (result.value) {
        await this.$store.dispatch('employee/updateEmployeeTransfer', {
          id: data.id,
          formData: { is_delete: 1 },
        });
        window.location.reload();
      }
    },
  },
};
