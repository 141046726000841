import ApiClient from '~/api';

const initialState = {
  currencies: [],
  activeCurrency: [],
};

const getters = {
  currencies: state => state.currencies,
  activeCurrency: state => state.activeCurrency,
};

const actions = {
  async fetchCurrencies({ commit, dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.currency.getAllCurrencies(formData);
      commit('setCurrencies', data);
      return data;
    } catch (e) {
      commit('setCurrencies', []);
      const caption = 'Failed to Load Currencies';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchCurrencyByLocale({ commit, dispatch }, locale) {
    try {
      const { data } = await ApiClient.v1.currency.getCurrencyByLocale(locale);
      commit('setActiveCurrency', data);
      return data;
    } catch (e) {
      commit('setActiveCurrency', {});
      const caption = 'Failed to Load Currency';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchCurrencyById({ commit, dispatch }, id) {
    try {
      const { data } = await ApiClient.v1.currency.getCurrencyById(id);
      commit('setActiveCurrency', data);
      return data;
    } catch (e) {
      commit('setActiveCurrency', {});
      const caption = 'Failed to Load Currency';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
};

const mutations = {
  setCurrencies(state, value) {
    state.currencies = value;
  },
  setActiveCurrency(state, value) {
    state.activeCurrencies = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
