const initialState = {
  limitList: [
    { id: 10, name: 10 },
    { id: 15, name: 15 },
    { id: 20, name: 20 },
    { id: 25, name: 25 },
    { id: 50, name: 50 },
  ],
};

const getters = {
  limitList: state => state.limitList,
  limit: state => id => {
    return state.limitList.find(limit => limit.id === id);
  },
};

const actions = {};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
