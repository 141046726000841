//
//
//
//

export default {
  name: 'LayoutEmpty',
  mounted() {
    const bodyElement = document.body;
    bodyElement.classList.remove('theme-dark');
  },
};
