import Vue from 'vue';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip, {
  defaultPlacement: 'auto',
  defaultContainer: 'body',
  popover: {
    defaultPlacement: 'auto',
  },
});
