import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ff6a47a2&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=ff6a47a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff6a47a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CSidebar: require('/home/web/alpha-fe/components/navbar/CSidebar.vue').default,CTopBar: require('/home/web/alpha-fe/components/navbar/CTopBar.vue').default,CToast2: require('/home/web/alpha-fe/components/toast/CToast2.vue').default,CFooter: require('/home/web/alpha-fe/components/CFooter.vue').default})
