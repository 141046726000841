/* eslint-disable no-await-in-loop */
export function nl2br(text) {
  return text.split('\n').reduce((accumulator, string) => {
    if (!Array.isArray(accumulator)) {
      return [accumulator, '<br />', string];
    }
    return accumulator.concat(['<br />', string]);
  });
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
}

export const mapArrayToUl = (lists, cssClass = 'mb-0 pl-4') => {
  return `<ul class="${cssClass}"><li>${lists.join('</li><li>')}</li></ul>`;
};

export const substring = (text, count) => {
  return text.slice(0, count) + (text.length > count ? '...' : '');
};

export const randomId = (prefix = '_') => {
  const val = Math.random()
    .toString(36)
    .substr(2, 9);

  return `${prefix}${val}`;
};

export const acronym = (str, separator = ' ') => {
  let newStr = str;
  if (separator !== ' ') newStr = str.replace(separator, ' ');

  const matches = newStr.match(/\b(\w)/g); // ['J','S','O','N']
  return matches.join(''); // JSON
};

export const nonVowel = (str = '') => {
  if (str === '') return '';
  return str
    .split('')
    .map(x => x.replace(/[aeiouAEIOU ]/g, ''))
    .join('');
};

export const autoCode = (str = '') => {
  if (str.length === 0) return '';
  const spaces = str.split(' ').length - 1;
  if (spaces >= 1) return acronym(str);
  return nonVowel(str);
};

export const uppercase = (str = '') => {
  return str.toUpperCase();
};

export const lowercase = (str = '') => {
  return str.toLowerCase();
};

export function capitalize(value) {
  if (!value) return '';
  const valueAsString = value.toString();
  return valueAsString.charAt(0).toUpperCase() + valueAsString.slice(1);
}

export default {
  nl2br,
  asyncForEach,
  mapArrayToUl,
  substring,
  randomId,
  acronym,
  nonVowel,
  autoCode,
  uppercase,
  lowercase,
  capitalize,
};
