const assetsMixin = {
  methods: {
    async fetchAssetFromDocumentService(filename, isAdmin = true, options = {}) {
      const action = isAdmin ? 'document/fetchDocumentsFile' : 'document/fetchDocumentsFileGeneral';
      const file = await this.$store.dispatch(action, filename);

      try {
        let c = true;
        const buffer = await file.arrayBuffer().catch(function (e) { c = false; });
        if (c) {
          let blob = null; 
          if (options.isPdfFile) { blob = new Blob([buffer], { type: 'application/pdf' }); }
          else { blob = new Blob([buffer]); }

          return URL.createObjectURL(blob);
        }
      } catch (error) {
        if (options.isViewFile) this.$store.dispatch('error/catchError', { error, caption: 'Unable to open file', message: `${ process.env.APP_NAME } is having trouble opening this file. Please try to close and reopen the file again.` });
        return null;
      }

      return null;
    },

    async getAvatarUrl(url, isAdmin = true, gender = null) {
      let avatarUrl = '/images/user-placeholder.png';

      if (gender === 'male' || gender === 'M') avatarUrl = '/images/male-placeholder.png';
      if (gender === 'female' || gender === 'F') avatarUrl = '/images/female-placeholder.png';

      if (url) {
        avatarUrl = await this.fetchAssetFromDocumentService(url, isAdmin);
      }

      return avatarUrl;
    },

    async downloadAssetFromDocumentService(filename, useBlob = false, blob = null) {
      let file;
      if (useBlob) {
        file = blob;
      } else {
        file = await this.fetchAssetFromDocumentService(filename);
      }
      if (file) {
        const tmp = document.createElement('a');
        tmp.href = file;
        tmp.download = filename;
        tmp.click();
      }
    },
  },
};

export default assetsMixin;
