import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'adm_dept_index' */ '~/pages/admin/department/Index');
const List = () => import(/* webpackChunkName: 'adm_dept_list' */ '~/pages/admin/department/List');
const Detail = () => import(/* webpackChunkName: 'adm_dept_detail' */ '~/pages/admin/department/Detail');
const DetailDepartment = () => import(/* webpackChunkName: 'adm_dept_detail_position' */ '~/pages/admin/department/DetailPosition');
const DetailEmployee = () => import(/* webpackChunkName: 'adm_dept_detail_employee' */ '~/pages/admin/department/DetailEmployee');

const routes = {
  path: 'employee/department',
  component: r(Index),
  children: [
    {
      path: '',
      name: 'admin_department_list',
      component: r(List),
    },
    {
      path: ':id(\\d+)',
      component: r(Detail),
      children: [
        {
          path: '',
          redirect: '/admin/department/:id/employees',
          name: 'admin_department_view',
        },
        {
          path: 'employees',
          name: 'admin_department_view_employee',
          component: r(DetailEmployee),
        },
        {
          path: 'positions',
          name: 'admin_department_view_position',
          component: r(DetailDepartment),
        },
      ],
    },
  ],
};

// old routes
const redirect = {
  path: 'department',
  children: [
    { path: '', name: 'admin_department_list_old', redirect: { name: 'admin_department_list' } },
    {
      path: ':id(\\d+)',
      children: [
        { path: '', name: 'admin_department_view_old', redirect: { name: 'admin_department_view' } },
        { path: 'employees', name: 'admin_department_view_employee_old', redirect: { name: 'admin_department_view_employee' } },
        { path: 'positions', name: 'admin_department_view_position_old', redirect: { name: 'admin_department_view_position' } },
      ],
    },
  ],
};

export default [
  routes,
  redirect,
];
