import ApiOptions from '~/api/api-options';
import { validateObject } from '~/helpers/url-helper';

export default {
  // Roles
  getRoles(queries) {
    const allowed = ['search', 'sort_by', 'sort_direction', 'page', 'limit', 'name', 'is_hidden'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/roles${filteredQs}`, 'user');
  },
  getRole(name) {
    return this.get(`/api/v1/roles/${name}`, 'user');
  },
  createRole(formData) {
    const allowed = ['name', 'description'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/roles`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateRole(name, formData) {
    const allowed = ['new_name', 'description'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/roles/${name}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteRole(name) {
    return this.delete(`/api/v1/roles/${name}`, 'user');
  },
  updateRoleStatus(name, formData) {
    const allowed = ['status'];
    const filtered = validateObject(formData, allowed);
    return this.patch(`/api/v1/roles/${name}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  attachPermissionsToRoles(name, formData) {
    const allowed = ['name'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/roles/${name}/permissions`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  getPermissionsToRoles(name) {
    return this.get(`/api/v1/roles/${name}/permissions`, 'user');
  },

  getRoleEmployees(name) {
    return this.get(`/api/v1/roles/${name}/employees`, 'user');
  },

  getUserRoles() {
    return this.get(`/api/v1/_self/employees/roles`, 'user');
  },
};
