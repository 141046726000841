const initialState = {
  timezones: [],
};

const getters = {
  timezones: state => state.timezones,
};

const actions = {
  async getTimezones({ commit }) {
    try {
      const url = '/data/list-timezone.json';
      const { data } = await this.$axios.get(url);
      commit('setTimezones', data);
      return [];
    } catch (e) {
      commit('setTimezones', []);
      return [];
    }
  },
};

const mutations = {
  setTimezones(state, value) {
    state.timezones = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
