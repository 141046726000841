import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_recruitment_employee_request_list' */ '~/pages/admin/recruitment/Index');
const EmployeeReqList = () => import(/* webpackChunkName: 'admin_recruitment_employee_request_list' */ '~/pages/admin/recruitment/EmployeeReqList');
const EmployeeReqCreate = () => import(/* webpackChunkName: 'admin_recruitment_employee_request_create' */ '~/pages/admin/recruitment/EmployeeReqCreate');
const EmployeeReqUpdate = () => import(/* webpackChunkName: 'admin_recruitment_employee_request_update' */ '~/pages/admin/recruitment/EmployeeReqUpdate');
const EmployeeReqDetail = () => import(/* webpackChunkName: 'admin_recruitment_employee_request_detail' */ '~/pages/admin/recruitment/EmployeeReqDetail');

export default {
  path: 'recruitment',
  component: r(Index),
  children: [
    { path: '', redirect: '/admin/recruitment/employee-request', name: 'admin_recruitment_index' },
    {
      path: 'employee-request',
      component: r(Index),
      children: [
        { path: '', name: 'admin_recruitment_employee_request_list', component: r(EmployeeReqList) },
        { path: 'new', name: 'admin_recruitment_employee_request_create', component: r(EmployeeReqCreate) },
        { path: ':id/edit', name: 'admin_recruitment_employee_request_update', component: r(EmployeeReqUpdate) },
        { path: ':id', name: 'admin_recruitment_employee_request_detail', component: r(EmployeeReqDetail) },
      ],
    },
  ],
};
