import Vue from 'vue';
import VueCookies from 'vue-cookies';
import ApiClient from '~/api';

Vue.use(VueCookies);

const initialState = {
  activeUser: null,
  emergencyContacts: null,
  bankAccounts: null,
  locale: null,
  companySetting: null,
};

const getters = {
  activeUser: state => state.activeUser,
  emergencyContacts: state => state.emergencyContacts,
  bankAccounts: state => state.bankAccounts,
  locale: state => state.locale,
  companySetting: state => state.companySetting,
  // locale() {
  //   return Vue.$cookies.get('locale');
  // },
  // companySetting() {
  //   const companySetting = Vue.$cookies.get('companySetting');
  //   companySetting.max_upload_filesize = parseInt(companySetting.max_upload_filesize, 10);
  //   return companySetting;
  // },
};

const actions = {
  // Get Locale
  async getLocale({ state, commit, dispatch }, _) {
    try {
      if (state.locale === null) {
        if (this.$auth.user && this.$auth.user.locale) {
          commit('setLocale', this.$auth.user.locale);
          return this.$auth.user.locale;
        }

        const { data } = await ApiClient.v1.company.getActiveLocale();
        commit('setLocale', data);
        return data;
      }
      return state.locale;
    } catch (e) {
      commit('setLocale', null);

      const caption = 'Unable to load locale';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return null;
    }
  },

  // Get CompanySetting
  async getCompanySetting({ state, commit, dispatch }, _) {
    try {
      if (state.companySetting === null) {
        if (Vue.$cookies.get('companySetting') === null || Vue.$cookies.get('companySetting') === undefined) {
          const { data } = await ApiClient.v1.company.getLoginData();

          let obj = {};
          data.forEach(element => {
            obj = {
              ...obj,
              [element.key]: element.value,
            };
          });

          obj.max_upload_filesize = parseInt(obj.max_upload_filesize, 10);
          commit('setCompanySetting', obj);
          return obj;
        }

        const companySetting = Vue.$cookies.get('companySetting');
        companySetting.max_upload_filesize = parseInt(companySetting.max_upload_filesize, 10);
        commit('setCompanySetting', companySetting);
        return companySetting;
      }
      return state.companySetting;
    } catch (e) {
      commit('setCompanySetting', null);

      const caption = 'Unable to load company setting login data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return null;
    }
  },

  async fetchUser({ commit }) {
    try {
      const { data } = await ApiClient.v1.user.getActiveUser();
      commit('setActiveUser', data);
    } catch (e) {
      commit('setActiveUser', null);
    }
  },
  async fetchEmergencyContacts({ commit }, uuid) {
    try {
      const { data } = await ApiClient.v1.user.getMyEmergencyContacts(uuid);
      commit('setEmergencyContacts', data);
    } catch (e) {
      commit('setEmergencyContacts', null);
    }
  },
  addEmergencyContactItem({ commit }, formData) {
    commit('addEmergencyContactItem', formData);
  },
  updateEmergencyContactItem({ commit }, { idx, formData }) {
    commit('updateEmergencyContactItem', { idx, formData });
  },
  deleteEmergencyContactItem({ commit }, { idx }) {
    commit('deleteEmergencyContactItem', idx);
  },
  async fetchBankAccount({ commit }, uuid) {
    try {
      const { data } = await ApiClient.v1.user.getMyBankAccounts(uuid);
      commit('setBankAccounts', data);
    } catch (e) {
      commit('setBankAccounts', null);
    }
  },
  async fetchOtherData({ commit }, { search }) {
    try {
      const { data } = await ApiClient.v1.user.getMyOtherData({ search, group: 'category' });
      commit('employee/setActiveEmployeeOtherData', data, { root: true });

      return data;
    } catch (e) {
      commit('employee/setActiveEmployeeOtherData', null, { root: true });

      return null;
    }
  },
  changePassword(_, formData) {
    return ApiClient.v1.user.changePassword(formData);
  },
  changeAuthUserAvatar({ commit }, filename) {
    commit('setAuthUserAvatar', filename);
  },
};

const mutations = {
  setLocale(state, value) {
    state.locale = value;
  },

  setCompanySetting(state, value) {
    state.companySetting = value;
  },

  setActiveUser(state, value) {
    state.activeUser = value;
  },
  setEmergencyContacts(state, value) {
    state.emergencyContacts = value;
  },
  setBankAccounts(state, value) {
    state.bankAccounts = value;
  },
  addEmergencyContactItem(state, formData) {
    state.activeUser.contacts.push(formData); // Add to the end of the list
    // state.activeUser.contacts.splice(0, 0, formData); // Add to the beginning of the list
  },
  updateEmergencyContactItem(state, { idx, formData }) {
    state.activeUser.contacts.splice(idx, 1, formData);
  },
  deleteEmergencyContactItem(state, idx) {
    state.activeUser.contacts.splice(idx, 1);
  },
  setAuthUserAvatar(state, fullname) {
    this.$auth.user.avatar_url = fullname;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
