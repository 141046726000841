var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"closeable":!_vm.isSubmitting},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('i',{staticClass:"fas fa-fw fa-key"}),_vm._v(" "),_c('span',[_vm._v("Change Password")])])]},proxy:true},{key:"body",fn:function(){return [_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 font-weight-std"},[_c('CFormLabel',{attrs:{"required":"","id":"txtOldPassword","label":"Old Password"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12",class:{ 'field-error': _vm.isFieldError(errors, 'old_password') }},[_c('CInputPassword',{attrs:{"id":"txtOldPassword","disabled":_vm.isSubmitting},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}}),_vm._v(" "),_c('CValidationError',{attrs:{"errors":_vm.fieldError(errors ? errors : null),"keys":['old_password']}})],1)])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required|valid_password","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 font-weight-std"},[_c('CFormLabel',{attrs:{"required":"","id":"txtPassword","label":"New Password"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12",class:{ 'field-error': _vm.isFieldError(errors, 'password') }},[_c('CInputPassword',{attrs:{"id":"txtPassword","disabled":_vm.isSubmitting,"placeholder":"Enter your New Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_vm._v(" "),_c('CValidationError',{attrs:{"errors":_vm.fieldError(errors ? errors : null),"keys":['password']}})],1)])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"mode":"lazy","rules":"required|match_password:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 font-weight-std"},[_c('CFormLabel',{attrs:{"required":"","id":"txtPasswordConfirmation","label":"Confirm New Password"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12",class:{ 'field-error': _vm.isFieldError(errors, 'password_confirmation') }},[_c('CInputPassword',{attrs:{"id":"txtPasswordConfirmation","disabled":_vm.isSubmitting,"placeholder":"Confirm your New Password"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_vm._v(" "),_c('CValidationError',{attrs:{"errors":_vm.fieldError(errors ? errors : null),"keys":['password_confirmation']}})],1)])]}}],null,true)}),_vm._v(" "),_c('hr',{staticClass:"my-4"}),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('CButton',{staticClass:"btn btn-outline-secondary btn-block",attrs:{"is-loading":_vm.isSubmitting},on:{"input":_vm.close}},[_vm._v("\n                  Cancel\n                ")])],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('CButton',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit","is-loading":_vm.isSubmitting}},[_vm._v("\n                  Change Password\n                ")])],1)])])],1)]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }