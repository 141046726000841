import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  getPAStatus(queries) {
    return this.get(`/api/v1/pa-status${objectToQueryString(queries)}`, 'user');
  },

  getTemplates(queries, cts = null) {
    return this.get(`/api/v1/templates${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getTemplateById(id) {
    return this.get(`/api/v1/templates/${id}`, 'user');
  },
  createTemplate(formData) {
    return this.post(`/api/v1/templates`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  duplicateTemplate(id, formData) {
    return this.post(`/api/v1/templates/${id}/duplicate`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateTemplate(id, formData) {
    return this.put(`/api/v1/templates/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateTemplateStatus(id) {
    return this.patch(`/api/v1/templates/${id}/status`, 'user');
  },
  deleteTemplate(id) {
    return this.delete(`/api/v1/templates/${id}`, 'user');
  },

  getScores(queries, cts = null) {
    return this.get(`/api/v1/score-types${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getScoreById(id) {
    return this.get(`/api/v1/score-types/${id}`, 'user');
  },
  createScore(formData) {
    return this.post(`/api/v1/score-types`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  duplicateScore(id, formData) {
    return this.post(`/api/v1/score-types/${id}/duplicate`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateScore(id, formData) {
    return this.put(`/api/v1/score-types/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateScoreStatus(id) {
    return this.patch(`/api/v1/score-types/${id}/status`, 'user');
  },
  deleteScore(id) {
    return this.delete(`/api/v1/score-types/${id}`, 'user');
  },

  getCycles(queries, cts = null) {
    return this.get(`/api/v1/cycles${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  createCycle(formData) {
    return this.post(`/api/v1/cycles`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  duplicateCycle(id, formData) {
    return this.post(`/api/v1/cycles/${id}/duplicate`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateCycle(id, formData) {
    return this.put(`/api/v1/cycles/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateCycleStatus(id) {
    return this.patch(`/api/v1/cycles/${id}/status`, 'user');
  },
  deleteCycle(id) {
    return this.delete(`/api/v1/cycles/${id}`, 'user');
  },
  getCycleById(id) {
    return this.get(`/api/v1/cycles/${id}`, 'user');
  },
  getCycleReviews(queries, id, cts = null) {
    return this.get(`/api/v1/cycles/${id}/generated-forms${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },

  getReviews(queries, cts = null) {
    return this.get(`/api/v1/generated-forms${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getMyReviews(queries, cts = null) {
    return this.get(`/api/v1/my-forms${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getReviewById(id, queries) {
    return this.get(`/api/v1/generated-forms/${id}${objectToQueryString(queries)}`, 'user');
  },
  getEmployeeReviewers(queries, id, cts = null) {
    return this.get(`/api/v1/generated-forms/${id}/detail${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getReviewHistoryById(id) {
    return this.get(`/api/v1/generated-forms/${id}/history`, 'user');
  },
  getReviewDetailById(id) {
    return this.get(`/api/v1/generated-forms/${id}/review`, 'user');
  },
  getReviewDetailFormById(id, queries, cts = null) {
    return this.get(`/api/v1/forms/${id}${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  addReviewer(formData) {
    return this.post(`/api/v1/reviewers`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  assignReviewer(id, formData) {
    return this.post(`/api/v1/form/${id}/reviewers`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  changeReviewer(id, formData) {
    return this.put(`/api/v1/form/${id}/reviewers`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  shareAccessReviewer(id, formData) {
    return this.post(`/api/v1/form/${id}/shared`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  removeAccessReviewer(id, shareId) {
    return this.delete(`/api/v1/form/${id}/shared/${shareId}`, 'user');
  },
  cancelReviewer(id) {
    return this.delete(`/api/v1/form/${id}/reviewers`, 'user');
  },
  startReview(id) {
    return this.get(`/api/v1/generated-forms/${id}/start`, 'user');
  },
  cancelReview(id) {
    return this.get(`/api/v1/generated-forms/${id}/cancel`, 'user');
  },
  finishReview(id, formData) {
    return this.post(`/api/v1/generated-forms/${id}/finish`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  recalculateIndiscipline(id) {
    return this.get(`/api/v1/generated-forms/${id}/adjust-indisciplines`, 'user');
  },
  saveIndiscipline(id, formData) {
    return this.post(`/api/v1/generated-forms/${id}/save-indisciplines`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  saveReviewForm(id, formData) {
    return this.post(`/api/v1/form-results/${id}`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },
  setAppraisalStatus(id, formData) {
    return this.post(`/api/v1/generated-forms/${id}/history`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getReviewCount(queries) {
    return this.get(`/api/v1/pa-widgets${objectToQueryString(queries)}`, 'user');
  },

  getIndisciplines(queries) {
    return this.get(`/api/v1/indisciplines${objectToQueryString(queries)}`, 'user');
  },
  getIndisciplineById(id) {
    return this.get(`/api/v1/indisciplines/${id}`, 'user');
  },
  createIndiscipline(formData) {
    return this.post(`/api/v1/indisciplines`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  duplicateIndiscipline(id, formData) {
    return this.post(`/api/v1/indisciplines/${id}/duplicate`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateIndiscipline(id, formData) {
    return this.put(`/api/v1/indisciplines/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateIndisciplineStatus(id) {
    return this.patch(`/api/v1/indisciplines/${id}/status`, 'user');
  },
  deleteIndiscipline(id) {
    return this.delete(`/api/v1/indisciplines/${id}`, 'user');
  },

  getRecommendations(queries) {
    return this.get(`/api/v1/recommendations${objectToQueryString(queries)}`, 'user');
  },
  getRecommendationById(id) {
    return this.get(`/api/v1/recommendations/${id}`, 'user');
  },
  createRecommendation(formData) {
    return this.post(`/api/v1/recommendations`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateRecommendation(id, formData) {
    return this.put(`/api/v1/recommendations/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateRecommendationStatus(id) {
    return this.patch(`/api/v1/recommendations/${id}/status`, 'user');
  },
  deleteRecommendation(id) {
    return this.delete(`/api/v1/recommendations/${id}`, 'user');
  },

  getExports(queries) {
    return this.get(`/api/v1/pa-exports${objectToQueryString(queries)}`, 'user');
  },
  getExportById(id) {
    return this.get(`/api/v1/pa-exports/${id}`, 'user');
  },
  getExportEmployeesById(id, queries = '', cts = null) {
    return this.get(`/api/v1/pa-exports/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  exportPA(formData) {
    return this.post(`/api/v1/pa-exports`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getExportHistoryById(id) {
    return this.get(`/api/v1/pa-exports/${id}/history`, 'user');
  },
  
  getCycleActive(uuid) {
    return this.get(`/api/v1/active-cycles?employee_id=${uuid}`, 'user');
  },
  getAvailablePeriod(uuid, id) {
    return this.get(`/api/v1/available-periods?employee_id=${uuid}&cycle_id=${id}`, 'user');
  },
  generateForm(formData) {
    return this.post(`/api/v1/generate-manuals`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },

  getAlphaData(queries) {
    return this.get(`/api/v1/master-indisciplines${objectToQueryString(queries)}`, 'user');
  },
  getTimeframes(queries) {
    return this.get(`/api/v1/timeframes${objectToQueryString(queries)}`, 'user');
  },
  getReviewMethods(queries) {
    return this.get(`/api/v1/review-methods${objectToQueryString(queries)}`, 'user');
  },
  downloadPdf(id) {
    const options = new ApiOptions({
      responseErrorJson: false,
      requestHeaders: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': 'attachment',
        Accept: '*/*',
      },
    });
    return this.get(`/api/v1/download-pa/${id}`, 'user', options);
  },
};
