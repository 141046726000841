export const settings = {
  // Prefix, Postfix
  prefix: '%',
  postfix: '%',

  // Codes
  codes: {
    // DAY
    day: 'D', // A textual representation of a day, three letters                         Mon through Sun
    dayFull: 'l', // A full textual representation of the day of the week                 Sunday through Saturday

    // DATE
    date: 'j', // Day of the month without leading zeros                                  1 to 31
    dateLead: 'd', // Day of the month, 2 digits with leading zeros                       01 to 31

    // MONTH
    month: 'n', // Numeric representation of a month, without leading zeros               1 through 12
    monthLead: 'm', // Numeric representation of a month, with leading zeros	            01 through 12
    monthShort: 'M', // A short textual representation of a month, three letters	        Jan through Dec
    monthLong: 'F', // A full textual representation of a month	                          January through December

    // YEAR
    year: 'y', // A two digit representation of a year                                    99 or 03
    yearFull: 'Y', // A full numeric representation of a year, 4 digits                   1999 or 2003

    // TIME
    hour24: 'G', // 24-hour format of an hour without leading zeros	                      0 through 23
    hour24Lead: 'H', // 24-hour format of an hour with leading zeros	                    0 through 23
    hour12: 'g', // 12-hour format of an hour without leading zeros	                      1 through 12
    hour12Lead: 'h', // 12-hour format of an hour with leading zeros	                    01 through 12
    minute: 'i', // Minutes without leading zeros	                                          0 to 59
    minuteLead: 'I', // Minutes with leading zeros	                                      00 to 59
    second: 's', // Seconds without leading zeros	                                          0 through 59
    secondLead: 'S', // Seconds with leading zeros	                                      00 through 59
    meridiemLower: 'a', // Lowercase Ante meridiem and Post meridiem	                    am or pm
    meridiemUpper: 'A', // Uppercase Ante meridiem and Post meridiem	                    AM or PM
  },
  equivalent: {
    H: 'HH',
    G: 'H',
    g: 'h',
    h: 'hh',
    i: 'm',
    I: 'mm',
    s: 's',
    S: 'ss',
    a: 'a',
    A: 'A',
  },
  names: {
    en: {
      day: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayFull: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      monthShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      monthLong: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
    id: {
      day: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
      dayFull: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
      monthShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des'],
      monthLong: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
    },
  },
};

/**
 * Wrap string with date prefix and postfix
 *
 * @param   {!string=}      str         Name
 *
 * @return  {string}        The wrapped string
 */
export const wrap = str => {
  return `${settings.prefix}${str}${settings.prefix}`;
};

export const eq = str => {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/%(.*?)%/gi, n => settings.equivalent[n.replace(/\%/g, '')]);
};

/**
 * Add given character before the number so that it can match the given length
 *
 * @param   {!number=}      number            The number
 * @param   {!number=}      targetLength      The required length
 * @param   {!string=}      padString         Character to add before the number
 *
 * @return  {string}        The prepended string
 */
export const prepend = (number, targetLength = 2, padString = '0') => {
  return number.toString().padStart(targetLength, padString);
};

const regionSetting = JSON.parse(localStorage.getItem('regionSetting'));

export const defaults = {
  dateToDisplay: regionSetting ? regionSetting.dateFormat.id : `${wrap(settings.codes.dateLead)} ${wrap(settings.codes.monthShort)} ${wrap(settings.codes.yearFull)}`, // %d% %M% %Y%
  dateToSave: `${wrap(settings.codes.yearFull)}-${wrap(settings.codes.monthLead)}-${wrap(settings.codes.dateLead)}`, // %Y%-%m%-%d%
  timeToDisplay: regionSetting ? regionSetting.timeFormat.id : `${wrap(settings.codes.hour24Lead)}:${wrap(settings.codes.minuteLead)}`, // %H%:%I%
  timezone: `UTC`,
};

/**
 * Format given timestamp into specified format
 *
 * @param   {?mo=}      ts            Month in integer 1 - 12
 * @param   {!string=}      locale        The prefered locale, `en` or `id`
 * 
 * @return  {string}        The converted result
 */

export const convertMonth = (mo = null, locale = 'en') => {
  return settings.names[locale].monthLong[mo - 1];
};

export const convertDayToInt = (day = null, locale = 'en') => {
  return settings.names[locale].dayFull.findIndex(
    e => {
      if (e === day) return true;
      return false;
    },
  );
};

export const convertIntToDay = (dayInt = null, locale = 'en') => {
  return settings.names[locale].dayFull[dayInt];
};

export const getYears = (start = false, n = 3) => {
  const d = new Date();
  let tahun = d.getFullYear();

  if (start) {
    tahun = start;
  }

  const years = [];
  for (let index = 0; index < n; index += 1) {
    years.push(tahun + index);
  }

  return years;
};

export function convertTZ(ts, timezone = false) {
  let dateTime = new Date(ts);
  if (timezone) {
    dateTime = new Date(dateTime.toLocaleString('en-US', { timeZone: timezone }));
  } else {
    dateTime = new Date(dateTime.toLocaleString('en-US', { timeZone: defaults.timezone }));
  }

  return dateTime;
}

/**
 * Format given timestamp into specified format
 *
 * @param   {?number=}      ts            The timestamp you wish to convert
 * @param   {!string=}      format        The format, can include date and time
 * @param   {!string=}      locale        The prefered locale, `en` or `id`
 *
 * @return  {string}        The converted result, based on the given format
 */
export const formatDate = (ts = null, format = 'default', timezone = false, locale = 'en', throwError = false) => {
  const defaultDateFormat = defaults.dateToDisplay;
  let dateFormat = format !== null ? format : defaultDateFormat;
  dateFormat = dateFormat === 'default' ? defaultDateFormat : dateFormat;

  const timestamp = ts !== null ? ts : Date.now();
  const dateTime = convertTZ(timestamp, timezone);
  const result = [];

  if (typeof ts !== 'number' || Number.isNaN(ts)) {
    if (throwError) throw new Error('Timestamp is invalid');
    return '-';
  }

  // DAY
  result.day = settings.names[locale].day[dateTime.getDay()];
  result.dayFull = settings.names[locale].dayFull[dateTime.getDay()];

  // DATE
  result.date = dateTime.getDate();
  result.dateLead = `0${result.date}`.slice(-2);

  // MONTH
  result.month = dateTime.getMonth() + 1;
  result.monthLead = prepend(result.month); // `0${result.month}`.slice(-2);
  result.monthShort = settings.names[locale].monthShort[dateTime.getMonth()];
  result.monthLong = settings.names[locale].monthLong[dateTime.getMonth()];

  // YEAR
  result.yearFull = dateTime.getFullYear().toString();
  result.year = result.yearFull.substr(-2);

  // TIME
  result.hour24 = dateTime.getHours();
  result.hour24Lead = `0${result.hour24}`.slice(-2);
  result.hour12 = result.hour24 > 12 ? result.hour24 - 12 : result.hour24;
  result.hour12Lead = `0${result.hour12}`.slice(-2);
  result.minute = dateTime.getMinutes();
  result.minuteLead = `0${result.minute}`.slice(-2);
  result.second = dateTime.getSeconds();
  result.secondLead = `0${result.second}`.slice(-2);
  result.meridiemLower = result.hour24 >= 12 ? 'pm' : 'am';
  result.meridiemUpper = result.meridiemLower.toUpperCase();

  const maps = [];
  Object.entries(settings.codes).forEach(x => {
    maps[`${wrap(x[1])}`] = result[x[0]];
  });

  return dateFormat.replace(/%(.*?)%/gi, n => maps[n]);
};

export const formatDateWithoutTZ = (ts = null, format = 'default', locale = 'en', throwError = false) => {
  const defaultDateFormat = defaults.dateToDisplay;
  let dateFormat = format !== null ? format : defaultDateFormat;
  dateFormat = dateFormat === 'default' ? defaultDateFormat : dateFormat;

  const timestamp = ts !== null ? ts : Date.now();
  const dateTime = new Date(timestamp);
  const result = [];

  if (typeof ts !== 'number' || Number.isNaN(ts)) {
    if (throwError) throw new Error('Timestamp is invalid');
    return '-';
  }

  // DAY
  result.day = settings.names[locale].day[dateTime.getDay()];
  result.dayFull = settings.names[locale].dayFull[dateTime.getDay()];

  // DATE
  result.date = dateTime.getDate();
  result.dateLead = `0${result.date}`.slice(-2);

  // MONTH
  result.month = dateTime.getMonth() + 1;
  result.monthLead = prepend(result.month); // `0${result.month}`.slice(-2);
  result.monthShort = settings.names[locale].monthShort[dateTime.getMonth()];
  result.monthLong = settings.names[locale].monthLong[dateTime.getMonth()];

  // YEAR
  result.yearFull = dateTime.getFullYear().toString();
  result.year = result.yearFull.substr(-2);

  // TIME
  result.hour24 = dateTime.getHours();
  result.hour24Lead = `0${result.hour24}`.slice(-2);
  result.hour12 = result.hour24 > 12 ? result.hour24 - 12 : result.hour24;
  result.hour12Lead = `0${result.hour12}`.slice(-2);
  result.minute = dateTime.getMinutes();
  result.minuteLead = `0${result.minute}`.slice(-2);
  result.second = dateTime.getSeconds();
  result.secondLead = `0${result.second}`.slice(-2);
  result.meridiemLower = result.hour24 >= 12 ? 'pm' : 'am';
  result.meridiemUpper = result.meridiemLower.toUpperCase();

  const maps = [];
  Object.entries(settings.codes).forEach(x => {
    maps[`${wrap(x[1])}`] = result[x[0]];
  });

  return dateFormat.replace(/%(.*?)%/gi, n => maps[n]);
};

/**
 * Format given timestamp into specified time format
 *
 * @param   {?number=}      ts            The timestamp you wish to convert
 * @param   {!string=}      format        The date and time format
 * @param   {!string=}      locale        The prefered locale, `en` or `id`
 *
 * @return  {string}        The converted time, based on the given format
 */
export const formatTime = (ts = null, format = 'default', timezone = false, locale) => {
  const defaultTimeFormat = defaults.timeToDisplay;
  let timeFormat = format !== null ? format : defaultTimeFormat;
  timeFormat = timeFormat === 'default' ? defaultTimeFormat : timeFormat;

  return formatDate(ts, timeFormat, timezone, locale);
};

/**
 * Format given timestamp into specified date time format
 *
 * @param   {?number=}      ts            The timestamp you wish to convert
 * @param   {!string=}      separator     Separator that separate the date and the time
 * @param   {!string=}      format        The date and time format
 * @param   {!string=}      locale        The prefered locale, `en` or `id`
 *
 * @return  {string}        The converted date and time, based on the given format
 */
export const formatDateTime = (ts = null, separator = ' ', format = 'default', timezone = false, locale) => {
  const defaultFormat = `${defaults.dateToDisplay}${separator}${defaults.timeToDisplay}`;
  let dateTimeFormat = format !== null ? format : defaultFormat;
  dateTimeFormat = dateTimeFormat === 'default' ? defaultFormat : dateTimeFormat;

  return formatDate(ts, dateTimeFormat, timezone, locale);
};

/**
 * Convert timestamp to database format
 *
 * @param   {number}      ts       The timestamp you wish to convert
 *
 * @return  {string}      The result in yyyy-mm-dd format, eg. 2020-12-31
 */
export const formatDateToSave = ts => {
  return formatDate(ts, defaults.dateToSave);
};

/**
 * Convert date object to database format
 *
 * @param {string}      date      The date object you wish to convert
 *
 * @return {string}     The result in yyyy-mm-dd format, eg. 2020-12-31
 */
export const formatDateToSaveFromDateObject = date => {
  const timestamp = date.getTime();
  return formatDate(timestamp, defaults.dateToSave);
};

/**
 * Parse time string and convert it into a UTC-based timestamp. To parse it to local timezone, set the `tz` parameter to `local`.
 *
 * @param   {string}      strDate       String of date, e.g `2020-12-31 00:00:00`
 *
 * @return  {number} Timestamp of the given date
 */
export const parseTimeToTimestamp = strTime => {
  let timeStr = strTime;
  if (timeStr.length > 10) {
    timeStr = strTime;
  } else {
    timeStr = `2020-12-12 ${timeStr}`;
  }


  // Replace dash with slash, for safari support, https://stackoverflow.com/a/19244955
  timeStr = timeStr.replace(/-/g, '/');

  const date = new Date(`${timeStr}`);
  const utc = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

  return utc;
};

/**
 * Parse date string and convert it into a UTC-based timestamp. To parse it to local timezone, set the `tz` parameter to `local`.
 *
 * @param   {string}      strDate       String of date, e.g `2020-12-31 00:00:00`
 *
 * @return  {number} Timestamp of the given date
 */
export const parseTimestamp = strDate => {
  let dateStr = strDate === null ? '0000-00-00' : strDate;
  if (dateStr.length === 10) {
    dateStr = `${strDate} 00:00:00`;
  }

  // Replace dash with slash, for safari support, https://stackoverflow.com/a/19244955
  dateStr = dateStr.replace(/-/g, '/');

  const date = new Date(`${dateStr}`);
  const utc = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

  return utc;
};

/**
 * Get months within range of N months before today.
 *
 * @param   {number=}     month
 * @param   {boolean=}    useTodayAsFinalDate
 * @param   {string=}     attributeStart
 * @param   {string=}     attributeEnd
 *
 * @return  {Object}      Result
 */
export const monthsBeforeToday = (month = 6, useTodayAsFinalDate = false, attributeStart = 'start_date', attributeEnd = 'end_date') => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();

  const firstDay = new Date(y, m - (month - 1), 1);
  let lastDay = new Date(y, m + 1, 0);
  if (useTodayAsFinalDate) {
    lastDay = new Date();
  }

  const res = [];
  res[attributeStart] = formatDateToSave(firstDay.getTime());
  res[attributeEnd] = formatDateToSave(lastDay.getTime());

  return res;
};

export const mapDayName = date => {
  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth();
  const d = today.getDate();

  if (date === `${y}-${m}-${d}`) {
    return 'Today';
  }

  return `tgl${date}`;
};

// eslint-disable-next-line no-unused-vars
export const getAge = (strDate, asString = false, withDays = false) => {
  let endingDate = strDate;
  let startDate = new Date(new Date().toISOString().substr(0, 10));
  if (!endingDate) {
    endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
  }
  let endDate = new Date(endingDate);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();
  const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let yearDiff = endDate.getFullYear() - startYear;
  let monthDiff = endDate.getMonth() - startDate.getMonth();

  if (monthDiff < 0) {
    yearDiff -= 1;
    monthDiff += 12;
  }

  let dayDiff = endDate.getDate() - startDate.getDate() + 1;
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff -= 1;
    } else {
      yearDiff -= 1;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  if (withDays) {
    if (asString) {
      const messages = [];
      if (yearDiff >= 1) messages.push(`${yearDiff} year${yearDiff >= 2 ? 's' : ''}`);
      if (monthDiff >= 1) messages.push(`${monthDiff} month${monthDiff >= 2 ? 's' : ''}`);
      if (dayDiff >= 1) messages.push(`${dayDiff} day${dayDiff >= 2 ? 's' : ''}`);
      return messages.join(' ');
    }

    return { years: yearDiff, months: monthDiff, days: dayDiff };
  }

  if (asString) return `${yearDiff} year${yearDiff >= 2 ? 's' : ''}`;
  return yearDiff;
};

export default {
  defaults,
  eq,
  wrap,
  prepend,
  formatDate,
  formatDateWithoutTZ,
  formatTime,
  formatDateTime,
  formatDateToSave,
  formatDateToSaveFromDateObject,
  parseTimestamp,
  parseTimeToTimestamp,
  monthsBeforeToday,
  mapDayName,
  getAge,
  getYears,
  convertIntToDay,
  convertDayToInt,
  convertMonth,
  convertTZ,
};
