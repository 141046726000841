const formMixin = {
  data() {
    return {
      validationErrors: {},
    };
  },
  methods: {
    isFieldError(errors, fieldName = null) {
      if (fieldName === null || fieldName === '') return false || (errors && errors[0]);

      const names = [];
      if (!Array.isArray(fieldName)) {
        names.push(fieldName);
      }

      if (this.validationErrors) {
        // Only if it is a single entry
        if (names.length === 1) {
          if (Object.prototype.hasOwnProperty.call(this.validationErrors, names[0])) return true;
        }

        // If it contain more than 1 entry
        let i = 0;
        let hasOne = false;
        while (!hasOne && i <= names.length - 1) {
          if (Object.prototype.hasOwnProperty.call(this.validationErrors, names[i])) hasOne = true;
          i += 1;
        }

        if (hasOne) return true || (errors && errors[0]);
      }

      return false || (errors && errors[0]);
    },
    fieldError(errors = null) {
      return { client: errors, server: this.validationErrors };
    },
  },
};

export default formMixin;
