import r from '~/utils/render-utils';

const ManageCompanyIndex = () => import(/* webpackChunkName: 'admin_manage_company_index' */ '~/pages/admin/manage-company/Index');
const ManageCompanyList = () => import(/* webpackChunkName: 'admin_manage_company_list' */ '~/pages/admin/manage-company/List');
const ManageCompanyCreate = () => import(/* webpackChunkName: 'admin_manage_company_create' */ '~/pages/admin/manage-company/CreateCompany');
const ManageCompanyEdit = () => import(/* webpackChunkName: 'admin_manage_company_edit' */ '~/pages/admin/manage-company/EditCompany');
const ManageCompanyClone = () => import(/* webpackChunkName: 'admin_manage_company_clone' */ '~/pages/admin/manage-company/CloneCompany');

export default {
  path: 'manage-company',
  component: r(ManageCompanyIndex),
  children: [
    {
      path: '',
      name: 'admin_manage_company_list',
      component: r(ManageCompanyList),
    },
    {
      path: 'new',
      name: 'admin_manage_company_create',
      component: r(ManageCompanyCreate),
    },
    {
      path: 'edit/:id(\\d+)',
      name: 'admin_manage_company_edit',
      component: r(ManageCompanyEdit),
    },
    {
      path: 'clone/:id(\\d+)',
      name: 'admin_manage_company_clone',
      component: r(ManageCompanyClone),
    },
  ],
};
