import ApiClient from '~/api';

const initialState = {
  departments: [],
  departmentsMeta: {},
  categories: [],
  categoriesMeta: {},
  positions: [],
  positionsMeta: {},
  employementTypes: [],
  employmentPeriods: [],
};

const getters = {
  departments: state => state.departments,
  departmentsMeta: state => state.departmentsMeta,
  categories: state => state.categories,
  categoriesMeta: state => state.categoriesMeta,
  positions: state => state.positions,
  positionsMeta: state => state.positionsMeta,
  employementTypes: state => state.employementTypes,
  employmentPeriods: state => state.employmentPeriods,
};

const actions = {
  // Departments
  async fetchDepartments({ commit, dispatch }, query) {
    try {
      const { data, meta } = await ApiClient.v1.department.getDepartments(query);
      commit('setDepartments', data);
      commit('setDepartmentsMeta', meta);

      return data;
    } catch (e) {
      commit('setDepartments', []);
      commit('setDepartmentsMeta', {});

      const caption = 'Unable to load departments list';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async fetchDepartment({ dispatch }, id) {
    try {
      const { data } = await ApiClient.v1.department.getDepartment(id);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load department information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchDepartmentSalaryRangeByPositionId({ dispatch }, id) {
    try {
      const { data } = await ApiClient.v1.department.getPositionSalaryRange(id);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load salary range information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async createDepartment({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.department.createDepartment(formData);
      commit('setDepartment', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateDepartment({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.department.updateDepartment(id, formData);
      commit('updateDepartment', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update department information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteDepartment({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.department.deleteDepartment(id);
      commit('deleteDepartment', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete department';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Categories
  async fetchCategories({ commit, dispatch }, query) {
    try {
      const { data, meta } = await ApiClient.v1.department.getCategories(query);
      commit('setCategories', data);
      commit('setCategoriesMeta', meta);

      return data;
    } catch (e) {
      commit('setCategories', []);
      commit('setCategoriesMeta', {});

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },

  async createCategory({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.department.createCategory(formData);
      commit('setCategory', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateCategory({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.department.updateCategory(id, formData);
      commit('updateCategory', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }
      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteCategory({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.department.deleteCategory(id);
      commit('deleteCategory', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Positions
  async fetchPositions({ commit, dispatch }, { query, dataFilter, customCompanyId } = {}) {
    try {
      let data = [];
      let meta = {};

      if (dataFilter && dataFilter.by && dataFilter.value) {
        if (dataFilter.by === 'department') {
          ({ data, meta } = await ApiClient.v1.department.getDepartmentsPositions(dataFilter.value, query));
        } else if (dataFilter.by === 'job-category') {
          ({ data, meta } = await ApiClient.v1.department.getCategorysPositions(dataFilter.value, query));
        }
      } else {
        ({ data, meta } = await ApiClient.v1.department.getPositions(query, customCompanyId));
      }

      commit('setPositions', data);
      commit('setPositionsMeta', meta);

      return data;
    } catch (e) {
      commit('setPositions', []);
      commit('setPositionsMeta', {});

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  fetchPositionById(_, id) {
    return ApiClient.v1.department.getPosition(id);
  },
  async createPosition({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.department.createPosition(formData);
      commit('setPosition', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updatePosition({ commit, dispatch }, { id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.department.updatePosition(id, formData);
      if (data) commit('updatePosition', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deletePosition({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.department.deletePosition(id);
      commit('deletePosition', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Employment Types
  async fetchEmploymentTypes({ commit }, { query, customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.department.getEmploymentTypes(query,customCompanyId);
      commit('setEmploymentTypes', data);

      return data;
    } catch (e) {
      commit('setEmploymentTypes', []);

      return [];
    }
  },

  // Employment Periods
  async fetchEmploymentPeriods({ commit }, { query, customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.department.getEmploymentPeriods(query, customCompanyId);
      commit('setEmploymentPeriods', data);

      return data;
    } catch (e) {
      commit('setEmploymentPeriods', []);
      return [];
    }
  },
};

const mutations = {
  // Departments
  setDepartments(state, data) {
    state.departments = data;
  },
  setDepartmentsMeta(state, data) {
    state.departmentsMeta = data;
  },
  setDepartment(state, data) {
    state.departments.splice(0, 0, data); // Add to the beginning of the list
  },
  updateDepartment(state, { idx, data }) {
    state.departments.splice(idx, 1, data);
  },
  deleteDepartment(state, idx) {
    state.departments.splice(idx, 1);
  },

  // Categories
  setCategories(state, data) {
    state.categories = data;
  },
  setCategoriesMeta(state, data) {
    state.categoriesMeta = data;
  },
  setCategory(state, data) {
    state.categories.splice(0, 0, data); // Add to the beginning of the list
  },
  updateCategory(state, { idx, data }) {
    state.categories.splice(idx, 1, data);
  },
  deleteCategory(state, idx) {
    state.categories.splice(idx, 1);
  },

  // Positions
  setPositions(state, data) {
    state.positions = data;
  },
  setPositionsMeta(state, data) {
    state.positionsMeta = data;
  },
  setPosition(state, data) {
    state.positions.splice(0, 0, data); // Add to the beginning of the list
  },
  updatePosition(state, { idx, data }) {
    state.positions.splice(idx, 1, data);
  },
  deletePosition(state, idx) {
    state.positions.splice(idx, 1);
  },

  // Employment Types
  setEmploymentTypes(state, data) {
    state.employementTypes = data;
  },
  setEmploymentType(state, data) {
    state.employementTypes.splice(0, 0, data); // Add to the beginning of the list
  },

  // Employment Periods
  setEmploymentPeriods(state, data) {
    state.employmentPeriods = data;
  },
  setEmploymentPeriod(state, data) {
    state.employmentPeriods.splice(0, 0, data); // Add to the beginning of the list
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
