import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_setting_index' */ '~/pages/admin/setting/Index');
const SettingEmployee = () => import(/* webpackChunkName: 'admin_setting_employee' */ '~/pages/admin/setting/Employee');
const SettingExpense = () => import(/* webpackChunkName: 'admin_setting_employee' */ '~/pages/admin/setting/Expense');
const SettingLeave = () => import(/* webpackChunkName: 'admin_setting_employee' */ '~/pages/admin/setting/Leave');
const SettingUser = () => import(/* webpackChunkName: 'admin_setting_user' */ '~/pages/admin/setting/User');
const SettingCompany = () => import(/* webpackChunkName: 'admin_setting_company' */ '~/pages/admin/setting/Company');
const SettingCurrency = () => import(/* webpackChunkName: 'admin_setting_currency' */ '~/pages/admin/setting/Currency');
const SettingBusinessTrip = () => import(/* webpackChunkName: 'admin_setting_business_trip' */ '~/pages/admin/setting/BusinessTrip');
const SettingAttendance = () => import(/* webpackChunkName: 'admin_setting_attendance' */ '~/pages/admin/setting/Attendance');

const SettingHolidayEventIndex = () => import(/* webpackChunkName: 'admin_setting_holiday_event' */ '~/pages/admin/setting/holiday-event/Index');
const SettingHolidayEventList = () => import(/* webpackChunkName: 'admin_setting_holiday_event_list' */ '~/pages/admin/setting/holiday-event/List');
const SettingHolidayCreate = () => import(/* webpackChunkName: 'admin_setting_holiday_new' */ '~/pages/admin/setting/holiday-event/HolidayCreate');
const SettingHolidayUpdate = () => import(/* webpackChunkName: 'admin_setting_holiday_update' */ '~/pages/admin/setting/holiday-event/HolidayUpdate');
const SettingEventCreate = () => import(/* webpackChunkName: 'admin_setting_event_new' */ '~/pages/admin/setting/holiday-event/EventCreate');
const SettingEventUpdate = () => import(/* webpackChunkName: 'admin_setting_event_update' */ '~/pages/admin/setting/holiday-event/EventUpdate');

const SettingPayrollIndex = () => import(/* webpackChunkName: 'admin_setting_payroll_index' */ 'pages/admin/setting/payroll/Index');
const SettingPayrollGeneralSetting = () => import(/* webpackChunkName: 'admin_setting_payroll_general_setting' */ 'pages/admin/setting/payroll/GeneralSetting');

export default {
  path: 'setting',
  component: r(Index),
  children: [
    {
      path: '',
      redirect: '/admin/setting/employee',
      name: 'admin_setting_index',
    },
    {
      path: 'company',
      name: 'admin_setting_company',
      component: r(SettingCompany),
    },
    {
      path: 'currency',
      name: 'admin_setting_currency',
      component: r(SettingCurrency),
    },
    {
      path: 'employee',
      name: 'admin_setting_employee',
      component: r(SettingEmployee),
    },
    {
      path: 'expense',
      name: 'admin_setting_expense',
      component: r(SettingExpense),
    },
    {
      path: 'leave',
      name: 'admin_setting_leave',
      component: r(SettingLeave),
    },
    {
      path: 'user',
      name: 'admin_setting_user',
      component: r(SettingUser),
    },
    {
      path: 'holiday-and-event',
      component: r(SettingHolidayEventIndex),
      children: [
        { path: '', component: r(SettingHolidayEventList), name: 'admin_setting_holiday_event_list' },
        { path: 'holiday/new', component: r(SettingHolidayCreate), name: 'admin_setting_holiday_new' },
        { path: 'holiday/:id(\\d+)', component: r(SettingHolidayUpdate), name: 'admin_setting_holiday_update' },
        { path: 'event/new', component: r(SettingEventCreate), name: 'admin_setting_event_new' },
        { path: 'event/:id(\\d+)', component: r(SettingEventUpdate), name: 'admin_setting_event_update' },
      ],
    },
    {
      path: 'payroll',
      component: r(SettingPayrollIndex),
      children: [
        {
          path: '',
          name: 'admin_setting_payroll',
          redirect: '/admin/setting/payroll/general-setting',
        },
        {
          path: 'general-setting',
          name: 'admin_setting_payroll_general_setting',
          component: r(SettingPayrollGeneralSetting),
        },
      ],
    },
    {
      path: 'business-travel',
      name: 'admin_setting_business_trip',
      component: r(SettingBusinessTrip),
    },
    {
      path: 'attendance',
      name: 'admin_setting_attendance',
      component: r(SettingAttendance),
    },
  ],
};
