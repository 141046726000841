import r from '~/utils/render-utils';

/*
 * Documentation can be found here, https://router.vuejs.org/.
 * Meanwhile, the example can be found here, https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js
 */

// const PageRedirector = () => import(/* webpackChunkName: 'redirector' */ '~/pages/PageRedirector');
const PageStyleguide = () => import(/* webpackChunkName: 'styleguide' */ '~/pages/PageStyleguide');
const PageRoutes = () => import(/* webpackChunkName: 'redirector' */ '~/pages/PageRoutes');
const PageNotFound = () => import(/* webpackChunkName: 'redirector' */ '~/pages/PageNotFound');

const InternalRoutes = [
  { path: '/styleguide', component: r(PageStyleguide), name: 'styleguide' },
  { path: '/routes', component: r(PageRoutes), name: 'routes' },
];

const other = [
  // { path: '*', component: r(PageRedirector), name: 'redirector_page' },

  ...(process.env.NODE_ENV === 'development' ? InternalRoutes : []),
  // { path: '*', component: r(PageNotFound), name: 'not_found' },
];

export default other;
