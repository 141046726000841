//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixin from '~/mixins/form';

export default {
  name: 'CWidgetChangePassword',
  mixins: [FormMixin],
  data() {
    return {
      isSubmitting: false,
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;

        const res = await this.$store.dispatch('user/changePassword', this.form);

        // If updated successfully
        if (res.message === 'Created' || res.message === 'Success') {
          this.close();
          await this.$swal({
            titleText: 'Password Changed',
            text: 'Password changed successfully. You will be redirected to the login page.',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: false,
            customClass: {
              popup: 'swal2-popup--sm',
              confirmButton: 'swal2-confirm--sm swal2-confirm--block',
            },
          });
          this.$router.push('/logout');
        }
      } catch (e) {
        if (
          e.response &&
          e.response.status &&
          (e.response.status === 400 || e.response.status === 422) &&
          e.response.data &&
          e.response.data.errors
        ) {
          this.validationErrors = e.response.data.errors;
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
