import { getErrorMessage } from '~/helpers/error';

const initialState = {
  error: null,
  isErrorGps: false,
};
const getters = {
  error: state => state.error,
  isErrorGps: state => state.isErrorGps,
};

const actions = {
  catchError({ dispatch }, { error, type = 'error', caption = null, message = null, opts = {} }) {
    if (process.env.NODE_ENV === 'development') console.error(error);
    const translatedError = getErrorMessage(error, message, caption);
    const params = {
      error: translatedError,
      type,
      opts,
    };
    dispatch('alert/showMessage', params, { root: true });
  },
  displayError({ commit }, value) {
    commit('setError', value);
  },
  clearError({ commit }) {
    commit('setError', null);
  },
};

const mutations = {
  setError(state, value) {
    state.error = value;
  },
  setIsErrorGps(state, value) {
    state.isErrorGps = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
