//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CModal',
  props: {
    size: {
      type: String,
      default: 'standard',
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    failedCloseAnimation: {
      type: Boolean,
      default: false,
    },
    clickOutside: {
      type: Boolean,
      default: true,
    },
    classNames: {
      type: Object,
      default() {
        return {
          body: '',
          header: '',
          footer: '',
        };
      },
    },
  },
  data() {
    return {
      state: '',
    };
  },
  computed: {
    containerStyle() {
      return {
        '': this.size === 'standard',
        'modal--small': this.size === 'small',
        'modal--super-small': this.size === 'xs',
        'modal--wide': this.size === 'wide',
        show: this.state === 'modal.open',
        hide: this.state === 'modal.close',
      };
    },
  },
  beforeMount() {
    document.body.classList.add('modal-open');
  },
  mounted() {
    this.state = 'modal.open';
  },
  beforeDestroy() {},
  destroyed() {
    document.body.classList.remove('modal-open');
  },
  methods: {
    close(source) {
      console.info('Closed triggered from CModal', source);
      if (source === 'outside' && this.clickOutside === false) {
        //
      } else if (this.closeable) {
        this.state = 'modal.close';
        setTimeout(() => {
          this.$emit('close');
        }, 150);
      } else if (this.failedCloseAnimation) {
        this.state = 'modal.close';
        setTimeout(() => {
          this.state = 'modal.open';
        }, 150);
      }
    },
  },
};
