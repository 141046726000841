import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  getPunchs(uuid, queries, cts = null) {
    return this.get(`/api/v1/employees/${uuid}/attendances${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getReports(queries, cts = null) {
    return this.get(`/api/v1/attendances-report${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getEmployees(queries, cts = null) {
    return this.get(`/api/v1/attendances-list${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getPunchStatus(uuid, cts = null) {
    return this.get(`/api/v1/employees/${uuid}/attendances-available-punch`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getAttendanceWidget(uuid, queries, cts = null) {
    return this.get(`/api/v1/employees/${uuid}/attendances-widget${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getAttendance(uuid, queries) {
    return this.get(`/api/v1/employees/${uuid}/attendances-list${objectToQueryString(queries)}`, 'user');
    // return JSON.parse('{"data":[{"id":1,"isDayOff":false,"punch_date":"2021-02-23","in":{"isLate":true,"actual":"08:01","status":"Late 1 Minute","scheduled":"08:00","scheduledGrace":"08:01"},"out":{"isEarly":true,"actual":"16:59","status":"Early 1 Minute","scheduled":"16:00","scheduledGrace":"16:00"},"note":{"title":"-","message":"this is message"},"isTakingOff":true,"lastUpdate":"2021-02-23 23:00","lastUpdater":{"nama":"Achmad Saiful"}}]}');
  },
  getCurrentAttendance(uuid) {
    return this.get(`/api/v1/employees/${uuid}/current-attendances`, 'user');
  },
  getAttendanceById(uuid, id) {
    return this.get(`/api/v1/employees/${uuid}/attendances-list/${id}`, 'user');
  },
  updateAttendance(uuid, id, formData) {
    return this.put(`/api/v1/employees/${uuid}/attendances-list/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateCurrentAttendance(formData) {
    return this.post(`/api/v1/current-attendances`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateAttendanceByEmployee(uuid, id, formData) {
    return this.put(`/api/v1/employees/${uuid}/attendances-list/${id}/add-note`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  submitPunch(uuid, formData) {
    return this.post(`/api/v1/employees/${uuid}/attendances`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getAttendanceChart(queries) {
    const allowed = ['employee_id', 'start_date', 'end_date'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/attendances/monthly${filteredQs}`, 'user');
  },
  getAttendanceStatus() {
    return this.get(`/api/v1/attendances/status`, 'user');
  },
  getResyncs(queries, cts = null) {
    return this.get(`/api/v1/resync${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getResyncStatus() {
    return this.get(`/api/v1/attendances/status`, 'user');
  },
  getResyncById(id) {
    return this.get(`/api/v1/resync/${id}`, 'user');
  },
  getResyncEmployeesById(id, queries = '', cts = null) {
    return this.get(`/api/v1/resync/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getResyncHistoryById(id) {
    return this.get(`/api/v1/resync/${id}/history`, 'user');
  },
  createResyncAttendance(formData) {
    return this.post(`/api/v1/resync`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getExports(queries, cts = null) {
    return this.get(`/api/v1/exports${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportById(id) {
    return this.get(`/api/v1/exports/${id}`, 'user');
  },
  getExportEmployeesById(id, queries = '', cts = null) {
    return this.get(`/api/v1/exports/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportHistoryById(id) {
    return this.get(`/api/v1/exports/${id}/history`, 'user');
  },
  exportAttendance(formData) {
    return this.post(`/api/v1/exports`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getExportAtt(queries, cts = null) {
    return this.get(`/api/v1/export-attendances${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportAttById(id) {
    return this.get(`/api/v1/export-attendances/${id}`, 'user');
  },
  getExportAttEmployeesById(id, queries = '', cts = null) {
    return this.get(`/api/v1/export-attendances/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportAttHistoryById(id) {
    return this.get(`/api/v1/export-attendances/${id}/history`, 'user');
  },
  exportAtt(formData) {
    return this.post(`/api/v1/export-attendances`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  getSettings() {
    return this.get(`/api/v1/attendances-settings`, 'user');
  },
  saveSettings(formData) {
    return this.patch('/api/v1/attendances-settings', 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
};
