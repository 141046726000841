import r from '~/utils/render-utils';

const PageLogin = () => import(/* webpackChunkName: 'login' */ '~/pages/PageLogin');
const PageAuth = () => import(/* webpackChunkName: 'auth' */ '~/pages/PageAuth');
const PageLogout = () => import(/* webpackChunkName: 'logout' */ '~/pages/PageLogout');
const PageForgotPassword = () => import(/* webpackChunkName: 'logout' */ '~/pages/PageForgotPassword');
const PageResetPassword = () => import(/* webpackChunkName: 'logout' */ '~/pages/PageResetPassword');

const general = [
  { path: '/login', component: r(PageLogin), name: 'login' },
  { path: '/auth', component: r(PageAuth), name: 'auth' },
  { path: '/logout', component: r(PageLogout), name: 'logout' },
  { path: '/forgot-password', component: r(PageForgotPassword), name: 'forgot_password' },
  { path: '/reset-password', component: r(PageResetPassword), name: 'reset_password' },
];

export default general;
