//
//
//
//
//
//
//
//

export default {
  name: 'CFormLabel',
  props: {
    id: {
      type: String,
      default: null,
    },
    required: {
      type: [Boolean, Number],
      default: false,
    },
    label: {
      type: String,
      default: 'Label',
    },
  },
};
