import r from '~/utils/render-utils';

const AccessIndex = () => import(/* webpackChunkName: 'admin_access_index' */ '~/pages/admin/access/Index');
const AccessList = () => import(/* webpackChunkName: 'admin_access_list' */ '~/pages/admin/access/List');
const AccessPermission = () => import(/* webpackChunkName: 'admin_access_permission' */ '~/pages/admin/access/permission/List');

const AccessRoleIndex = () => import(/* webpackChunkName: 'admin_access_role_index' */ '~/pages/admin/access/role/Index');
const AccessRoleList = () => import(/* webpackChunkName: 'admin_access_role_list' */ '~/pages/admin/access/role/List');
const AccessRoleCreate = () => import(/* webpackChunkName: 'admin_access_role_create' */ '~/pages/admin/access/role/Create');
const AccessRoleUpdate = () => import(/* webpackChunkName: 'admin_access_role_update' */ '~/pages/admin/access/role/Update');
const AccessRoleDetail = () => import(/* webpackChunkName: 'admin_access_role_detail' */ '~/pages/admin/access/role/Detail');

export default {
  path: 'access',
  component: r(AccessIndex),
  children: [
    { path: '', redirect: '/admin/access/role', name: 'admin_access_index' },
    // {
    //   path: 'list',
    //   name: 'admin_access_list',
    //   component: r(AccessList),
    // },
    {
      path: 'role',
      component: r(AccessRoleIndex),
      children: [
        { path: '', name: 'admin_access_role_list', component: r(AccessRoleList) },
        { path: 'new', name: 'admin_access_role_create', component: r(AccessRoleCreate) },
        { path: 'view/:name', name: 'admin_access_role_detail', component: r(AccessRoleDetail) },
        { path: ':name', name: 'admin_access_role_update', component: r(AccessRoleUpdate) },
      ],
    },
    {
      path: 'permission',
      name: 'admin_access_permission',
      component: r(AccessPermission),
    },
  ],
};
