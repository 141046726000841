const initialState = {
  errors: [],
};

const getters = {
  errors: state => state.errors,
};

const actions = {};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
