const envCustom = {
  data() {
    return {
      featureToggle: [],
      enableLDAPFeature: false,
      useAvatar: false,
    };
  },
  created() {
    this.enableLDAPFeature = this.isActiveFeatureToggle('ldap-feature');
    this.loadUseAvatar();
  },
  methods: {
    isActiveFeatureToggle(feature) {
      this.loadEnvCustom();
      return this.featureToggle.includes(feature);
    },
    loadEnvCustom() {
      if (process.env.FEATURE_TOGGLE) {
        this.featureToggle = process.env.FEATURE_TOGGLE.split('; ');
      }
    },
    loadUseAvatar() {
      if (process.env.USE_AVATAR) {
        this.useAvatar = process.env.USE_AVATAR;
      }
    },
  },
};

export default envCustom;
