class ApiOptions {
  constructor(additionalOptions = null) {
    const options = {
      responseErrorJson: true,
    };

    if (additionalOptions) {
      const { requestPayload, requestHeaders, requestLimit, responseErrorJson, cancelTokenSource, customCompanyId } = additionalOptions;
      if (requestHeaders) options.headers = requestHeaders;
      if (requestPayload) options.data = requestPayload;
      if (responseErrorJson === false) options.responseErrorJson = responseErrorJson;
      options.requestLimit = requestLimit;

      if (cancelTokenSource) options.cancelTokenSource = cancelTokenSource;

      if (customCompanyId) options.customCompanyId = customCompanyId;
    }

    this.options = options;
    return this.options;
  }
}

export default ApiOptions;
