const initialState = {
  timezone: 'UTC',
};

const getters = {
  timezone: state => state.timezone,
};

const actions = {
  async fetchTimezone({ commit }) {
    if (localStorage.getItem('timezone') === null) {
      const res = await new Promise(resolve => {
        resolve('Asia/Jakarta');
      });
      localStorage.setItem('timezone', res);
    }

    commit('setTimezone', localStorage.getItem('timezone'));
  },
};

const mutations = {
  setTimezone(state, value) {
    state.timezone = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
