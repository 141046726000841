//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import assetsMixin from '~/mixins/assets';

export default {
  name: 'CLogo',
  mixins: [assetsMixin],
  props: {
    isDefault: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'icon',
    },
  },
  data() {
    return {
      srcLogo: '/images/logo.png',
      srcIcon: '/images/logo-icon.png',
    };
  },
  watch: {
    company: {
      async handler(newValue) {
        await this.fetchLogo();
        await this.fetchIcon();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      company: 'company/selectedCompany',
    }),
  },
  methods: {
    async fetchLogo() {
      if (!this.isDefault && this.company && this.company.company_logo) {
        this.srcLogo = await this.getAvatarUrl(this.company.company_logo);
      }
    },
    async fetchIcon() {
      if (!this.isDefault && this.company && this.company.company_icon) {
        this.srcIcon = await this.getAvatarUrl(this.company.company_icon);
      }
    },
  },
};
