import { whitelistedPages } from '~/helpers/role-helper';
import { checkAccess } from '~/helpers/permissions';

const printMessage = message => {
  // if (process.env.NODE_ENV === 'development') console.info(message);
};

export default async function ({ route, store }) {
  const matched = [...route.matched].pop();

  if (matched && matched.name) {
    if (whitelistedPages.includes(matched.name)) return true;
    const p = await store.dispatch('permission/getPermissions');
    const r = await store.dispatch('permission/getRoutes');
    const roles = await store.dispatch('permission/getUserRoles');
    const result = await checkAccess(matched.name, p, r, roles);

    if (result) return true;

    printMessage(`Access denied for ${matched.name}`);
    store.$router.push({ name: 'user_home' });
    return false;
  }

  return true;
}
