import { render, staticRenderFns } from "./CToast2.vue?vue&type=template&id=19bf81b0&scoped=true&"
import script from "./CToast2.vue?vue&type=script&lang=js&"
export * from "./CToast2.vue?vue&type=script&lang=js&"
import style0 from "./CToast2.vue?vue&type=style&index=0&id=19bf81b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19bf81b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CGpsToast: require('/home/web/alpha-fe/components/toast/CGpsToast.vue').default,CEmployeeTransferToast: require('/home/web/alpha-fe/components/page/employee/transfer/CEmployeeTransferToast.vue').default})
