//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'CGpsToast',
  data() {
    return {
      isShowToast: false,
    };
  },
  watch: {
    isErrorGps(val) {
      console.info(val);
      this.isShowToast = val;
    },
  },
  computed: {
    ...mapGetters({
      isErrorGps: 'error/isErrorGps',
    }),
  },
};
