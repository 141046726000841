/**
 * @module helpers/url-helpers
 */

export function objectToQueryString(params = {}) {
  const url = Object.keys(params)
    .map(key => {
      if (params[key] === null) return `${key}=`;
      return `${key}=${params[key]}`;
    })
    .join('&');

  if (url !== '') return `?${url}`;
  return url;
}

export function serialize(obj, prefix) {
  const str = [];

  Object.keys(obj).forEach(p => {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push(v !== null && typeof v === 'object' ? serialize(v, k) : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  });

  return str.join('&');
}

export function validateObject(needles = [], stacks = [], convertToQueryString = false) {
  if (stacks[0] === '*') {
    if (convertToQueryString) return objectToQueryString(needles);
    return needles;
  }

  const result = Object.keys(needles)
    .filter(key => stacks.includes(key))
    .reduce((obj, key) => {
      obj[key] = needles[key];
      return obj;
    }, {});

  if (convertToQueryString) return objectToQueryString(result);
  return result;
  
  
}

export default {
  objectToQueryString,
  serialize,
  validateObject,
};
