//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CSidebarGroup',
  props: {
    links: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: '',
    },
  },
};
