//
//
//
//
//
//
//

export default {
  name: 'CToast2',
};
