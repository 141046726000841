import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_job_category_index' */ '~/pages/admin/job_category/Index');
const List = () => import(/* webpackChunkName: 'admin_job_category_list' */ '~/pages/admin/job_category/List');
const Detail = () => import(/* webpackChunkName: 'admin_job_category_detail' */ '~/pages/admin/job_category/Detail');
const DetailDepartment = () => import(/* webpackChunkName: 'admin_job_category_detail_position' */ '~/pages/admin/job_category/DetailPosition');
const DetailEmployee = () => import(/* webpackChunkName: 'admin_job_category_detail_employee' */ '~/pages/admin/job_category/DetailEmployee');

const routes = {
  path: 'employee/job-category',
  component: r(Index),
  children: [
    {
      path: '',
      component: r(List),
      name: 'admin_jobcategory_index',
    },
    {
      path: ':id(\\d+)',
      component: r(Detail),
      children: [
        {
          path: '',
          redirect: '/admin/job-category/:id/employees',
          name: 'admin_jobcategory_view',
        },
        {
          path: 'employees',
          name: 'admin_job_category_view_employee',
          component: r(DetailEmployee),
        },
        {
          path: 'positions',
          name: 'admin_job_category_view_position',
          component: r(DetailDepartment),
        },
      ],
    },
  ],
};

// old routes
const redirect = {
  path: 'job-category',
  children: [
    { path: '', name: 'admin_jobcategory_index_old', redirect: { name: 'admin_jobcategory_index' } },
    {
      path: ':id(\\d+)',
      children: [
        { path: '', name: 'admin_jobcategory_view_old', redirect: { name: 'admin_jobcategory_view' } },
        { path: 'employees', name: 'admin_job_category_view_employee_old', redirect: { name: 'admin_job_category_view_employee' } },
        { path: 'positions', name: 'admin_job_category_view_position_old', redirect: { name: 'admin_job_category_view_position' } },
      ],
    },
  ],
};

export default [
  routes,
  redirect,
];
