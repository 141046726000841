import ApiOptions from '../api-options';
import { serialize, validateObject } from '~/helpers/url-helper';

export default {
  getDocuments(formData) {
    const qs = serialize(formData);
    return this.get(`/api/v1/documents?${qs}`, 'user');
  },
  getDocumentsFile(filename) {
    const options = new ApiOptions({
      responseErrorJson: false,
      requestHeaders: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': 'attachment',
        Accept: '*/*',
      },
    });
    return this.get(`/api/v1/documents/file/${filename}`, 'user', options);
  },
  getDocumentsFileGeneral(filename) {
    const options = new ApiOptions({
      responseErrorJson: false,
      requestHeaders: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': 'attachment',
        Accept: '*/*',
      },
    });
    return this.get(`/api/v1/documents/file/for-employee/${filename}`, 'user', options);
  },
  uploadDocument(formData) {
    const options = new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return this.post(`/api/v1/documents`, 'user', options);
  },
  deleteDocument(id, formData) {
    const allowed = ['employee_id', 'usage', 'relevant_usage_id'];
    const filtered = validateObject(formData, allowed);
    const options = new ApiOptions({
      requestPayload: filtered,
      responseErrorJson: false,
    });
    return this.delete(`/api/v1/documents/${id}`, 'user', options);
  },
  getStorageSize() {
    return this.get(`/api/v1/documents/storage`, 'user');
  },
  resyncDocuments(uuid, formData) {
    return this.post(`/api/v1/employees/${uuid}/files`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
};
