/* eslint-disable no-unused-vars */
import { setClient } from '~/api';

export default function({ app, $axios, redirect }, inject) {
  setClient(app.$axios);

  $axios.onRequest(config => {
    // if (process.env.NODE_ENV === 'development') console.info(`[NUXT AXIOS] Making request to ${config.url}`);

    if (config.url === '/oauth/token') {
      const m = new Date();
      const date = `${m.getFullYear()}-${`0${m.getMonth() + 1}`.slice(-2)}-${`0${m.getDate()}`.slice(-2)} ${`0${m.getHours()}`.slice(
        -2,
      )}:${`0${m.getMinutes()}`.slice(-2)}:${`0${m.getSeconds()}`.slice(-2)}`;
      localStorage.setItem('time_refresh', date);
    }

    // if (localStorage.getItem('auth._token.local') && localStorage.getItem('auth._token.local') !== 'false') {
    //    /* eslint-disable dot-notation */
    //    config.headers['Authorization'] = localStorage.getItem('auth._token.local');
    // }

    // return config;
  });

  // $axios.onError(error => {
  //   const code = parseInt(error.response && error.response.status);
  //   if (code === 400) {
  //     redirect("/400");
  //   }
  // });
}
