//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import assetsMixin from 'mixins/assets';
import VueClickaway from 'vue-clickaway';
// import Multiselect from 'vue-multiselect';
import envCustom from '~/mixins/env-custom';

export default {
  name: 'Topbar',
  // components: { Multiselect },
  mixins: [VueClickaway.mixin, envCustom, assetsMixin],
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },

  fetch() {
    this.fetchAvatarUrl();
  },

  data() {
    return {
      show: null,
      showChangePasswordForm: false,
      enableNotificationFeature: false,
      avatarUrl: null,
      myCompany: {},
      isLoadingSwitch: false,

      roles: [],
    };
  },
  watch: {
    '$auth.user.avatar_url': function() {
      this.fetchAvatarUrl();
    },
  },
  computed: {
    ...mapGetters({
      companyList: 'company/activeCompany',
    }),
    isOnlyEmployee() {
      if (this.roles.length === 1 && this.roles[0] === 'employee') return true;
      return false;
    },
    checkRoleAuthorization() {
      if (['admin', 'admin-companies'].some(r => this.roles.includes(r))) return true;
      return false;
    },
    showSwitchCompany() {
      if (this.companyList && this.companyList.length) {
        // jika hanya employee dan punya 1 main company saja maka false
        if (this.isOnlyEmployee && this.companyList.length <= 1) return false;
        return true;
      }

      return false;
    },
  },
  async created() {
    try {
      this.roles = await this.$store.dispatch('permission/getUserRoles');

      await this.$store.dispatch('company/fetchCompanyByEmployee', {
        uuid: this.$auth.user.uuid,
        query: { status: 1 },
      });

      const selectedCompany = await this.$store.dispatch('company/getSelectedCompany');
      if (selectedCompany) {
        // to always update selected company in localstorage 
        const company = this.companyList.find(x => x.id === selectedCompany.id);
        this.myCompany = company;
        await this.$store.dispatch('company/setSelectedCompany', company);
      }

      if (!selectedCompany && this.companyList && this.companyList.length) {
        this.myCompany = this.companyList[0];
        await this.$store.dispatch('company/setSelectedCompany', this.companyList[0]);
      }

      this.enableNotificationFeature = this.isActiveFeatureToggle('user-notification');
    } catch (e) {
      this.$store.dispatch('error/catchError', { error: e, caption: 'Unable to Load Data' });
    }
  },
  methods: {
    goTo(path) {
      if (this.$route.path !== path) this.$router.push(path);
      this.$emit('click');
    },
    reset() {
      this.show = null;
    },
    async fetchAvatarUrl() {
      this.avatarUrl = await this.getAvatarUrl(this.$auth.user.avatar_url, true, this.$auth.user.gender);
    },
    async setCompany(company) {
      await this.$store.dispatch('company/setSelectedCompany', company);
      this.myCompany = company;
      this.show = null;
      // window.location = window.location.pathname;
      window.location = '/';
    },
  },
};
