const companyMixin = {
  methods: {
    getInitials(data) {
      if (data != null) {
        let chr = '';
        const d = data.toUpperCase();
        chr = d.split(' ');
        if (chr.length >= 2) {
          return chr[0][0] + chr[1][0];
        }
        return d[0] + d[1];
      }
      return '';
    },
    getColorClassName() {
      const min = 1;
      const max = 27;
      const index = Math.floor(Math.random() * (max - min + 1) + min);

      return `company${index}`;
    },
  },
};

export default companyMixin;
