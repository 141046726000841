import Vue from 'vue';
import axios from 'axios';
import ApiClient from '~/api';

const initialState = {
  punchs: [],
  punchsMeta: {},

  attendances: [],
  attendancesMeta: {},
  attendanceWidget: null,
  attendanceStatuses: [],
  currentAttendance: [],

  employees: [],
  employeesMeta: {},

  reports: [],
  reportsMeta: {},

  resyncs: [],
  resyncsMeta: {},
  resyncStatuses: [],
  resyncDetails: [],
  resyncHistories: [],
  resyncEmployees: [],
  resyncEmployeesMeta: {},

  exports: [],
  exportMeta: {},
  exportDetails: [],
  exportHistories: [],
  exportEmployees: [],
  exportEmployeesMeta: {},

  exportAtt: [],
  exportAttMeta: {},
  exportAttDetails: [],
  exportAttHistories: [],
  exportAttEmployees: [],
  exportAttEmployeesMeta: {},

  settings: null,
};

const getters = {
  punchs: state => state.punchs,
  punchsMeta: state => state.punchsMeta,
  attendances: state => state.attendances,
  attendancesMeta: state => state.attendancesMeta,
  attendanceWidget: state => state.attendanceWidget,
  attendanceStatuses: state => state.attendanceStatuses,
  currentAttendance: state => state.currentAttendance,

  employees: state => state.employees,
  employeesMeta: state => state.employeesMeta,

  reports: state => state.reports,
  reportsMeta: state => state.reportsMeta,

  resyncs: state => state.resyncs,
  resyncsMeta: state => state.resyncsMeta,
  resyncStatuses: state => state.resyncStatuses,
  resyncHistories: state => state.resyncHistories,
  resyncEmployees: state => state.resyncEmployees,
  resyncEmployeesMeta: state => state.resyncEmployeesMeta,

  exports: state => state.exports,
  exportMeta: state => state.exportMeta,
  exportHistories: state => state.exportHistories,
  exportEmployees: state => state.exportEmployees,
  exportEmployeesMeta: state => state.exportEmployeesMeta,

  exportAtt: state => state.exportAtt,
  exportAttMeta: state => state.exportAttMeta,
  exportAttHistories: state => state.exportAttHistories,
  exportAttEmployees: state => state.exportAttEmployees,
  exportAttEmployeesMeta: state => state.exportAttEmployeesMeta,

  settings: state => state.settings,
};

const actions = {
  async punchStatus({ dispatch }, { uuid }) {
    try {
      /* eslint-disable camelcase */
      const data = await ApiClient.v1.attendance.getPunchStatus(uuid);
      return data;
    } catch (e) {
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchPunchs({ commit, dispatch }, { uuid, formData, cts = null, type = 'stack' }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getPunchs(uuid, formData, cts);

      commit('setPunchs', { data, type, formData });
      commit('setPunchsMeta', meta);
      return data;
    } catch (e) {
      commit('setPunchs', { data: [], type });
      commit('setPunchsMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchReports({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getReports(query, cts);

      commit('setReports', data);
      commit('setReportsMeta', meta);
      return data;
    } catch (e) {
      commit('setReports', []);
      commit('setReportsMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchEmployees({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getEmployees(query, cts);

      commit('setEmployees', data);
      commit('setEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setEmployees', []);
      commit('setEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchAttendanceWidget({ commit, dispatch }, { uuid, query, cts = null }) {
    try {
      const { data } = await ApiClient.v1.attendance.getAttendanceWidget(uuid, query, cts);
      commit('setAttendanceWidget', data);

      return data;
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to fetch attendance widget';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchAttendance({ commit }, { uuid, query }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getAttendance(uuid, query);
      commit('setAttendances', data);
      commit('setAttendancesMeta', meta);

      return data;
    } catch (e) {
      commit('setAttendances', []);
      commit('setAttendancesMeta', {});

      return [];
    }
  },
  async fetchCurrentAttendance({ commit }, { uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getCurrentAttendance(uuid);
      commit('setCurrentAttendance', data);

      return data;
    } catch (e) {
      commit('setCurrentAttendance', null);

      return [];
    }
  },
  fetchChart(_, formData) {
    return ApiClient.v1.attendance.getAttendanceChart(formData);
  },
  async fetchAttendanceById({ commit, store, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.attendance.getAttendanceById(uuid, id);
      commit('updateAttendance', { idx, data });

      return data;
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to fetch attendance';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchAttendanceStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.attendance.getAttendanceStatus();
      commit('setAttendanceStatuses', data);
      return [];
    } catch (e) {
      commit('setAttendanceStatuses', []);
      return [];
    }
  },
  async updateAttendance({ commit, dispatch }, { uuid, id, idx, formData, isAdmin }) {
    try {
      const { data } = isAdmin ? await ApiClient.v1.attendance.updateAttendance(uuid, id, formData) : await ApiClient.v1.attendance.updateAttendanceByEmployee(uuid, id, formData);
      commit('updateAttendance', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update attendance';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateCurrentAttendance({ commit, dispatch }, { uuid, idx, formData, isAdmin }) {
    try {
      formData.employee_id = uuid;
      const { data } = await ApiClient.v1.attendance.updateCurrentAttendance(formData);
      dispatch('fetchCurrentAttendance', { uuid });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to update attendance';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async submitPunch({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.attendance.submitPunch(uuid, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to submit attendance';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchResyncs({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getResyncs(query, cts);

      commit('setResyncs', data);
      commit('setResyncsMeta', meta);
      return data;
    } catch (e) {
      commit('setResyncs', []);
      commit('setResyncsMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchResyncStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.attendance.getResyncStatus();
      commit('setResyncStatuses', data);
      return [];
    } catch (e) {
      commit('setResyncStatuses', []);
      return [];
    }
  },
  fetchResyncById(_, id) {
    return ApiClient.v1.attendance.getResyncById(id);
  },
  async fetchResyncEmployeesById({ commit, dispatch }, { id, query = '', cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getResyncEmployeesById(id, query, cts);
      commit('setResyncEmployees', data);
      commit('setResyncEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setResyncEmployees', []);
      commit('setResyncEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchResyncHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.attendance.getResyncHistoryById(id);
      commit('setResyncHistories', data);
      return data;
    } catch (e) {
      commit('setResyncHistories', []);
      return [];
    }
  },
  async createResyncAttendance({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.attendance.createResyncAttendance(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to create resync attendance';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchExports({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getExports(query, cts);

      commit('setExports', data);
      commit('setExportMeta', meta);
      return data;
    } catch (e) {
      commit('setExports', []);
      commit('setExportMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchExportById(_, id) {
    return ApiClient.v1.attendance.getExportById(id);
  },
  async fetchExportEmployeesById({ commit, dispatch }, { id, query = '', cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getExportEmployeesById(id, query, cts);
      commit('setExportEmployees', data);
      commit('setExportEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setExportEmployees', []);
      commit('setExportEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.attendance.getExportHistoryById(id);
      commit('setExportHistories', data);
      return data;
    } catch (e) {
      commit('setExportHistories', []);
      return [];
    }
  },
  async exportAttendance({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.attendance.exportAttendance(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to export punch data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchExportAtt({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getExportAtt(query, cts);

      commit('setExportAtt', data);
      commit('setExportAttMeta', meta);
      return data;
    } catch (e) {
      commit('setExportAtt', []);
      commit('setExportAttMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  fetchExportAttById(_, id) {
    return ApiClient.v1.attendance.getExportAttById(id);
  },
  async fetchExportAttEmployeesById({ commit, dispatch }, { id, query = '', cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.attendance.getExportAttEmployeesById(id, query, cts);
      commit('setExportAttEmployees', data);
      commit('setExportAttEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setExportAttEmployees', []);
      commit('setExportAttEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportAttHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.attendance.getExportAttHistoryById(id);
      commit('setExportAttHistories', data);
      return data;
    } catch (e) {
      commit('setExportAttHistories', []);
      return [];
    }
  },
  async exportAtt({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.attendance.exportAtt(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to export attendance';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchSetting({ commit, state }, { personal }) {
    try {
      let key = 'main_company';
      if (!personal) key = 'selected_company';

      const { data } = await ApiClient.v1.attendance.getSettings();
      commit('setSettings', data);
      return data;
    } catch (e) {
      commit('setSettings', []);
      return [];
    }
  },
  async saveSetting({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.attendance.saveSettings(formData);
      return data;
    } catch (e) {
      const caption = 'Failed to Save Business Travel Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
};

const mutations = {
  setAttendances(state, data) {
    state.attendances = data;
  },
  setAttendancesMeta(state, data) {
    state.attendancesMeta = data;
  },
  setAttendanceWidget(state, data) {
    state.attendanceWidget = data;
  },
  setAttendanceStatuses(state, value) {
    state.attendanceStatuses = value;
  },
  setCurrentAttendance(state, data) {
    state.currentAttendance = data;
  },

  setEmployees(state, data) {
    state.employees = data;
  },
  setEmployeesMeta(state, data) {
    state.employeesMeta = data;
  },

  setPunchs(state, { data, type, formData }) {
    if (type === 'stack') {
      if (data.length) {
        if (formData.page === 1) {
          state.punchs = data;
        } else {
          data.forEach(element => {
            state.punchs.push(element);
          });
        }
      }
    } else {
      state.punchs = data;
    }
  },
  setPunchsMeta(state, data) {
    state.punchsMeta = data;
  },
  setReports(state, data) {
    state.reports = data;
  },
  setReportsMeta(state, data) {
    state.reportsMeta = data;
  },
  setResyncs(state, data) {
    state.resyncs = data;
  },
  setResyncStatuses(state, value) {
    state.resyncStatuses = value;
  },
  setResyncsMeta(state, data) {
    state.resyncsMeta = data;
  },
  setResyncHistories(state, data) {
    state.resyncHistories = data;
  },
  setResyncEmployees(state, data) {
    state.resyncEmployees = data;
  },
  setResyncEmployeesMeta(state, data) {
    state.resyncEmployeesMeta = data;
  },
  setExports(state, data) {
    state.exports = data;
  },
  setExportMeta(state, data) {
    state.exportMeta = data;
  },
  setExportHistories(state, data) {
    state.exportHistories = data;
  },
  setExportEmployees(state, data) {
    state.exportEmployees = data;
  },
  setExportEmployeesMeta(state, data) {
    state.exportEmployeesMeta = data;
  },
  setExportAtt(state, data) {
    state.exportAtt = data;
  },
  setExportAttMeta(state, data) {
    state.exportAttMeta = data;
  },
  setExportAttHistories(state, data) {
    state.exportAttHistories = data;
  },
  setExportAttEmployees(state, data) {
    state.exportAttEmployees = data;
  },
  setExportAttEmployeesMeta(state, data) {
    state.exportAttEmployeesMeta = data;
  },

  updateAttendance(state, { idx, data }) {
    state.attendances[idx].in.actual = data.punch_in;
    state.attendances[idx].in.status = data.status_punch_in;
    state.attendances[idx].in.is_late = data.is_late;
    state.attendances[idx].out.actual = data.punch_out;
    state.attendances[idx].out.status = data.status_punch_out;
    state.attendances[idx].out.is_early = data.is_early;
    if (data.notes) {
      state.attendances[idx].note.message = data.notes.message;
      state.attendances[idx].note.title = data.notes.title;
    }
    if (data.employee_notes) {
      state.attendances[idx].employee_notes.message = data.employee_notes.message;
      state.attendances[idx].employee_notes.title = data.employee_notes.title;
    }
    state.attendances[idx].last_update = data.last_update;
    state.attendances[idx].last_updater = data.last_updater;
    state.attendances[idx].is_taking_off = data.is_taking_off;
    state.attendances[idx].created_at = data.created_at;
  },

  setSettings(state, value) {
    state.settings = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
